.slick-next {
  right: -3.5rem !important;
  @include mobileScreen {
    //right: -0.75rem !important;
  }
}

.slick-prev {
  left: -3.5rem !important;
  @include mobileScreen {
   // left: -0.75rem !important;
  }
}

.slick-prev,
.slick-next {
  width: $fs-44px !important;
  height: $fs-44px !important;
  z-index: 9 !important;
  text-align: center;
  // display: none !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: $fs-38px !important;
  color: #f5ba8a !important;
  display: none !important;
}

.slick-dots {
  bottom: $fs-12px !important;
}

.slick-dots li button:before {
  font-size: $fs-16px !important;
}

.slick-dots li.slick-active button:before {
  color: $crimson-red !important;
}

.slick-track {
  width: auto;
}

.lr-arrow {
  font-size: $fs-32px !important;
  color: $neon-carrot;
}

.lr-arrow-green {
  font-size: $fs-32px !important;
  color: $kelly-green;
}

.lr-arrow-white {
  font-size: $fs-32px !important;
  color: $white;
  @include mobileScreen {
    color: $black-pearl;
    background-color: $white;
  }
}

.next-icon-container {
  right: 0.5rem !important;
}
.prev-icon-container {
  left: 0.5rem !important;
}

.next-next-container {
  right: -3.75rem !important;
  @include mobileScreen {
    right: -0.75rem !important;
  }
}

.next-prev-container {
  left: -3.25rem !important;
  @include mobileScreen {
    left: -0.75rem !important;
  }
}

.slick-list > div {
  margin-left: 0;
  @include mobileScreen {
    margin-left: auto;
  }
}

.pdf-viewer-container {
  .slick-list > div {
    margin-left: 0;
  }
}


.lr-arrow-black {
  font-size: $fs-32px !important;
  color: $black-pearl;
}