.bt_logo_data{
  img{
    width:25% !important;
  }
}

.pink-bg {
  // background-image: url("../../assets/MapOfMe/pink-bg.png");
  // background-size: contain;
  // background-repeat: no-repeat;
  // width: 100%;
  // height: 650px;
  background-image: url("../../assets/MapOfMe/pink-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 80%;

  .para-sec {
    p {
      line-height: 1.65rem !important;
      letter-spacing: 0.9px;
      font-size: 17px !important;
      text-align: justify !important;
    }
  }
  .logo {
    img {
      width: 80% !important;
    }
    a {
      text-decoration: none;
      font-size: 21px;
      cursor: pointer;
    }
  }
  .bang-label {
    position: relative;
    img {
      width: 80% !important;
      height: 53px;
    }
    p {
      position: absolute;
      top: 14px;
      left: 10px;
      font-size: 25px !important;
      font-weight: 700;
    }
  }
  .city-label {
    position: relative;

    img {
      width: 80% !important;
      height: 53px;
    }
    .delhi-label {
      left: 48px !important;
    }
    p {
      position: absolute;
      top: 14px;
      left: 27px !important;
      font-size: 25px !important;
      font-weight: 700;
    }
  }
}
////////////////////MUMBAI SECTION START/////////////////
.main_mumbai {
  margin: 0 auto;
  width: 1120px !important;
  text-align: center;
  position: relative;

  img {
    position: relative;
    width: 100% !important;
   
  }
  .mum-head {
    p {
    width: 567px;
    position: absolute;
    top: 9%;
    left: 43%;
    font-size: 14px !important;
    text-align: justify;
    transform: translate(-50%, -50%);
    line-height: 1rem !important;
    }
  }
  .mum-main-video {
    position: absolute;
    top: 38% !important;
    left: 53% !important;
    transform: translate(-54%, -50%);

    iframe {
      width: 780px !important;
      height: 402px !important;
      border-radius: 29px;
    }
  }
  .mum-quize {
    position: absolute;
    // bottom: 30% !important;
    // left: 30% !important;
    // transform: translate(-50%, -50%);
    bottom: 36% !important;
    left: 23% !important;
  
    p {
      font-weight: 800;
      display: contents;
      a {
        font-size: 23px !important;
        text-decoration: none;
      }
    }
  }
  .mum-step {
    position: absolute;
    bottom: 36% !important;
    right: 15% !important;
   
    p {
      display: flex;
      line-height: 0.95rem !important;
      font-weight: 800;
      a {
        font-size: 22px !important;
        text-decoration: none;
      }
    }
  }
  .mum-video1 {
    position: absolute;
    bottom: 218px !important;
    left: 19.5% !important;
    transform: translate(-50%, -51%);
   .thumb-main{
  .thumb1 {
    width: 183px !important;
    height: 96px !important;

  }
  .ylogo{
    position: absolute;
    top: 37px;
    left: 73px;
    width: 49px !important;
  }
}
    
  }
  .mum-video2 {
    position: absolute;
    bottom: 218px !important;
    left: 450px !important;
    transform: translate(-50%, -50%);
    .thumb-main{
      .thumb1 {
        width: 183px !important;
    height: 96px !important;

      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
    // iframe {
    //   width: 195px !important;
    //   height: 101px !important;
    // }
  }
  .mum-video3 {
    position: absolute;
    bottom: 218px !important;
    right: 258px !important;
    transform: translate(-50%, -50%);
    .thumb-main{
      .thumb1 {
        width: 183px !important;
        height: 96px !important;
    
      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
    // iframe {
    //   width: 195px !important;
    //   height: 101px !important;
    // }
  }
  .mum-video4 {
    position: absolute;
    bottom: 218px!important;
    right: 32px !important;
    transform: translate(-50%, -50%);
    .thumb-main{
      .thumb1 {
        width: 183px !important;
    height: 96px !important;

      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
    // iframe {
    //   width: 195px !important;
    //   height: 101px !important;
    // }
  }
  .mum-show1 {
    position: absolute;
    bottom: 198px !important;
    left: 191px !important;
    
    p {
      display: contents;
      a {
        font-size: 24px;
        font-weight: 700;
        text-decoration: none;
      }
    }
  }
  .mum-show2 {
    position: absolute;
    bottom: 199px !important;
    left: 394px !important;
 
    p {
      display: contents;
      a {
        font-size: 24px;
        font-weight: 700;
        text-decoration: none;
      }
    }
  }
  .mum-show3 {
    position: absolute;
    bottom: 182px !important;
    right: 363px !important;
   
    p {
      display: flex;
      a {
        font-size: 24px;
        font-weight: 700;
        text-decoration: none;
      }
    }
  }
  .mum-show4 {
    position: absolute;
    bottom: 197px !important;
    right: 105px !important;
  
    p {
      display: contents;
      a {
        font-size: 24px;
        font-weight: 700;
        text-decoration: none;
      }
    }
  }
}
/////////////////////////MUMBAI SECTION END ////////////
/// BENGLURU START /////////
.main_bengaluru {
  margin: 0 auto;
  width: 1120px !important;
  text-align: center;
  position: relative;

  img {
    position: relative;
    width: 100% !important;
    // z-index: 3;
  }
  .ben-head {
    p {
      color: #fff;
      width: 577px;
      position: absolute;
      top: 15%;
      left: 43%;
      line-height: 1rem !important;
      letter-spacing: 0.75px;
      font-size: 14px !important;
      text-align: justify;
      transform: translate(-50%, -49%);
    }
  }
  .ben-main-video {
    position: absolute;
    top: 41% !important;
    left: 51%!important;
    transform: translate(-50%, -50%);
    iframe {
      width: 772px !important;
      height: 341px !important;
    }
  }
  .ben-quize {
    position: absolute;
    bottom: 31% !important;
    left: 24%;
   
    p {
      display: contents;
      font-weight: 800;
      a {
        font-size: 19px !important;
        text-decoration: none;
      }
    }
  }
  .ben-step {
    position: absolute;
    bottom: 29% !important;
    right: 18% !important;
   
    p {
      display: flex;
      line-height: 0.95rem !important;
      font-weight: 800;
      a {
        font-size: 19px !important;
        text-decoration: none;
      }
    }
  }
  .ben-video1 {
    position: absolute;
    bottom: 113px !important;
    left: 220px !important;
    transform: translate(-50%, -50%);

    .thumb-main{
      .thumb1 {
        width: 202px !important;
        height: 106px !important;
      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
  }
  .ben-video2 {
    position: absolute;
    bottom: 113px !important;
    left: 451px !important;
    transform: translate(-50%, -50%);
    .thumb-main{
      .thumb1 {
        width: 202px !important;
        height: 106px !important;
      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
  }
  .ben-video3 {
    position: absolute;
    bottom: 113px !important;
    right: 239px !important;
    transform: translate(-50%, -50%);
    .thumb-main{
      .thumb1 {
        width: 202px !important;
        height: 106px !important;
      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
  }
  .ben-video4 {
    position: absolute;
    bottom: 113px !important;
    right: 8px !important;
    transform: translate(-50%, -50%);
    .thumb-main{
      .thumb1 {
        width: 202px !important;
        height: 106px !important;
      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
  }
}
/// BENGLURU END/////////
/// DELHI START//////
.main_delhi {
  margin: 0 auto;
  width: 1120px !important;
  text-align: center;
  position: relative;

  img {
    position: relative;
    width: 90% !important;
    // z-index: 3;
  }
  .del-head {
    p {
      width: 396px;
    position: absolute;
    top: 89px;
    left: 554px;
    line-height: 0.8rem !important;
    letter-spacing: 0.75px;
    font-size: 14px !important;
    text-align: justify;
    transform: translate(-50%, -50%);
    }
  }
  .del-main-video {
    position: absolute;
    top: 39%!important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    
    iframe {
      width: 727px !important;
      height: 388px !important;
      border-radius: 21px;
    }
  }
  .del-quize {
    position: absolute;
    bottom: 356px !important;
    left: 241px !important;

    p {
      display: contents;
      font-weight: 800;
      a {
        font-size: 19px !important;
        text-decoration: none;
      }
    }
  }
  .del-step {
    position: absolute;
    bottom: 356px !important;

    right: 141px !important;
    p {
      display: contents;
      font-weight: 800;
      a {
        font-size: 19px !important;
        text-decoration: none;
      }
    }
  }
  .del-video1 {
    position: absolute;
    bottom: 107px !important;
    left: 216px !important;
    transform: translate(-50%, -50%);
    .thumb-main{
      .thumb1 {
        width: 201px !important;
        height: 106px !important;
      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
    
  }
  .del-video2 {
    position: absolute;
    bottom: 107px !important;
    left: 448px !important;
    transform: translate(-50%, -50%);
    .thumb-main{
      .thumb1 {
        width: 201px !important;
        height: 106px !important;
      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
  }
  .del-video3 {
    position: absolute;
    bottom: 108px !important;
    right: 239px !important;
    transform: translate(-50%, -50%);
    .thumb-main{
      .thumb1 {
        width: 201px !important;
        height: 106px !important;
      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
  }
  .del-video4 {
    position: absolute;
    bottom: 108px !important;
    right: 7px !important;
    transform: translate(-50%, -50%);
    .thumb-main{
      .thumb1 {
        width: 201px !important;
        height: 106px !important;
      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
  }
}
/// END///////
.main_kolkata {
  margin: 0 auto;
  width: 1120px !important;
  text-align: center;
  position: relative;

  img {
    position: relative;
    width: 90% !important;
    // z-index: 3;
  }
  .kol-head {
    p {
      width: 421px;
      position: absolute;
      top: 14%;
      left: 40%;
      line-height: 1rem !important;
      letter-spacing: 0.75px;
      font-size: 14px !important;
      text-align: justify;
      transform: translate(-50%, -50%);
    }
  }
  .kol-main-video {
    position: absolute;
    
    top: 542px !important;
    left: 51.3% !important;
    transform: translate(-50%, -50%);
    iframe {
      width: 780px !important;
      height: 346px !important;
    }
  }
  .kol-quize {
    position: absolute;
    top: 770px !important;
    left: 241px !important;
    p {
      display: contents;
      font-weight: 800;
      a {
        font-size: 19px !important;
        text-decoration: none;
      }
    }
  }
  .kol-step {
    position: absolute;
    top: 770px !important;
    right: 184px !important;
    p {
      display: contents;
      font-weight: 800;
      a {
        font-size: 19px !important;
        text-decoration: none;
      }
    }
  }
  .kol-video1 {
    position: absolute;
    bottom: 437px !important;
    left: 219px !important;
    transform: translate(-50%, -50%);
    .thumb-main{
      .thumb1 {
        width: 204px !important;
        height: 106px !important;
      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
   
  }
  .kol-video2 {
    position: absolute;
    bottom: 438px !important;
    left: 449px !important;
    transform: translate(-50%, -50%);
    .thumb-main{
      .thumb1 {
        width: 204px !important;
        height: 106px !important;
      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
  }
  .kol-video3 {
    position: absolute;
    bottom: 436px !important;
    right: 235px !important;
    transform: translate(-50%, -50%);
    .thumb-main{
      .thumb1 {
        width: 204px !important;
        height: 106px !important;
      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
  }
  .kol-video4 {
    position: absolute;
    bottom: 437px !important;
    right: 3px !important;
    transform: translate(-50%, -50%);
    .thumb-main{
      .thumb1 {
        width: 204px !important;
        height: 106px !important;
      }
      .ylogo{
        position: absolute;
        top: 37px;
        left: 73px;
        width: 49px !important;
      }
    }
  }
}
.slider-thumb{
  .thumb-main{
    position: relative;
    .thumb1 {
      width: 97% !important;
      height: 120px !important;
    }
    .ylogo{
      position: absolute;
      top: 43%;
      left: 39%;
      width: 20% !important;
    }
  }
}
.slider-video1 
{
  .player-wrapper-small {
  width: 99% !important;
  height: 120px;
  margin-right: 10px;
  border-radius: 0px !important;
}
.thumb-main{
  position: relative;
  .thumb1 {
    width: 225px !important;
    height: 150px !important;
  }
  .ylogo{
    position: absolute;
    top: 57px;
    left: 90px;
    width: 49px !important;
  }
}
}

.slick-slider {
  padding: 0 100px !important;
}



@media only screen and (max-width: 1024px) and (min-width: 768px){
  .slider-video1 .thumb-main .thumb1 {
    width: 183px !important;
    height: 101px !important;
}
  .pink-bg {
    height: 500px;
    
    .bang-label {
      p {
        font-size: 17px !important;
      }
    }
    .city-label {
      .delhi-label {
        left: 38px;
      }
      p {
        font-size: 17px !important;
        left: 24px !important;
      }
    }
    .para-sec {
      p {
        font-size: 12px !important;
        text-align: justify !important;
        line-height: 1rem !important;
      }
    }
  }
  .main_mumbai{
    width:750px !important;
  .mum-head{ p {
    width: 370px !important;
    top: 9% !important;
    left: 42% !important;
    font-size: 12px !important;
    line-height: 0.75rem !important;
  }}
  .mum-main-video {
    top: 38% !important;
    left: 53% !important;
    iframe{
      width: 521px !important;
      height: 268px !important;
      border-radius: 26px;
    }
}
.mum-video1 {
  bottom: 147px !important;
  left: 147px !important;
  .thumb-main {
    .thumb1 {
      width: 122px !important;
      height: 64px !important;
  }
  .ylogo {
    top: 20px;
    left: 43px;
    width: 40px !important;
  }
}
}
 .mum-video2 {
  bottom: 147px !important;
  left: 301px !important;
.thumb-main {.thumb1 {
  width: 122px !important;
  height: 64px !important;
}
 .ylogo {
  top: 20px;
  left: 43px;
  width: 40px !important;
}}
}
 .mum-video3 {
  bottom: 147px !important;
  right: 173px !important;

.thumb-main {.thumb1 {
  width: 122px !important;
  height: 64px !important;
}
 .ylogo {
  top: 20px;
  left: 43px;
  width: 40px !important;
}}
}
 .mum-video4 {
  bottom: 147px !important;
  right: 22px !important;
.thumb-main {.thumb1 {
  width: 122px !important;
  height: 64px !important;
}
.ylogo {
  top: 20px;
  left: 43px;
  width: 40px !important;
}}
}
.mum-quize {
  bottom: 282px !important;
    left: 175px !important;

 p a {
  font-size: 14px !important;
  
}}
 .mum-step {
  
  bottom: 282px !important;
  right: 110px !important;
 p a {
  font-size: 14px !important;
 }
}
.mum-show1 {
  
  bottom: 137px !important;
  left: 130px !important;
 p a {
  font-size: 13px;
}}
.mum-show3 {
  bottom: 119px !important;
  right: 253px !important;
p{ 
  display: flex ;
  a {
  font-size: 11px;
  
}}}
 .mum-show2 {
  bottom: 137px !important;
  left: 268px !important;
 p a {
  font-size: 13px;
}}
 .mum-show4 {
  bottom: 137px !important;
  right: 88px !important;
 p a {
  font-size: 12px;
  
}}
}
  .main_bengaluru {
    width:750px !important;
    .ben-head p {
      color: #fff;
      width: 382px;
      position: absolute;
      top: 111px;
      left: 42%;
      line-height: 1rem !important;
      letter-spacing: 0.75px;
      font-size: 13px !important;
      text-align: justify;
}
.ben-main-video {
  top: 41% !important;
  left: 51%!important;
 iframe {
  width: 538px !important;
  height: 198px !important;
}}
.ben-video1 {
  bottom: 75px !important;
    left: 148px!important;
 .thumb-main{ .thumb1 {
  width: 134px !important;
  height: 72px !important;
}
.ylogo {
  top: 24px;
  left: 47px;
  width: 40px !important;
}}}

.ben-video2 {
  bottom: 75px !important;
  left: 301px !important;
.thumb-main {.thumb1 {
  width: 134px !important;
  height: 72px !important;
}
 .ylogo {
  top: 24px;
  left: 47px;
  width: 40px !important;
}}}
.ben-video3 {
  bottom: 75px !important;
  right: 161px !important;
 .thumb-main {.thumb1 {
  width: 134px !important;
  height: 72px !important;
}
 .ylogo {
  top: 24px;
  left: 47px;
  width: 40px !important;
}}}
 .ben-video4 {
  bottom: 75px !important;
  right: 7px !important;
.thumb-main{ .thumb1 {
  width: 134px !important;
  height: 72px !important;
}
 .ylogo {
  top: 24px;
  left: 47px;
  width: 40px !important;
}}}
.ben-quize {
  bottom: 232px !important;
  left: 176px !important;
 p a {
  font-size: 13px !important;
}}
 .ben-step {
  bottom: 218px !important;
    right: 135px !important;
    p a {
      font-size: 13px !important;
    }
}
}



.main_delhi{
  width: 750px !important;
  .del-head p {
    width: 336px;
    position: absolute;
    top: 9%;
    left: 50%;
    line-height: 0.75rem !important;
    font-size: 12px !important;
    text-align: justify;
  }
   .del-main-video {
   top: 39% !important;
   left: 50% !important;
   iframe {
    width: 477px !important;
    height: 239px !important;
    border-radius: 21px;
  }
}
.del-quize {
  
  bottom: 231px!important;
  left: 171px !important;
  p a{
    font-size: 16px !important;
  }
}
 .del-step {
  bottom: 231px!important;
  right: 75px !important;
  p a{
    font-size: 16px !important;
  }
}
.del-video1 {
  bottom: 73px !important;
  left: 146px !important;
.thumb-main{ 
  .thumb1 {
  width: 134px !important;
  height: 70px !important;
}
.ylogo {
  top: 19px;
    left: 48px;
    width: 40px !important;
}}}
.del-video2 {
  bottom: 73px !important;
  left: 301px !important;
.thumb-main {.thumb1 {
  width: 134px !important;
  height: 70px !important;
}
.ylogo {
  top: 19px;
    left: 48px;
    width: 40px !important;
}}}
.del-video3 {
  bottom: 73px !important;
  right: 161px !important;

 .thumb-main {.thumb1 {
  width: 134px !important;
  height: 70px !important;
}
 .ylogo {
  top: 19px;
  left: 48px;
  width: 40px !important;
}}}
.del-video4{
  bottom: 73px !important;
  right: 6px !important;

 .thumb-main{ .thumb1 {
  width: 134px !important;
  height: 70px !important;
}
.ylogo {
  top: 19px;
    left: 48px;
    width: 40px !important;
}}}
}




.main_kolkata {
  width: 750px !important;
  .kol-head p {
    width: 296px;
    position: absolute;
    top: 141px;
    left: 306px;
    line-height: 1rem !important;
    letter-spacing: 0.75px;
    font-size: 14px !important;
    text-align: justify;

}
.kol-main-video {
  top: 362px !important;
  left: 385px !important;
 iframe {
  width: 523px !important;
    height: 231px !important;
}}
.kol-quize {
  top: 516px !important;
  left: 159px !important;
  p a {
    font-size: 14px !important;
    
}
}
 .kol-step {
  top: 503px !important;
  right: 115px !important;
  p a {
    font-size: 14px !important;
    
}
}
.kol-video1 {
  bottom: 291px !important;
  left: 147px !important;
.thumb-main{ .thumb1 {
  width: 141px !important;
  height: 73px !important;
}
.ylogo {
  top: 23px;
  left: 48px;
  width: 40px !important;
}}}
 .kol-video2 {
  bottom: 291px !important;
  left: 301px !important;
.thumb-main{ .thumb1 {
  width: 141px !important;
  height: 73px !important;
}
 .ylogo {
  top: 23px;
    left: 48px;
    width: 40px !important;
}}}
.kol-video3 {
  bottom: 291px !important;
  right: 152px !important;
 .thumb-main {.thumb1 {
  width: 141px !important;
  height: 73px !important;
}
 .ylogo {
  top: 23px;
    left: 48px;
    width: 40px !important;
}}}
 .kol-video4 {
  bottom: 291px !important;
  right: -4px !important;

.thumb-main {.thumb1 {
  width: 141px !important;
  height: 73px !important;
}
 .ylogo {
  top: 23px;
    left: 48px;
    width: 40px !important;
}}}
}
}
@media only screen and (max-width: 768px) {
  .pink-bg .bang-label p {
    font-size: 12px !important;
}
.pink-bg .city-label p {
  font-size: 14px !important;
  left: 18px !important;
}
.pink-bg .logo a {
  text-decoration: none;
  font-size: 15px;
  cursor: pointer;
}
.pink-bg .para-sec p {
  font-size: 12px !important;
  text-align: justify !important;
  line-height: 1rem !important;
}
.slider-thumb .thumb-main .ylogo {
  position: absolute;
  top: 35%;
  left: 46%;
  width: 11% !important;
}
}
@media only screen and (max-width: 767px) {
  .pink-bg {.para-sec p {
    font-size: 12px !important;
    text-align: justify !important;
    line-height: 1rem !important;
   
}
.logo img {
  width: 34% !important;
}
.logo a {
  font-size: 15px;
 }
.bang-label {
  img{
    width: 52% !important;
    height: 43px;
  }
  p {
 
    top: 14px;
    left: 116px;
  
    font-size: 17px !important;
}}
.city-label {
  .delhi-label {
    left: 142px !important
}
  img{
    width: 52% !important;
    height: 43px;
  }
  p {
  top: 14px;
    left: 116px !important;
  
    font-size: 17px !important;
  
}}
}
.slider-thumb .thumb-main .ylogo {
  position: absolute;
  top: 29%;
  left: 48%;
  width: 11% !important;
}
.main_mumbai{
  width:600px !important;
.mum-head{ p {
  width: 301px !important;
  top: 9% !important;
  left: 42% !important;
  font-size: 10px !important;
  line-height: 0.75rem !important;
}}
.mum-main-video {
  top: 38% !important;
  left: 53% !important;
  iframe{
    width: 417px !important;
    height: 213px !important;
    border-radius: 13px;
  }
}
.mum-video1 {
  bottom: 117px!important;
  left: 118px !important;
.thumb-main {
  .thumb1 {
    width: 94px !important;
    height: 51px !important;
}
.ylogo {
  top: 15px;
  left: 32px;
  width: 27px !important;
}
}
}
.mum-video2 {
  bottom: 117px!important;
left: 241px !important;
.thumb-main {.thumb1 {
  width: 94px !important;
  height: 51px !important;
}
.ylogo {
  top: 15px;
  left: 32px;
  width: 27px !important;
}}
}
.mum-video3 {
  bottom: 117px!important;
right: 143px !important;

.thumb-main {.thumb1 {
  width: 94px !important;
  height: 51px !important;
}
.ylogo {
  top: 15px;
  left: 32px;
  width: 27px !important;
}}
}
.mum-video4 {
  bottom: 117px!important;
right: 22px !important;
.thumb-main {.thumb1 {
  width: 94px !important;
  height: 51px !important;
}
.ylogo {
  top: 15px;
  left: 32px;
  width: 27px !important;
}}
}
.mum-quize {
  bottom: 225px !important;
  left: 140px !important;

p a {
font-size: 12px !important;

}}
.mum-step {
  bottom: 225px !important;
  right: 87px !important;
p a {
font-size: 12px !important;
}
}
.mum-show1 {
  bottom: 108px !important;
  left: 103px !important;

p a {
font-size: 13px;
}}
.mum-show3 {
  bottom: 88px !important;
  right: 201px !important;
p{ 
  display: flex;
  line-height: 0.75rem !important;
a {
font-size: 11px;

}}}
.mum-show2 {
  bottom: 106px !important;
  left: 212px !important;
p a {
font-size: 13px;
}}
.mum-show4 {
  bottom: 106px !important;
  right: 61px !important;
p a {
font-size: 12px;

}}
}
.main_bengaluru {
  width:600px !important;
  .ben-head p {
    color: #fff;
    width: 332px;
    position: absolute;
    top: 91px;
    left: 42%;
    line-height: 0.75rem !important;
    letter-spacing: 0.75px;
    font-size: 10px !important;
    text-align: justify;
}
.ben-main-video {

  top: 41% !important;
  left: 52% !important;
iframe {
  width: 434px !important;
  height: 164px !important;
}}
.ben-video1 {
  bottom: 60px !important;
  left: 118px !important;
.thumb-main{ .thumb1 {
  width: 109px !important;
  height: 57px !important;
}
.ylogo {
  top: 21px;
  left: 41px;
  width: 23px !important;
}}}

.ben-video2 {
  bottom: 60px !important;
left: 241px !important;
.thumb-main {.thumb1 {
  width: 109px !important;
  height: 57px !important;
}
.ylogo {
  top: 21px;
  left: 41px;
  width: 23px !important;
}}}
.ben-video3 {
  bottom: 60px !important;
right: 127px !important;
.thumb-main {.thumb1 {
  width: 109px !important;
  height: 57px !important;
}
.ylogo {
  top: 21px;
  left: 41px;
  width: 23px !important;
}}}
.ben-video4 {
  bottom: 60px !important;
right: 3px !important;
.thumb-main{ .thumb1 {
  width: 109px !important;
  height: 57px !important;
}
.ylogo {
  top: 21px;
  left: 41px;
  width: 23px !important;
}}}
.ben-quize {
  bottom: 183px !important;
  left: 146px !important;
p a {
font-size: 13px !important;
}}
.ben-step {
  bottom: 166px !important;
  right: 115px !important;
  p {
    line-height: 0.7rem !important;
    display: flex;
    a {
    font-size: 13px !important;
  }}
}
}
.main_delhi{
  width:600px !important;
.del-head p {
  width: 273px;
  position: absolute;
  top: 9%;
  left: 50%;
  line-height: 0.5rem !important;
  font-size: 9px !important;
  text-align: justify;
}
 .del-main-video {
 top: 39% !important;
 left: 50% !important;
 iframe {
  width: 360px !important;
  height: 190px !important;
  border-radius: 21px;
}
}
.del-quize {

  bottom: 184px !important;
  left: 133px !important;
p a{
  font-size: 14px !important;
}
}
.del-step {
  bottom: 183px !important;
  right: 59px !important;
p a{
  font-size: 14px !important;
}
}
.del-video1 {
  bottom: 58px !important;
  left: 116px !important;
.thumb-main{ 
.thumb1 {
  width: 106px !important;
  height: 56px !important;
}
.ylogo {
  top: 19px;
  left: 41px;
  width: 26px !important;
}}}
.del-video2 {
  bottom: 58px !important;
left: 241px !important;
.thumb-main {.thumb1 {
  width: 106px !important;
  height: 56px !important;
}
.ylogo {
  top: 19px;
  left: 41px;
  width: 26px !important;
}}}
.del-video3 {
  bottom: 58px !important;
right: 129px !important;

.thumb-main {.thumb1 {
  width: 106px !important;
  height: 56px !important;
}
.ylogo {
  top: 19px;
  left: 41px;
  width: 26px !important;
}}}
.del-video4{
  bottom: 58px !important;
right: 6px !important;

.thumb-main{ .thumb1 {
  width: 106px !important;
  height: 56px !important;
}
.ylogo {
  top: 19px;
  left: 41px;
  width: 26px !important;

}}}
}
.main_kolkata {
  width:600px !important;
.kol-head p {
  width: 232px;
  position: absolute;
  top: 115px;
  left: 244px;
  line-height: 1rem !important;
  letter-spacing: 0.75px;
  font-size: 10px !important;
  text-align: justify;
}
.kol-main-video {
  top: 290px !important;
  left: 308px!important;
iframe {
  width: 417px !important;
  height: 185px !important;
}}
.kol-quize {
  top: 408px !important;
  left: 122px !important;

p a {
  font-size: 12px !important;
  
}
}
.kol-step {
  top: 404px !important;
    right: 89px !important;

p {
  display:flex;
  a {
  font-size: 12px !important;
  
}}
}
.kol-video1 {
  bottom: 232px !important;
  left: 117px !important;
.thumb-main{ .thumb1 {
  width: 112px !important;
  height: 59px !important;
}
.ylogo {
  top: 23px;
  left: 44px;
  width: 27px !important;
}}}
.kol-video2 {
  bottom: 232px !important;
left: 241px !important;
.thumb-main{ .thumb1 {
  width: 112px !important;
  height: 59px !important;
}
.ylogo {
  top: 23px;
  left: 44px;
  width: 27px !important;
}}}
.kol-video3 {
  bottom: 232px !important;
right: 123px !important;
.thumb-main {.thumb1 {
  width: 112px !important;
  height: 59px !important;
}
.ylogo {
  top: 23px;
  left: 44px;
  width: 27px !important;
}}}
.kol-video4 {
  bottom: 232px !important;
right: -1px !important;

.thumb-main {.thumb1 {
width: 112px !important;
    height: 59px !important;
}
.ylogo {
  top: 23px;
  left: 44px;
  width: 27px !important;
}}}
}
.red_section .img_1 p {
  left: 25%;
  top: 23px;
  color: #fff !important;
  font-size: 10px !important;
}
.red_section .img_2 img {
  width: 323px !important;
}
.red_section .img_1 img {
  width: 56px !important;
  left: 24%;
  top: 6%;
}
}
@media only screen and (max-width: 480px) {
  body{
    overflow-x: hidden;
  }
  .pink-bg {
    height: 600px;
    background-size: 100% 600px;
    .para-sec p {
      font-size: 10px !important;
      text-align: justify !important;
      line-height: 0.85rem !important;
  }
.logo img {
  width: 34% !important;
}
.logo a {
  font-size: 15px;
 }
.bang-label {
  img{
    width: 52% !important;
    height: 43px;
  }
  p {
    top: 14px;
    left: 53px;
    font-size: 13px !important;
}}
.city-label {
  .delhi-label {
  
      left: 74px !important;
  }

  img{
    width: 52% !important;
    height: 43px;
  }
  p {
  top: 14px;
    left: 66px !important;
  
    font-size: 13px !important;
  
}}
}
.slider-thumb .thumb-main .ylogo {
  position: absolute;
  top: 29%;
  left: 48%;
  width: 11% !important;
}
.main_mumbai{
  width:300px !important;
.mum-head{ p {
  width: 151px !important;
  top: 9% !important;
  left: 42% !important;
  font-size: 5px !important;
  line-height: 0.35rem !important;
}}
.mum-main-video {
  top: 39% !important;
  left: 53% !important;
  iframe{
    width: 209px !important;
    height: 107px !important;
    border-radius: 13px;
  }
}
.mum-video1 {
  bottom: 58px !important;
  left: 59px !important;
.thumb-main {
  .thumb1 {
    width: 48px !important;
    height: 25px!important;
}
.ylogo {
  top: 9px;
  left: 19px;
  width: 13px !important;
}
}
}
.mum-video2 {
  bottom: 59px !important;
  left: 121px !important;
.thumb-main {.thumb1 {
  width: 48px !important;
    height: 25px!important;
}
.ylogo {
  top: 9px;
  left: 19px;
  width: 13px !important;
}}
}
.mum-video3 {
  bottom: 59px !important;
  right: 70px !important;

.thumb-main {.thumb1 {
  width: 48px !important;
    height: 25px!important;
}
.ylogo {
  top: 9px;
  left: 19px;
  width: 13px !important;
}}
}
.mum-video4 {
  bottom: 60px !important;
  right: 9px !important;
.thumb-main {.thumb1 {
  width: 48px !important;
    height: 25px!important;
}
.ylogo {
  top: 9px;
  left: 19px;
  width: 13px !important;
}}
}
.mum-quize {
  bottom: 108px !important;
  left: 71px !important;
p a {
font-size: 6px !important;

}}
.mum-step {
  bottom: 108px !important;
  right: 44px !important;
p a {
font-size: 6px !important;
}
}
.mum-show1 {
  bottom: 50px!important;
  left: 51px !important;
  

p a {
font-size: 7px;
}}
.mum-show3 {
  bottom: 35px !important;
  right: 98px !important;
p{ 
  display: flex;
  line-height: 0.45rem !important;
a {
font-size: 6px;

}}}
.mum-show2 {
  bottom: 50px !important;
  left: 104px !important;
p a {
font-size: 7px;
}}
.mum-show4 {
  bottom: 48px !important;
  right: 30px !important;
p a {
font-size: 6px;

}}
}
.main_bengaluru {
  width:300px !important;
  .ben-head p {
    color: #fff;
    width: 166px;
    position: absolute;
    top: 46px;
    left: 42%;
    line-height: 0.39rem !important;
    letter-spacing: 0.55px;
    font-size: 6px !important;
    text-align: justify;

}
.ben-main-video {
  top: 41% !important;
  left: 52% !important;
iframe {
  width: 205px !important;
  height: 100px !important;
}}
.ben-video1 {
  bottom: 30px !important;
  left: 59px !important;
.thumb-main{ .thumb1 {
  width: 53px !important;
    height: 29px !important;
}
.ylogo {
  top: 12px;
    left: 20px;
    width: 13px !important;
}}}

.ben-video2 {
  bottom: 30px !important;
left: 120px !important;
.thumb-main {.thumb1 {
  width: 53px !important;
    height: 29px !important;
}
.ylogo {
  top: 12px;
  left: 20px;
  width: 13px !important;
}}}
.ben-video3 {
  bottom: 30px !important;
right: 65px !important;
.thumb-main {.thumb1 {
  width: 53px !important;
  height: 29px !important;
}
.ylogo {
  top: 12px;
  left: 20px;
  width: 13px !important;
}}}
.ben-video4 {
  bottom: 30px !important;
right: 3px !important;
.thumb-main{ .thumb1 {
  width: 53px !important;
  height: 29px !important;
}
.ylogo {
  top: 12px;
    left: 20px;
    width: 13px !important;
}}}
.ben-quize {
 
  bottom: 86px !important;
  left: 71px !important;

p a {
font-size: 6px !important;
}}
.ben-step {
  bottom: 72px !important;
  right: 51px !important;
  p {
    line-height: 0.4rem !important;
    display: flex;
    a {
    font-size: 6px !important;
  }}
}
}
.main_delhi{
  width:300px !important;
.del-head p {
  width: 116px;
  position: absolute;
  top: 9%;
  left: 50%;
  line-height: 0.3rem !important;
  font-size: 4px !important;
  text-align: justify;
}
 .del-main-video {
 top: 39% !important;
 left: 50% !important;
 iframe {
  width: 176px !important;
  height: 96px !important;
  border-radius: 21px;
}
}
.del-quize {
  bottom: 88px !important;
  left: 61px !important;
p a{
  font-size: 7px !important;
}
}
.del-step {
  bottom: 88px !important;
  right: 30px !important;
p a{
  font-size: 7px !important;
}
}
.del-video1 {
  bottom: 29px !important;
    left: 58px !important;
.thumb-main{ 
.thumb1 {
  width: 55px !important;
  height: 28px !important;
}
.ylogo {
  top: 10px;
  left: 23px;
  width: 13px !important;
}}}
.del-video2 {
  bottom: 29px !important;
left: 120px !important;
.thumb-main {.thumb1 {
  width: 55px !important;
  height: 28px !important;
}
.ylogo {
  top: 10px;
  left: 23px;
  width: 13px !important;
}}}
.del-video3 {
  bottom: 29px !important;
  right: 62px !important;

.thumb-main {.thumb1 {
  width: 55px !important;
  height: 28px !important;
}
.ylogo {
  top: 10px;
  left: 23px;
  width: 13px !important;
}}}
.del-video4{
  bottom: 29px !important;
right: 1px !important;

.thumb-main{ .thumb1 {
  width: 55px !important;
  height: 28px !important;
}
.ylogo {
  top: 10px;
  left: 23px;
  width: 13px !important;

}}}
}
.main_kolkata {
  width:300px !important;
.kol-head p {
  width: 111px;
  position: absolute;
  top: 57px;
  left: 120px;
  line-height: 0.4rem !important;
  letter-spacing: 0.45px;
  font-size: 6px !important;
  text-align: justify;
}
.kol-main-video {
  top: 153px !important;
  left: 156px !important;
iframe {
  width: 212px !important;
  height: 103px !important;
}}
.kol-quize {
  top: 197px !important;
  left: 58px !important;
p a {
  font-size: 7px !important;
  
}
}
.kol-step {
  top: 205px !important;
  right: 41px !important;
p {
  display:flex;
  line-height: 0.45rem !important;
  a {
  font-size: 7px !important;
  
}}
}
.kol-video1 {
  bottom: 115px !important;
  left: 58px !important;
.thumb-main{ .thumb1 {
  width: 55px !important;
  height: 30px !important;
}
.ylogo {
  top: 11px;
  left: 19px;
  width: 13px !important;
}}}
.kol-video2 {
  bottom: 115px !important;
left: 120px !important;
.thumb-main{ .thumb1 {
  width: 55px !important;
  height: 30px !important;
}
.ylogo {
  top: 11px;
  left: 19px;
  width: 13px !important;
}}}
.kol-video3 {
  bottom: 115px !important;
right: 62px !important;
.thumb-main {.thumb1 {
  width: 55px !important;
  height: 30px !important;
}
.ylogo {
  top: 11px;
  left: 19px;
  width: 13px !important;
}}}
.kol-video4 {
  bottom: 115px !important;
right: 1px !important;

.thumb-main {.thumb1 {
  width: 55px !important;
  height: 30px !important;
}
.ylogo {
  top: 11px;
  left: 19px;
  width: 13px !important;
}}}
}
.red_section .img_1 p {
  left: 10% !important;
  top: 23px;
  color: #fff !important;
  font-size: 10px !important;
}
.red_section .img_2 img {
  width: 323px !important;
}
.red_section .img_1 img {
  width: 56px !important;
  left: 7% !important;
  top: 7% !important;
}
.red_section .img_2 p {
  position: absolute;
  left: 51px;
  top: 15px;
  color: #fff !important;
  font-size: 9px !important;
  text-align: start;
}
}
@media only screen and (max-width: 375px) {
.pink-bg .bang-label p {
  top: 14px;
  left: 53px;
  font-size: 10px !important;
}
.pink-bg .city-label .delhi-label {
  left: 63px !important;
}
.pink-bg .city-label p {
  top: 14px;
  left: 55px !important;
  font-size: 10px !important;
}
.red_section .img_1 img {
  width: 55px !important;
  left: 11px !important;
  top: 6% !important;
}
.red_section .img_1 p {
  left: 20px !important;
  font-size: 10px !important;
  top: 19px !important;
}
}
@media only screen and (max-width: 320px) {
  .red_section .img_2 img {
    width: 254px !important;
}
.pink-bg .bang-label p {
  top: 14px;
  left: 39px;
  font-size: 10px !important;
}
.red_section .img_1 img {
  width: 48px !important;
  left: 20px !important;
  top: 6%;
}
.red_section .img_1 p {
  left: 34px !important;
  font-size: 7px !important;
  top: 19px !important;
}
}