@import "fonts";
@import "colors";
@import "mixins";
@import "screen-sizes";

.p-very-small {
  font-size: $fs-10px !important;
  line-height: calc(0.65 * 1.2rem) !important;
}

.p-small {
  font-size: $fs-13px !important;
  line-height: calc(0.85 * 1.2rem) !important;
}

p {
  font-size: $fs-14px !important;
  line-height: calc(0.95 * 1.2rem) !important;
}

.p-large {
  font-size: $fs-16px !important;
  line-height: calc(1.125 * 1.2rem) !important;
}
.p-extra-large {
  font-size: $fs-42px !important;
  line-height: calc(0.95 * 1.2rem) !important;
}

.h6-medium {
  font-size: $fs-18px !important;
  line-height: calc(1.4 * 1.14rem) !important;
}

.h5-small {
  font-size: $fs-20px !important;
  line-height: calc(1.4 * 1.14rem) !important;
}

.h5-medium {
  font-size: $fs-22px !important;
  line-height: calc(1.6 * 1.14rem) !important;
}

.h4-medium {
  font-size: $fs-24px !important;
  line-height: calc(2.125 * 1.14rem) !important;
}

.h3-large {
  font-size: $fs-25px !important;
  line-height: calc(2.35 * 1.14rem) !important;
}

.h2-small {
  font-size: $fs-24px !important;
  line-height: calc(2.45 * 1.14rem) !important;
}

.h1-small {
  font-size: $fs-26px !important;
  line-height: calc(3.5 * 1.14rem) !important;
}

.h1-large {
  font-size: $fs-28px !important;
  line-height: calc(3.5 * 1.14rem) !important;
}

.h1-extra-large {
  font-size: $fs-42px !important;
  line-height: calc(3.5 * 1.14rem) !important;
}
