
.banner-section {
    .banner-photo{
        img{
            width:15% !important;
        }
        .youth-img{
            width:20%;
            img{
                width:100% !important
            }
        }
    }
    .banner-bg{
   background-color: #81d835 !important;
 
   height:25vh !important;
   z-index: -1;
   margin-top: -6%;
    }
}
.section-two{
    background-image: url("../../assets/GetPlan/paperbg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .grass-img{
        margin-top: -136px;
       
        background-image: url("../../assets/GetPlan/grass.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 100px;
    }
    .get-para{
     

    //    .get-data{
    //     width: 85%;
    //     float:right;
    //    }
        // transform: translate(-50%, -50%);
        .para-img{
            position: relative;
            width: 79%;
            float: right;
            img{
                width: 100% !important;
            }
        }
        .para-data{
            position: absolute;
            // top: 66px;
            // left: 5%;
            // padding: 0 6px;
            // width: 85%;
        //   position: absolute;
        //   top: 4px;
        //   left: 5%;
        top: 66px;
        left: 4%;
        padding: 0 14px 0 3px;
        img{
            width:97% !important;
        }
            .span-data{
                font-weight: 700;
                color: #ffff29;
            }
            p{
                line-height: 1.4rem !important;
                text-align: left;
                letter-spacing: 0.9px;
                font-size: 14px !important;
                font-weight: 400;
                padding: 0px 60px 0 14px;
                // width: 372px;
                // line-height: 1.75rem !important;
                // text-align: left;
                // letter-spacing: 0.65px;
                // font-size: 19px !important;
                // font-weight: 500;
              
                // line-height: 1.8rem !important;
                // text-align: left;
                // letter-spacing: 0.75px;
                // font-size: 24px !important;
                // font-weight: 500;
            }
        }
       
    }
    .get-video{
        
        .paper-section{
            width:79%;
            .poloimg{
                width:100% !important;
                position: relative;
            }
        }
        .video-paper{
          
            position: absolute;
            // top: 13%;
            // left: 5.5%;
            top: 17%;
            /* left: 4.5%; */
            right: 11%;
            width: 37%;
            .video-img{
                img{
                    // width:57%
                    width: 61%;
                }

            }
            .player-wrapper-small {
               
                border-radius: 0px !important;
               
            }
         .react-player {
            position: absolute;
            top: 0;
            left: 0;
            width: 58.5% !important;
            height: 100% !important;
        }
        }
    }
    
    .plan-text{
        p {
            width: 265px;
            line-height: 1.75rem !important;
            text-align: left;
            letter-spacing: 0.65px;
            font-size: 17px !important;
            .span-data{
                color: #81d835;
                font-weight: 900;
            }
        }
    }
   
    
}
.section-three{
    background-image: url("../../assets/GetPlan/Thirdpanel.png") ;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100% !important;
    height: 100%;
   .slider-head{
        p {
            width: 74%;
            line-height: 1.5rem !important;
            color: #fff;
            letter-spacing: 0.65px;
            font-size: 17px !important;
            
        }
    }
    .paper-section{
        .poloimg{
            width:67% !important;
        }
    }
    .lr-arrow {
        width: 28px;
        height: 41px !important;
    }
}
.section-four{
    // background-color: #fedf5e ;
    background-image: url("../../assets/GetPlan/paddy.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding-bottom: 20px !important;
     .four-head{
        background-color: rgb(9 5 5 / 42%);
        // width: 80%;
        width: 76%;
        border-radius: 20px;
        color: #fff;
        p {
            width: 95%;
            line-height: 1.5rem !important;
            letter-spacing: 0.65px;
            font-size: 17px !important;
            .span-data{
                font-weight: 800;
            }
            
        }
     }
   
    .video-two{
        img{
        width:65% !important;
        position: relative;
        }
        iframe{
    //  position: absolute;
    // transform: translate(-50%, -50%);
    // top: 69%;
    // left: 50%;
    // width: 42%;
    // height: 32%;
    // position: absolute;
    // transform: translate(-50%, -50%);
    // top: 72%;
    // left: 49.5%;
    // width: 57%;
    // height: 38%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 71%;
    left: 49.5%;
    width: 57%;
    height: 40%;

        }
     }
}
.section-five{
    background-image: url("../../assets/GetPlan/fifthbg.png");
  
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height:100%;
    width:100% !important;
    padding-bottom: 24px !important;
  padding-top: 24px !important;
    .video-three{
       img{
        width:85% !important;
        position: relative;
       } 
       iframe{
        position: absolute;
        transform: translate(-50%, -50%);
        top: 41%;
        left: 36.8%;
        width: 43%;
        height: 66%;
    
       }
    }
    .girl-img{
        width:80%;
        img{
            width:89% !important;
        }
    }
    .btn-img{
        width:81%;
        img{
            width:100% !important
        }
    }
    .trans-para{
        background-color: rgba(0, 0, 0, 0.43);
        width: 554px;
        padding: 10px 5px;
        color: white;
        
       p{
        font-size: 12px !important;
       }
    }
}
.get-slider{
    .slick-slider {
        padding: 0px 268px !important;
    }}
    .slider_bg {
        width: 100%;
        height: auto;
        filter: drop-shadow(0 0 15.5px rgba(0, 0, 0, 0.22000737488269806));
        background-color: #f5f0f0;
  
}
.bg-seven{
    // background-image: url(../../assets/GetPlan/Sevenpanel.png);
    // background-position:bottom;
    // background-repeat: no-repeat;
    // height: 100vh;
    // background-size: contain;
    background:  url(../../assets/GetPlan/paperbg.png)  no-repeat, url(../../assets/GetPlan/Sevenpanel.png) bottom no-repeat;
    padding-bottom: 10px !important;
     background-size: 100% , 100%;
     height: auto;
  .slider_bg {
    width: 100%;
    height: auto;
    filter: drop-shadow(0 0 0px rgba(0, 0, 0, 0.2200073749));
    background-color: #f5f0f0;
    img {
    width: 100% !important;
    margin: 5px;
    height: 485px;
}}
.sup-logo{
    img{
        width: 15% !important;
    }
}
}
// @media only screen and (max-width: 1326px){
//     .section-two .get-para .para-data {
//         position: absolute;
//         top: 10px;
//         left: 7%;
//         width: 368px;
//     }
// }
@media only screen and (max-width: 1024px){
    body{
        overflow-x: hidden;
    }

.banner-section {.banner-bg {
  
    height: 12vh !important;
   
}}
    .section-four {.video-two {iframe {
       
        top: 77%;
        left: 49.5%;
        width: 55%;
        height: 29%;
    }}
}
    .section-two {
        .grass-img {
            margin-top: -100px;
            padding-top: 82px;
          
        }
       
        .get-para {
            top: 14%;
            left: 16%;
        .para-data {
            top: 53px;
            left:5%;
         p {
        width: 306px;
        line-height: 1.7rem !important;
        text-align: left;
        letter-spacing: 0.5px;
        font-size: 16px !important;
        color: #050000;
    }
    }
    }
    .get-video .video-paper {
        position: absolute;
        top: 24%;
  }
}
.section-five .trans-para {
    background-color: rgba(0, 0, 0, 0.43);
    width: 451px;
    padding: 10px 5px;
    color: white;
    p{
        font-size: 14px !important;
    }
}
.bg-seven .slider_bg img {
    width: 100% !important;
    margin: 5px;
    height: 350px;
  }
}
@media only screen and (max-width: 834px){
    .banner-section .banner-bg {
    height: 7vh !important;
}
.section-two .get-para .para-data {
    top: 37px;
    left: 5%;
  }
.bg-seven .slider_bg img {
    width: 100% !important;
    margin: 5px;
    height: 300px;
  }
  .section-four{ .video-two {iframe {
    top: 81%;
    left: 49.5%;
    width: 55%;
    height: 26%;
  }}}
  
  .section-five {
   
 background-size: 100% 100%;
 .video-three iframe {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 37%;
    left: 36.8%;
    width: 45%;
    height: 61%;
  }
  .trans-para {
     width: 350px;
    }
    
  }
}
@media only screen and (max-width: 768px){
   
// .section-two {
//     .grass-img {
//         margin-top: -95px;
        
//     }
//      .get-para{
//      .para-data {
//      top: 0%;
//     left: 5%;
//         p {
//             width: 218px;
//             line-height: 1.12rem !important;
//             text-align: left;
//             letter-spacing: 0.5px;
//             font-size: 12px !important;
//             color: #050000;
//             margin-top: 30px !important;
//         }
// }
// .para-img{ img {
//     width: 67% !important;
// }}
//      }
//      .get-video .paper-section .poloimg {
//         width: 67% !important;
//         position: relative;
//     }
//     // .video-paper .video-img img {
//     //     width: 62%;
//     // }
//      }
// .banner-section {.banner-bg {
//     height: 10vh !important;
// }}
.section-four{
  
    .four-head {
       width: 90%;
         }
    .video-two iframe {
        top: 81%;
        left: 49.5%;
        width: 54%;
        height: 24%;
}
}
.section-five {.trans-para {
    width: 332px;
    padding: 5px 5px;
    color: white;
   
}}
.bg-seven .slider_bg img {
    width: 100% !important;
    margin: 5px;
    height: 270px;
}
.section-two .grass-img {
    margin-top: -100px;
    padding-top: 59px;
    .get-para {.para-img {
         width: 72%;
       }
       .para-data {
        top: 30px;
        left: 5%;
        img{
            width:100% !important
        }
    }
    }
    .get-video {
        .video-paper{ 
            top: 14%;
            .video-img{ img {
            width: 57%;
        }}
         .react-player {
            position: absolute;
            top: 0;
            left: 0;
            width: 54.5% !important;
    }
}
        
        .paper-section {
        width: 72%;
    }}
}
}
@media only screen and (max-width: 425px){
    .banner-section {
      
        .banner-bg{
       background-color: #81d835 !important;
       width: 100%;
       height: 4.3rem !important;
       z-index: -1;
       margin-top: -6%;
        }
    }
    .section-two {
        .grass-img{
            margin-top: -110px;
            padding-top: 53px;
        //    .img-grass{
        //     height:500px;
            
        //    }
        .get-video {.video-paper {.react-player {
            position: absolute;
            top: -18px;
            left: 3px;
            width: 46.5% !important;
           
        }
     
    }}
        }
      
         .get-para {
         .para-img{ 
          
            img {
                width: 100% !important;
                height: auto;
            }}
            .para-data {
                top: 14%;
                left: 2%;
               width: 94%;

               
            }
        }
    //     .plan-text p {
    //     width: 265px;
    //     line-height: 1rem !important;
    //     text-align: left;
    //     letter-spacing: 0.65px;
    //     font-size: 12px !important;
    // }

  .get-video{ 
   
    .paper-section {.poloimg {
        width: 100% !important;
        height: auto;
        position: relative;}
    } .video-paper {
        top:17%;
        left: 51.5%;
        width: 56%;
        .video-img {
            img {
            width: 50% !important;
        }
        .react-player {
            position: absolute;
            top: -26px;
            left: 4px;
            width: 46.5% !important;
        }
    }
       
    }}
}

// .section-two .get-video .video-paper .react-player {
//     position: absolute;
//     top: -9px;
//     left: 5px;
//     width: 52.5% !important;
//     height: 100% !important;
// }
    .get-slider{
        .slick-slider {
            padding: 0px 80px !important;
        }
    }
        .section-three {
            background-size: 100% 500px;
            .slider-head p {
            width: 100%;
            line-height: 1rem !important;
            color: #fff;
            letter-spacing: 0.65px;
            font-size: 11px !important;
        }
        .lr-arrow {
            width: 19px;
            height: 23px !important;
        }
        .prev-icon-container {
            left: 0.1rem !important;
        }
    }
        .section-four{
            background-size: 100% 640px;
             .four-head{
                 width: 89%;
                 padding: 10px;
                p {
                    width: 100%;
                    line-height: 0.75rem !important;
                    letter-spacing: 0.25px;
                    font-size: 10px !important;
                   
                    
                }
             }
        .video-two {img {
                width: 90% !important;
                position: relative;
            }
            
             iframe {
                // top: 79%;
                // left: 49.5%;
                // width: 76%;
                // height: 27%;
               
                    top: 81%;
                    left: 49.5%;
                    width: 74%;
                    height: 25%;
                
            }
            }
    }
 
    .section-five {
        background-size: cover;
        height: auto;
        background-position: -63px;
        .video-three {iframe {
            // top: 19%;
            // left: 50%;
            // width: 76%;
            // height: 25%;
            top: 18%;
            left: 49.5%;
            width: 76%;
            height: 28%;
        }
    }
    .trans-para {
      
        
            width: 310px;
            padding: 0px 1px !important;
        
        p{
            font-size: 12px !important
        }
    }
    .girl-img {
        width: 65%;
        margin: auto;
         img {
            width: 63% !important;
        }
    }
    .btn-img {
        width: 72%;
        margin: auto;
    }
}
    .bg-seven {
        background-size: 100% 600px, 100%;
        .slider_bg {
            img {
                height: auto;}
    }
    .sup-logo {img {
        width: 33% !important;
    }}
  
}

}
@media only screen and (max-width: 375px){
.section-four .video-two iframe {
    // top: 83%;
    // left: 50%;
    // width: 65%;
    // height: 22%;
    top: 81%;
    left: 50%;
    width: 74%;
    height: 25%;
}
.section-two .grass-img .get-video .video-paper{
    left: 51.5%;
    width: 56%;
    top: 19%;
}
.section-two .grass-img {
        margin-top: -104px;
    }  
.section-two {
   
    background-repeat: repeat;
}
.section-two .grass-img .get-video .video-paper .react-player{
    top: -34px;
    left: 2px;

    width: 42.5% !important;
   }

.section-two .grass-img .get-para .para-img {
    width: 65%;
}
.section-two .grass-img .get-video .paper-section {
    width: 65%;
}
.section-two .grass-img .get-para .para-data{
    top: 23px;
    left: 5%;
    width: 100%;
}
.section-two .get-video .video-paper .video-img img {
    width: 45% !important;
}
}
@media only screen and (max-width: 320px){
  
    .section-two .grass-img {
        margin-top: -84px;
        padding-top: 42px;
    }
    .section-two .get-video .video-paper .react-player {
        width: 49.5% !important;
    }
    .section-four .video-two iframe {
        top: 82%;
        left: 50%;
        width: 74%;
        height: 24%;
    }
}

