.header-container {
  position: fixed;
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  background: $white;
  justify-content: center;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 8px 6px #7b5e5e29;

  @include mobileScreen {
    align-items: center;
    justify-content: flex-end;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.menu-container {
  width: 95%;
  // margin-left: auto;
  // margin-right: auto;
}

.logo-container {
  width: $fs-48px;
  // @include mobileScreen {
  //   width: $fs-32px;
  // }
}

.custom-link--position {
  font-family: $robotoSlab-Regular;
  font-size: $fs-14px;
  cursor: pointer;
  text-transform: capitalize;

  .custom-link {
    // @include setPadding($fs-8px, $fs-42px, $fs-8px, $fs-42px);
    padding-left: 2rem;
    padding-bottom: 0.5rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    color: $boulder-grey;
    cursor: pointer;

    @include desktop-max {
      @include setPadding($fs-8px, $fs-12px, $fs-8px, $fs-12px);
    }

    &:hover {
      //color: $kelly-green-light;
      cursor: pointer;
    }

    &.custom-link-active {
      cursor: pointer;
      font-family: $robotoSlab-Regular;
      font-weight: 600;
      // color: $kelly-green-light !important;
    }
  }
}

.custom-link--mobile-position {
  font-family: $robotoSlab-Bold;
  font-size: $fs-18px;
  text-transform: capitalize;
  cursor: pointer;

  .custom-link {
    width: 100%;
    display: flex;
    @include setPadding($fs-16px, $fs-16px, $fs-16px, $fs-16px);
    color: $boulder-grey;
    cursor: pointer;

    &:hover {
      //color: $kelly-green-light !important;
      cursor: pointer;
      //border-bottom: $fs-3px solid $kelly-green-light !important;
    }

    &.custom-link-active {
      cursor: pointer;
      font-family: $robotoSlab-Bold;
      font-weight: 600;
      // border-bottom: $fs-3px solid $kelly-green-light !important;
      // color: $kelly-green-light !important;
    }
  }
}

.menu-icon {
  width: $fs-26px;
}

.mobile-header {
  position: absolute;
  left: 0;
  right: 0;
  width: 70%;
  height: 100vh;
  top: 0;
  // min-height: 50vh;
  background: $white;
  z-index: 999;
  padding-top: $fs-24px;
  padding-left: $fs-16px;
  padding-right: $fs-16px;
  box-shadow: 0px 0px 6px #00000029;
  overflow-y: scroll;
  @include desktop {
    width: 50%;
  }
}

.side-menu__logo-contianer {
  width: 35%;
}

.close-icon {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 99;
}

.head-nav {
  width: 200px;
  background-color: #fff;
  color: $boulder-grey;
  @include setPadding($fs-8px, $fs-12px, $fs-8px, $fs-12px);
}

// .dropdown {
//   position: relative;
// }

// .menu {
//   position: absolute;
//    list-style-type: none;
//   margin: 10px 0;
//   padding: 0px;
//    width: 250px;
//     box-shadow: 1px 0px 3px 1px #0000004f;
// }

// .menu > li {
//   margin: 0;

//   background-color: white;
// }

// .menu > li:hover {
//   background-color: lightgray;
// }
// .menu >li >a {
//   color: $boulder-grey;
// }

// .menu > li > a > button {
//   width: 100%;
//   height: 100%;
//   text-align: left;

//   background: white;
//   color: inherit;
//   border: none;
//   @include setPadding($fs-8px, $fs-12px, $fs-8px, $fs-12px); 
//   margin: 0;
//   font: inherit;
//   cursor: pointer;
// }
.dropdown {
  padding: 20px 40px;
  display: flex;
  // justify-content: center;
  align-items: center;


  position: relative;

  perspective: 1000px;
  z-index: 100;
}

.dropdown:hover {

  cursor: pointer;
}

.dropdown:hover .dropdown_menu {
  li {
    list-style: none;

    a {
      display: block;
      list-style: none;
      text-decoration: none;
      color: inherit;

    }
  }
}

.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  perspective: 1000px;
  z-index: -1;
}

.dropdown_menu li a {
  display: none;
  // color: #fff;
  background-color: #fff;
  padding: 7px 10px;

  opacity: 0;
  list-style: none;
}

.dropdown_menu li a:hover {
  background-color: #cfd6db;
  // color: #fff !important;

}

.dropdown:hover .dropdown_menu--animated {
  display: block;
  width: 250px;
}

.dropdown_menu--animated {
  display: none;

}

.dropdown_menu--animated li a {
  display: block;
  opacity: 1;
}

.dropdown_menu-6 {
  animation: growDown 700ms ease-in-out forwards;
  transform-origin: top center;
}

@-moz-keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@-webkit-keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@-o-keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@media only screen and (max-width: 820px) {
  .dropdown {
    flex-direction: column;
    align-items: start;
    width: 230px;
   
  }

  .dropdown_menu {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 25px;
    perspective: 1000px;
    z-index: -1;
    padding: 4px 0 0 0px;
    // height: 200px;
    // overflow-x: scroll;
  }
}