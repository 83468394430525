.goalone {
    background-image: url("../../assets/GoalNew/section1/section1.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // height: 720px;
    height: auto;

    .goal-tv {
        background-image: url("../../assets/GoalNew/section1/tv.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        // height: 450px;
        // position: relative;
        // width: 80%;
        margin: auto;
        height: 596px;
        position: relative;
        width: 100%;

        .sli-img {
            width: 100%;

            .sli {

                width: 90%;
                margin: auto;
                padding-top: 43px !important;
                border-radius: 20px !important;

                .lr-arrow {
                    width: 20px !important;
                    height: 53px !important;
                }

                .slick-slider {
                    padding: 0px 10px !important;

                    img {
                        height: 457px;
                        border-radius: 20px;
                    }
                }

                .prev-icon-container {
                    left: -7rem !important;
                }

                .next-icon-container {
                    right: -7rem !important;
                }

                //   .slick-prev, .slick-next {
                //     width: 9.75rem !important;
                //     height: 12.75rem !important;
                //   }
            }
        }

        .beach {
            position: absolute;
            top: -18%;
            right: 7%;
            width: 25%;
        }

        .sli-tree {
            // position: absolute;
            // bottom: -9%;
            // left: -10.5%;
            // width: 100%;
            // height: 300px;
            // position: absolute;
            // bottom: -9%;
            // left: -11%;
            // width: 90%;
            // height: 350px;

            position: absolute;
            bottom: -5%;
            left: -10%;
            width: 28%;
            height: 334px;
            object-fit: contain;


        }

        .sli-tree3 {
            width: 63%;
            position: absolute;
            left: 22%;
            bottom: 4%;
        }

        .sli-tree1 {
            position: absolute;
            bottom: -4%;
            right: -11%;
            width: 22%;
            height: 317px;
            object-fit: contain;

        }

    }

}

.goaltwo {

    //   height:500px;
    .prev-icon-container {
        left: 2.5rem !important;
    }

    .next-icon-container {
        right: 2.5rem !important;
    }

    .lr-arrow {
        width: 20px !important;
        height: 40px;
    }

    .goal2-img {
        position: relative;

        .img-sec {
            position: absolute;
            top: -142px;
            width: 122%;
            left: -122px;
        }
    }

    .goal-sli2 {
        position: relative;

        .goal-i {
            width: 34%;
            position: absolute;
            top: -35px;
            z-index: 1;
            left: 199px;

        }
    }

    .play-p {
        width: 382px;
        text-align: start;
        margin: auto;

        p {
            span {
                font-size: 600;
                color: #a22ad0;
            }

            font-size: 21px !important;
            line-height: 2.3rem !important;
        }

    }
}

.goalthree {
    background-image: url("../../assets/GoalNew/section3/section3.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    // height: 720px;
    // height: auto;
    .play-p {
        width: 458px;
        text-align: start;
        margin: auto;

        p {
            font-size: 26px !important;
            font-weight: 400;
            line-height: 2rem !important;
        }
    }

    .play-img {
        width: 90%
    }
}

.goalfour {
    background-image: url("../../assets/GoalNew/section4/section4.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    //  height: 720px;
    height: auto;

    .goal-ball {
        width: 69%;
        margin: auto;
        margin-top: -132px;
    }

    .goal-p {
        p {
            font-size: 23px !important;
            text-align: start;
            line-height: 1.9rem !important;
        }
    }

    .goal-tv1 {
        background-image: url("../../assets/GoalNew/section4/sec4-tv1.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 25px 20px 30px 18px;
        width: 496px;
        margin: auto;
        height: 383px;

        iframe {
            border-radius: 20px;
            width: 100%;
            height: 318px;

        }
    }

    .goal-tv2 {
        background-image: url("../../assets/GoalNew/section4/sec4-tv2.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 28px 45px 12px 45px;
        margin: auto;
        height: 500px;

        iframe {
            border-radius: 20px;
            width: 100%;
            height: 410px;

        }
    }
}

.goal-5 {
    background-image: url("../../assets/GoalNew/section5/sec5.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    //  height:877px;
    .goal-img {
        width: 60%;
        margin: auto;
    }

    .goal-tab {
        width: 53%;
        margin: auto;
        padding-bottom: 40px;

        .tab-style {
            background-color: #9a29cf;
            padding: 20px 0;
            width: 200px;
            border-radius: 12px;

            p {
                color: #fff;
                font-size: 18px !important;
                font-weight: 500;
            }
        }
    }

}

.goal-head {

    p {

        font-size: 20px !important;
        font-weight: 600;
    }
}

.goal-logos {
    width: 30%;
    margin: auto;
}

@media screen and (max-width:1024px) {
    .goaltwo {
        .goal2-img {
            .img-sec {

                top: -114px;

            }
        }

        .play-p {
            width: 316px;

            p {
                font-size: 18px !important;
                line-height: 1.6rem !important;
            }
        }

        .slick-slider {
            padding: 0 80px !important;
        }
    }

    .goal-5 {
        .goal-tab {

            .tab-style {

                width: 157px;

            }
        }
    }

    .goalone {
        .goal-tv {
            .sli-tree {

                bottom: -8%;
                left: -10%;
                width: 33%;
                height: 334px;

            }

            .sli-tree3 {
                width: 57%;
                position: absolute;
                left: 25%;
                bottom: 5%;
            }

            .sli-tree1 {
                position: absolute;
                bottom: -8%;
            }
        }
    }
}

@media screen and (max-width:834px) {
    .goalone {
        .goal-tv {
            // height: 530px;
            // position: relative;
            // width: 88%;
            height: 470px;
            position: relative;
            width: 95%;

            .sli-img {
                .sli {
                    .slick-slider {
                        img {
                            // height: 408px;
                            // border-radius: 20px;
                            height: 344px;
                            border-radius: 20px;
                            object-fit: cover;
                        }
                    }
                }
            }

            .sli-tree {
                bottom: -7%;
                left: -11%;
                width: 37%;
                height: 280px;
                object-fit: cover;

            }

            .sli-tree1 {
                position: absolute;
                bottom: -7%;
                right: -11%;
                width: 26%;
                height: 280px;
                object-fit: cover;

            }

            .beach {
                width: 30%;
                top: -16%;

            }
        }
    }

    .goaltwo {
        .play-p {
            p {
                font-size: 17px !important;
                font-weight: 400;
                line-height: 1.6rem !important;
            }
        }

        .goal-sli2 .goal-i {
            width: 45%;
            position: absolute;
            top: -25px;
            z-index: 1;
            left: 102px;
        }

        .goal2-img {
            .img-sec {

                width: 111%;
                left: -40px;

            }
        }

        .slick-slider {
            padding: 0 50px !important;
        }
    }


    .goalthree {
        .play-p {
            width: 325px;
            text-align: start;
            margin: auto;

            p {
                font-size: 17px !important;

                font-weight: 400;
                line-height: 1.4rem !important;
            }
        }
    }

    .goalfour {

        .goal-p {
            p {
                font-size: 17px !important;
                text-align: start;
                line-height: 1.6rem !important;
            }
        }

        .goal-tv1 {
            padding: 16px 14px 30px 13px;
            width: 306px;
            margin: auto;
            height: 300px;

            iframe {
                height: 255px;
            }
        }

        .goal-tv2 {

            padding: 28px 27px 12px 27px;
        }
    }

    .goal-5 {
        .goal-tab {

            .tab-style {

                width: 118px;

                p {
                    font-size: 14px !important;
                }

            }
        }
    }

    .goal-logos {
        width: 48%;
        margin: auto;
    }
}

// @media screen and (max-width:768px) {
//     .goalone {
//         .goal-tv {
//             .sli-tree {
//                 bottom: -9%;
//                 left: -11%;
//                 width: 95%;
//                 height: 280px;
//                 object-fit: fill;
//             }

//             .sli-tree1 {

//                 bottom: -8%;
//                 right: -11%;
//                 width: 24%;
//                 height: 280px;
//                 object-fit: fill;
//             }
//         }
//     }
// }

@media screen and (max-width:425px) {
    .goalone {
        .goal-tv {
            height: 255px;

            .sli-tree {
                bottom: -6%;
                left: -16%;
                width: 41%;
                height: 150px;
                object-fit: cover;
            }

            .sli-tree1 {
                
                bottom: -4%;
                right: -16%;
                width: 29%;

                height: 159px;
            }
            .sli-tree3 {
                width: 58%;
               
                left: 25%;
                bottom: 7%;
            }

            .sli-img {
                .sli {
                    padding-top: 22px !important;

                    .slick-prev,
                    .slick-next {
                        width: 2.75rem !important;
                        height: 4.75rem !important;
                    }

                    .lr-arrow {
                        width: 20px !important;
                        height: 34px !important;
                    }

                    .prev-icon-container {
                        left: -4rem !important;
                    }

                    .next-icon-container {
                        right: -4rem !important;
                    }

                    .slick-slider {
                        img {
                            height: 190px;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }

    .goaltwo {
        .prev-icon-container {
            left: 0.7rem !important;
        }

        .next-icon-container {
            right: 0.5rem !important;
        }

        .goal2-img {
            .img-sec {
                width: 115%;
                left: -26px;
                top: -48px;

            }
        }
    }

    .goalthree {
        .play-img {
            width: 90%;
            margin: auto;
        }
    }

    .goalfour {
        .goal-tv2 {
            height: 350px;
            padding: 23px 17px 12px 16px;

            iframe {
                border-radius: 9px;
                width: 100%;
                height: 281px;
            }
        }

        .goal-ball {
            width: 102%;
            margin: auto;
            margin-top: -92px;
        }

    }

    .goal-5 {
        .goal-img {
            width: 80%;

        }

        .goal-tab {
            .tab-style {
                width: 91px;
                padding: 10px 0;

                p {
                    font-size: 11px !important;
                }
            }
        }
    }

    .goal-head p {
        font-size: 16px !important;
        font-weight: 600;
    }

    .goal-logos {
        width: 79%;
        margin: auto;
    }

}

@media screen and (max-width:390px) {
    .goal-5 .goal-tab {
        width: 63%;
        margin: auto;
        padding-bottom: 40px;
    }

    .goaltwo .goal2-img .img-sec {
        width: 115%;
        left: -26px;
        top: -45px;
    }
    .goalone .goal-tv .sli-tree {
        bottom: -5%;
        left: -18%;
        width: 44%;
        height: 150px;
        object-fit: cover;
    }
}

@media screen and (max-width:375px) {
    .goalone {
        .goal-tv {
            height: 250px;
            width: 80%;

            .sli-tree {
                bottom: -7%;
                left: -16%;
                width: 44%;
                height: 150px;
                object-fit: fill;
            }
            .sli-tree1 {
                bottom: -10%;
                right: -19%;
                width: 37%;
                height: 159px;
                object-fit: contain;
            }

            .sli-img .sli .slick-slider img {
                height: 183px;
                border-radius: 9px;
            }
        }
    }

    .goaltwo {
        .goal2-img {
            .img-sec {
                top: -41px;
            }
        }
    }

    .goalfour {
        .goal-tv1 {
            padding: 16px 14px 30px 13px;
            width: 283px;

            iframe {
                border-radius: 8px;
            }
        }

        .goal-tv2 {
            padding: 21px 13px 12px 13px;
            height: 340px;

            iframe {
                height: 273px;
            }
        }
    }

    .goal-5 {
        .goal-tab {
            width: 66%;
            margin: auto;
            padding-bottom: 19px;

            .tab-style {
                width: 99px;
            }
        }
    }
}

@media screen and (max-width:320px) {
    .goalone .goal-tv {
        height: 200px;

        .sli-img .sli .slick-slider img {
            height: 145px;
            border-radius: 20px;
        }
    }

    .goalone .goal-tv .sli-tree {
        bottom: -5%;
        left: -19%;
        width: 46%;
        height: 102px;
        object-fit: contain;
    }
    .goalone .goal-tv .sli-tree1 {
        bottom: -16%;
        right: -16%;
        width: 33%;
        height: 159px;
        object-fit: contain;
    }

    .goaltwo .goal2-img .img-sec {
        top: -37px;
    }

    .goaltwo .play-p {
        width: 280px;
    }

    .goalthree .play-p {
        width: 293px;
    }

    .goalfour {
        .goal-ball {
            width: 101%;
            margin: auto;
            margin-top: -75px;
        }
    }

    .goal-5 {
        .goal-tab {
            .tab-style {
                width: 85px;
            }
        }
    }

}