.custom-theme-btn {
   box-shadow: 0px $fs-8px $fs-6px #72C3DB29;
  font-family: $robotoSlab-Regular;
  font-size: $fs-14px !important;
  border-radius: $fs-4px !important;
  @include setFont(500, normal, normal, normal, center);
  @include setPadding(
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important
  );
  @include setBtnColor(
    $mosque-green !important,
    $mosque-green !important,
    $white !important
  );
  &:hover {
    @include setBtnColor(
      $white !important,
      //background-color
      $white !important,
      //border-color
      $mosque-green !important//color
    );
    border-color: $mosque-green !important;
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $white !important,
      $white !important,
      $mosque-green !important
    );
    border-color: $mosque-green !important;
  }
  &:disabled {
    @include setBtnColor(
      $mosque-green !important,
      $mosque-green !important,
      $white !important
    );
    cursor: not-allowed;
    border-color: $mosque-green !important;
  }
}

.custom-cornflower-blue-btn {
  box-shadow: 0px $fs-8px $fs-6px #72C3DB29;
 font-family: $robotoSlab-Regular;
 font-size: $fs-14px !important;
 border-radius: $fs-4px !important;
 @include setFont(500, normal, normal, normal, center);
 @include setPadding(
   $fs-8px !important,
   $fs-8px !important,
   $fs-8px !important,
   $fs-8px !important
);
 @include setBtnColor(
   $denim-blue !important,
   $denim-blue !important,
   $white !important
 );
 &:hover {
   @include setBtnColor(
     $white !important,
     //background-color
     $white !important,
     //border-color
     $denim-blue !important//color
   );
   border-color: $denim-blue !important;
 }
 &:focus {
   box-shadow: none !important;
   @include setBtnColor(
     $white !important,
     $white !important,
     $denim-blue !important
   );
   border-color: $denim-blue !important;
 }
 &:disabled {
   @include setBtnColor(
     $denim-blue !important,
     $denim-blue !important,
     $white !important
   );
   cursor: not-allowed;
   border-color: $denim-blue !important;
 }
}

.custom-danger-btn{
  box-shadow: 0px $fs-8px $fs-6px #72C3DB29;
  font-family: $robotoSlab-Bold;
  font-size: $fs-14px !important;
  border-radius: $fs-5px !important;
  @include setFont(500, normal, normal, $fs-1px , center);
  @include setPadding(
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important
  );
  @include setBtnColor(
    $crimson-red !important,
    $crimson-red !important,
    $white !important
  );
  &:hover {
    @include setBtnColor(
      $white !important,
      //background-color
      $white !important,
      //border-color
      $crimson-red !important//color
    );
    border-color: $crimson-red !important;
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $white !important,
      $white !important,
      $crimson-red !important
    );
    border-color: $crimson-red !important;
  }
  &:disabled {
    @include setBtnColor(
      $crimson-red !important,
      $crimson-red !important,
      $white !important
    );
    cursor: not-allowed;
    border-color: $crimson-red !important;
  }
}

.custom-edit-btn{
  box-shadow: 0px $fs-8px $fs-6px #72C3DB29;
  font-family: $robotoSlab-Bold;
  font-size: $fs-14px !important;
  border-radius: $fs-4px !important;
  @include setFont(500, normal, normal, $fs-1px, center);
  @include setPadding(
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important
  );
  @include setBtnColor(
    $kelly-green !important,
    $kelly-green !important,
    $white !important
  );
  &:hover {
    @include setBtnColor(
      $white !important,
      //background-color
      $white !important,
      //border-color
      $kelly-green !important//color
    );
    border-color: $kelly-green !important;
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $white !important,
      $white !important,
      $kelly-green !important
    );
    border-color: $kelly-green !important;
  }
  &:disabled {
    @include setBtnColor(
      $kelly-green !important,
      $kelly-green !important,
      $white !important
    );
    cursor: not-allowed;
    border-color: $kelly-green !important;
  }
}
.custom-btn-small {
  @include setPadding(
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important
  );
}

.custom-btn--very-small{
  @include setPadding(
    $fs-4px !important,
    $fs-8px !important,
    $fs-4px !important,
    $fs-8px !important
  );
  font-size: $fs-16px;
}