.out-one {
    background-image: url("../../assets/OutDoor/First/firstt.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // height: 720px;
    height: auto;
    width: 100%;

    .out1 {
        background-image: url("../../assets/OutDoor/First/first-panel.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        // height: 450px;
        // position: relative;
        // width: 80%;
        margin: auto;

        height: 486px;
        position: relative;
        width: 83%;

        .sli-img {
            width: 100%;

            .sli {

                width: 90%;
                margin: auto;
                padding-top: 43px !important;
                border-radius: 20px !important;

                .lr-arrow {
                    width: 21px;
                    height: 41px !important;
                }

                .slick-slider {
                    padding: 0px 10px !important;

                    img {
                        height: 347px;
                        border-radius: 20px;
                    }
                }

                .prev-icon-container {
                    left: -5rem !important;
                }

                .next-icon-container {
                    right: -5rem !important;
                }

                //   .slick-prev, .slick-next {
                //     width: 9.75rem !important;
                //     height: 12.75rem !important;
                //   }
            }
        }

        .beach {
            //   position: absolute;
            //   top: -25%;
            //  right: 0%;
            //  width: 36%;   
            position: absolute;
            top: -23%;
            right: 5%;
            width: 32%;
        }

        .sli-tree {
            position: absolute;
            // bottom: -4%;
            // left: -5%;
            // width: 113%;
            bottom: -3%;
            left: -2.5%;
            width: 109%;
            height: 300px;

        }

    }

    .cele {
        // width:40%;
        width: 38%;
        margin: auto;
    }
}

.out-two {
    background-image: url("../../assets/OutDoor/second/second-bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // height: 700px;
    height: auto;
    width: 100%;

    .foot {
        width: 15%;
        margin: -105px 47px;
    }

    .girl-pa {
        // width:85%;
        margin: auto;

        .high-bold {
            font-weight: 700 !important;
            letter-spacing: 0.3px;
        }

        p {
            span {
                color: #ff008a;
                font-weight: 600;

            }

            // font-size: 19px !important;
            // line-height: 1.85rem !important;
            // font-weight: 400;
            // letter-spacing: 0.50px;
            // text-align: start;
            font-size: 18px !important;
            line-height: 1.5rem !important;
            font-weight: 500;
            letter-spacing: 0.3px;
            text-align: start;
        }
    }

    .second-sli {
        background-image: url("../../assets/OutDoor/second/slider2-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 550px;

        .slick-slider {
            padding: 77px 35px !important;

            .lr-arrow {
                width: 21px;
                height: 41px !important;
            }

        }

        .prev-icon-container {
            left: -3rem !important;
        }

        .next-icon-container {
            right: -2.5rem !important;
        }

        img {
            width: 100%;
            height: 370px;
            border-radius: 20px;
        }
    }
}

.out-three {
    background-image: url("../../assets/OutDoor/third/third-bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // height: 700px;
    height: auto;
    width: 100%;

    .foot {
        width: 15%;
        margin: -105px 47px;
    }

    .sust-img {
        width: 85%;
        margin: auto;
    }

    .foot-para {
        p {
            //    font-size: 19px !important;
            //     line-height: 1.7rem !important;
            //     font-weight: 400;
            //     letter-spacing: 0.50px;
            //     text-align: start;
            font-size: 18px !important;
            line-height: 1.5rem !important;
            font-weight: 500;
            letter-spacing: 0.3px;
            text-align: start;

        }
    }

    .second-sli2 {
        background-image: url("../../assets/OutDoor/third/slider-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 530px;

        .slick-slider {
            padding: 77px 35px !important;


            .lr-arrow {
                width: 21px;
                height: 41px !important;
            }
        }

        .prev-icon-container {
            left: -3rem !important;
        }

        .next-icon-container {
            right: -2.5rem !important;
        }

        img {
            width: 100%;
            height: 365px;
            border-radius: 20px;
        }
    }
}

.out-four {
    background-image: url("../../assets/OutDoor/forth/forth-bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    //  height: 700px;
    height: auto;
    width: 100%;

    .foot {
        width: 18%;
        margin: -98px 73px;
    }

    .tree-img {
        width: 60%;
        margin: auto;
    }

    .tree-para {
        p {
            span {
                color: #ff008a;
                font-weight: 600;
            }

            font-size: 18px !important;
            line-height: 1.5rem !important;
            font-weight: 500;
            letter-spacing: 0.3px;
            text-align: start;

        }
    }

    .four-tv {
        background-image: url("../../assets/OutDoor/forth/four-tv.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 450px;
        padding: 25px 26px 35px 27px;

        iframe {
            width: 100%;
            height: 360px;
            border-radius: 20px;
        }



        img {
            height: 360px !important;
            border-radius: 20px;
        }
    }
}

.out-five {
    background-image: url("../../assets/OutDoor/fifth/fifth-bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    //   height: 750px;
    height: auto;
    width: 100%;

    .fifth-tree {
        display: none;
    }

    .goal1 {
        width: 60%;
        margin: -99px 70px;
    }

    .goal3 {
        width: 48%;
        margin: -78px 124px 0px 124px;
    }

    .goa {
        margin: -139px -5px;

        .goal2 {
            width: 258px;

        }

        p {
            span {
                color: #ff008a;
                font-weight: 600;
            }

            font-size: 17px !important;
            line-height: 1.2rem !important;
            font-weight: 500;
            letter-spacing: 0.3px;
            text-align: start;

        }
    }

    .five-tv {
        background-image: url("../../assets/OutDoor/fifth/tv1.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 390px;
        padding: 25px 26px 35px 27px;

        iframe {
            width: 100%;
            height: 325px;
            border-radius: 20px;
        }

        //    .player-wrapper-small {
        //     height: 340px;
        //     border-radius: 20px;
        //     .react-player {

        //        top: -18px;
        //     }
        //   }
        img {
            height: 325px !important;
            border-radius: 20px;
        }
    }
}

@media only screen and (max-width: 1024px) {

    .out-one {
        background-size: 100% 100%;

        // height: 520px;
        .out1 {

            height: 347px;

            .sli-img .sli .slick-slider img {
                height: 240px;
                border-radius: 20px;
            }

            .sli-tree {

                height: 205px;
            }
        }

    }

    .out-two {
        .second-sli {

            height: 490px;

            .slick-slider {
                padding: 64px 35px !important;
            }

            img {
                height: 350px;
            }
        }

        .foot {
            width: 15%;
            margin: -105px 34px !important;
        }

        .girl-pa {
            p {
                font-size: 14px !important;
            }
        }
    }

    .out-three {
        .second-sli2 {
            height: 520px;

            .slick-slider {
                padding: 69px 35px !important;
            }

            img {
                height: 375px;
            }
        }

        .foot {
            width: 17%;
            margin: -105px 35px !important;
        }

        .foot-para {
            p {
                font-size: 15px !important;
            }
        }
    }

    .out-four {
        .four-tv {
            // height: 420px;
            height: 406px;

            img {
                height: 326px !important
            }

            iframe {
                height: 326px;
            }
        }

        .mg {
            // margin-bottom: 36px;
            margin-bottom: 19px;
        }

        .foot {
            width: 20%;
            margin: -88px 35px !important;
        }
    }

    .out-five {
        .goal1 {
            width: 68%;
            margin: -77px 41px !important;
        }

        .goal3 {
            width: 52%;
            margin: -84px 110px 0px 77px !important;
        }

        .goa {
            margin: -91px -5px 0 -5px;

            p {
                font-size: 14px !important;
                line-height: 1.1rem !important;
            }
        }

        .five-tv {
            padding: 25px 20px 35px 18px;
        }

    }
}

@media only screen and (max-width: 834px) {
    .out-five .goa {
        margin: -62px 6px 0 -5px;
    }

    .out-two .foot {
        width: 15%;
        margin: -75px 34px !important;
    }
}

@media only screen and (max-width: 768px) {

    .out-one {
        background-size: 100% 100%;

        // height: 480px;
        .out1 {
            height: 296px;

            .sli-img {
                .sli {
                    .prev-icon-container {
                        left: -3rem !important;
                    }

                    .next-icon-container {
                        right: -3rem !important;
                    }

                    .slick-slider img {
                        height: 200px;
                        border-radius: 20px;
                    }
                }
            }

            .sli-tree {
                height: 168px;
            }
        }
    }

    .out-two {

        // height: 525px;
        .foot {

            margin: -74px 47px !important;
        }

        .girl-pa {
            padding: 0px 10px !important;

            p {
                font-size: 15px !important;
                line-height: 1rem !important;
            }
        }

        .second-sli {
            height: 450px;

            img {
                height: 290px;
            }
        }
    }

    .out-three {
        .foot {
            width: 17%;
            margin: -79px 35px !important;
        }

        .foot-para {
            p {
                font-size: 14px !important;
                line-height: 1rem !important;
            }
        }

        .sust-img {
            width: 77%;
            margin: auto;
        }

        .second-sli2 {
            height: 490px;

            img {
                height: 343px;
            }

            .next-icon-container {
                right: -2rem !important;
            }

            .prev-icon-container {
                left: -2rem !important;
            }
        }
    }

    .out-four {
        .mg {
            margin-bottom: 0px;
        }

        .foot {
            width: 20%;
            margin: -79px 45px !important;
        }

        .tree-para p {
            font-size: 14px !important;
            line-height: 1rem !important;
        }

        .four-tv {

            height: 350px;

            img {
                height: 280px !important;
            }

            iframe {
                height: 280px !important;
            }
        }
    }

    .out-five {
        .goal1 {
            width: 68%;
            margin: -44px 41px !important;
        }

        .goal3 {
            width: 52%;
            margin: -65px 0px 0px 70px !important;
        }

        .goa {
            margin: -71px 10px 0 -10px;

            p {
                font-size: 13px !important;
                line-height: 1rem !important;
                letter-spacing: 0.35px;
            }

            .goal2 {
                width: 195px;
            }
        }

        .five-tv {
            height: 300px;
            padding: 18px 10px 35px 9px;

            img {
                height: 250px !important;
            }

            iframe {
                height: 250px !important;
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .out-one {
        background-image: url("../../assets/OutDoor/First/first-mg.jpg");
        height: auto;

        .cele {
            // width: 56%;
            width: 64%;

        }

        .out1 {
            // height: 160px;
            height: 209px;
            width: 95%;

            .sli-tree {
                // height: 89px;
                height: 104px;
                bottom: -3%;
                left: -3.5%;
                width: 111%;
            }

            .sli-img {
                .sli {
                    padding-top: 17px !important;

                    .lr-arrow {
                        width: 14px;
                        height: 31px !important;
                    }

                    .next-icon-container {
                        right: -2rem !important;
                    }

                    .prev-icon-container {
                        left: -2rem !important;
                    }

                    .slick-slider img {
                        height: 153px;
                        border-radius: 7px;
                    }
                }
            }
        }

    }

    .out-two {
        background-image: url("../../assets/OutDoor/second/second2-mg.jpg");
        background-repeat: no-repeat;
        background-size: 100% 662px;
        // height:690px;
        height: 662px;

        .foot {
            margin: -52px -15px !important;
            width: 21%;
        }

        .second-sli {
            // margin: 0px 40px 10px 80px;
            // margin: 0px 25px 10px 92px;
            margin: 0px 38px 10px 67px;
            height: 300px;

            .prev-icon-container {
                left: -1.5rem !important;
            }

            .next-icon-container {
                right: -1.5rem !important;
            }

            .slick-slider {
                // padding: 44px 35px !important;

                padding: 39px 25px !important;

                .lr-arrow {
                    width: 14px;
                    height: 31px !important;
                }
            }

            img {
                height: 205px;
                border-radius: 9px;
            }
        }

        .girl-pa {
            // padding: 10px 47px 10px 106px !important;
            padding: 10px 37px 10px 78px !important;

            p {
                font-size: 14px !important;
                line-height: 1.1rem !important;
                margin-bottom: 0.45rem !important;
            }
        }
    }

    .out-three {
        background-image: url("../../assets/OutDoor/third/third-mg.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .sust-img {
            width: 92%;
            margin: auto;
            margin-top: -38px;
        }

        .foot-para {
            p {
                font-size: 14px !important;
                line-height: 1.1rem !important;
            }
        }

        .foot {
            width: 20%;
            margin: -58px -14px !important;
        }

        .second-sli2 {
            // margin: 0px 51px 10px 70px;
            // margin: 0px 39px 10px 85px;
            margin: 0px 52px 10px 51px;
            height: 300px;

            .slick-slider {
                padding: 44px 25px 0px 25px !important;

                .lr-arrow {
                    width: 14px;
                    height: 31px !important;
                }

                .prev-icon-container {
                    left: -1.5rem !important;
                }

                .next-icon-container {
                    right: -1.5rem !important;
                }
            }

            img {
                height: 205px;
                border-radius: 5px;
            }
        }
    }

    .out-four {
        background-image: url("../../assets/OutDoor/forth/forth-mg.jpg");

        .tree-img {
            width: 37%;
            margin: auto;
        }

        .tree-para {
            padding: 0px 20px 0px 76px;

            p {
                font-size: 14px !important;
                line-height: 1.1rem !important;
            }
        }

        .foot {
            width: 20%;
            margin: -53px -7px !important;
        }

        .four-tv {
            // height: 200px !important;
            // margin: 0px 27px 20px 80px;
            // padding: 13px 13px 35px 13px;
            height: 241px !important;
            margin: 0px 3px 20px 65px;
            padding: 13px 13px 35px 13px;

            img {
                height: 190px !important;
                border-radius: 9px;
            }

            iframe {
                height: 190px !important;
                border-radius: 9px;
            }
        }
    }

    .out-five {
        background-image: url("../../assets/OutDoor/fifth/fifth-mg.jpg");
        background-repeat: no-repeat;
        height: 735px;

        .fifth-tree {
            display: block;
            padding: 0px;

            .ff {
                width: 115%;
                margin-left: -52px;

                img {
                    height: 102px;
                }
            }
        }

        .goal1 {
            width: 90%;
            margin: -37px -5px !important;
        }

        .goal2 {
            width: 217px;
        }

        .goal3 {
            // width: 72%;
            // margin: -44px 0px 0px 24px !important;
            width: 67%;
            margin: -37px 0px 0px 24px !important;
        }

        .goa {
            margin: -59px 10px 0 31px;
        }

        .tree-para {
            // padding: 10px 149px 10px 18px;
            padding: 10px 45px 0px 0px;
        }

        .five-tv {
            // height: 200px;
            // padding: 13px 10px 35px 9px;
            // margin: 0px 15px 0px 101px;
            // margin: 0px 32px 0px 78px;
            // height: 241px;
            // padding: 13px 10px 35px 9px;
            // margin: 0px 0px 0px 94px;
            height: 241px;
            padding: 13px 10px 35px 9px;
            margin: 0px 0px 0px 58px;

            img {

                height: 205px !important;
                border-radius: 9px;
            }

            iframe {

                height: 205px !important;
                border-radius: 9px;
            }
        }
    }
}

@media screen and (max-width: 375px) {

    // .out-one {.out1 {
    //     height: 135px;
    //   .sli-tree {
    //         height: 66px;
    //     }
    // .sli-img .sli .slick-slider img {
    //         height: 95px;
    //         border-radius: 7px;
    //     }
    // }}
    .out-one .out1 .beach {
        position: absolute;
        top: -16%;
        right: 5%;
        width: 32%;
    }

    .out-two {
        height: 622px;

        .girl-pa {
            padding: 10px 37px 10px 63px !important;
        }

        .second-sli {

            height: 230px;

            .slick-slider {
                padding: 33px 26px !important;
            }

            img {
                height: 161px;
                border-radius: 8px;
            }
        }
    }

    .out-three {

        .second-sli2 {

            margin: 0px 66px 10px 47px;
            height: 230px;

            .slick-slider {
                padding: 34px 25px 0px 25px !important;
            }

            img {
                height: 161px;
                border-radius: 5px;
            }
        }

        .foot {
            width: 20%;
            margin: -49px -10px !important;
        }
    }

    .out-four {
        .tree-para {
            // padding: 0px 20px 0px 93px;
            padding: 0px 20px 0px 69px;

            p {
                font-size: 14px !important;
                line-height: 1.1rem !important;
            }
        }

        .four-tv {
            height: 177px !important;
            margin: 0px 27px 20px 55px;
            padding: 9px 9px 28px 9px;

            img {
                height: 145px !important;
                border-radius: 10px;
            }

            iframe {
                height: 145px !important;
                border-radius: 10px;
            }
        }

    }

    .out-five {
        height: 655px;

        .fifth-tree .ff {
            width: 117%;
            margin-left: -52px;
        }

        .tree-para {
            // padding: 17px 102px 10px 18px;
            padding: 17px 41px 10px 0px;
        }

        .five-tv {
            height: 177px;
            padding: 10px 8px 35px 8px;
            margin: -15px 29px 0px 66px;

            img {
                height: 148px !important;
            }

            iframe {
                height: 148px !important;
            }
        }

        .goa {
            margin: -47px 10px 0 28px;
        }
    }
}

@media screen and (max-width: 320px) {

    // .out-one {.out1 {
    //     height: 116px;
    //     .sli-tree {
    //         height: 46px;
    //     }
    //    .sli-img .sli .slick-slider img {
    //         height: 80px;
    //         border-radius: 7px;
    //     }
    // }}
    .out-two {

        height: 544px;

        .girl-pa {
            padding: 10px 37px 10px 54px !important;
        }

        .foot {
            margin: -40px -15px !important;
        }

        .second-sli {
            height: 200px;
            margin: 0px 25px 10px 56px;

            .slick-slider {
                padding: 29px 20px !important;
            }

            img {
                height: 134px;
                border-radius: 8px;
            }
        }
    }

    .out-three {
        .second-sli2 {
            // margin: 0px 34px 10px 61px;
            margin: 0px 50px 10px 37px;
            height: 200px;

            .slick-slider {
                padding: 29px 19px 0px 19px !important;
            }

            img {
                height: 142px;
            }
        }

        .foot {

            margin: -44px 3px;
        }
    }

    .out-four {
        .tree-para {
            padding: 0px 20px 0px 60px;
        }

        .foot {

            margin: -37px -6px !important;
        }
    }

    .out-five {
        height: 571px;

        .fifth-tree .ff {
            width: 119%;
            margin-left: -52px;
        }

        .goal3 {
            width: 72%;
            margin: -39px 0px 0px 24px !important;
        }

        .goa {
            margin: -34px 10px 0 18px;

            .tree-para {
                padding: 18px 43px 10px 10px;
            }

            p {
                font-size: 12px !important;
                line-height: 1.1rem !important;
                letter-spacing: 0.35px;
            }

            .goal2 {
                width: 168px;
            }
        }
    }
}
@media only screen and (max-width: 1920px) {
    .out-two {.foot {
        margin: -114px 18px;
    }}

    .out-three{ .foot {
        width: 15%;
        margin: -125px 27px;
    }}

    .out-four {.foot {
        width: 18%;
        margin: -120px 41px;
    }}

    .out-five {.goal1 {
        width: 60%;
        margin: -90px 38px;
    }}
}
@media only screen and (min-width: 1400px) {
    .container {
        // max-width:1320px !important;
        width: 1320px !important;
    }

    // .out-two .foot {
    //     width: 15%;
    //     margin: -105px -116px;
    // }

    .out-one .out1 {

        height: 530px;

        width: 100%;
    }

    .out-one .out1 .sli-img .sli .slick-slider img {
        border-radius: 20px;
        height: 380px;
    }

    // .out-three .foot {
    //     width: 15%;
    //     margin: -105px -85px;
    // }

    // .out-four .foot {
    //     width: 18%;
    //     margin: -98px -69px;
    // }

    // .out-five .goal1 {
    //     width: 60%;
    //     margin: -99px -81px;
    // }
}

@media only screen and (min-width: 900px) and (max-width:1440px) {
    .out-two .foot {
        width: 15%;
        margin: -108px 51px;
    }

    .out-three .foot {
        width: 15%;
        margin: -106px 57px;
    }

    .out-four .foot {
        width: 18%;
        margin: -98px 65px;
    }

    .out-five .goal1 {
        width: 60%;
        margin: -99px 58px;
    }

    .out-one .out1 {

        height: 486px;

        width: 83%;
    }

    .out-one .out1 .sli-img .sli .slick-slider img {
        height: 347px;
        border-radius: 20px;
    }
}

// @media only screen and (min-width: 2560px) {
//     .out-two .foot {
//         width: 20%;
//         margin: -158px -376px;
//     }

//     .out-three .foot {
//         width: 20%;
//         margin: -182px -342px;
//     }

//     .out-four .foot {
//         width: 23%;
//         margin: -176px -308px;
//     }

//     .out-five .goal1 {
//         width: 81%;
//         margin: -128px -327px;
//     }
// }



// @media (-webkit-min-device-pixel-ratio: 1.25) {
//     .out-two {
//         .foot {
//             // width:18%;
//             margin: -119px -173px
//         }
//     }
// }