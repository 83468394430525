.me-one {
    background-image: url("../../assets/MapMe/section1/mapFirst-bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: auto;
    // padding-bottom: 260px !important;

    .arrow-red {
        color: red;
    }

    .map-train {
        position: relative;
        padding-bottom: 32%;

        .beach {
            position: absolute;
            top: 2%;
            right: 6%;
            width: 21%;
            z-index: 9;
        }

        .lr-arrow {
            width: 25px !important;
            height: 30px;
        }

        .sli-img {

            // position: relative;
            .sli {
                width: 90%;
                margin: auto;
                padding-top: 146px !important;


                .prev-icon-container {
                    left: -5.1rem !important;
                }

                .slick-prev,
                .slick-next {
                    width: 9.75rem !important;
                    height: 12.75rem !important;
                    right: -5.5rem !important;
                }
            }
        }

        .train {
            // position: absolute;
            // top: 0%;
            // left:0%;   
            // z-index: 9;   
            position: absolute;
            top: 0%;
            left: 9%;
            z-index: 9;
            width: 85%;

        }

    }
}

.me-two {

    .me-red {
        background-image: url("../../assets/MapMe/section2/red.jpg");
        background-repeat: no-repeat;
        background-size: 101% 100%;
        height: auto;

        .me-tv1 {
            background-image: url("../../assets/MapMe/section2/mumbai.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 350px;
            margin-left: 10%;
            padding: 17px 44px 77px 22px;

            iframe {
                width: 100%;
                height: 254px;
                border-radius: 20px;

            }
        }
    }

    .me-blue {
        background-image: url("../../assets/MapMe/section2/blue.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: auto;

        .me-tv1 {
            background-image: url("../../assets/MapMe/section2/benga.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 350px;
            margin-right: 10%;
            padding: 17px 58px 77px 22px;

            iframe {
                width: 100%;
                height: 260px;
                border-radius: 20px;

            }
        }
    }

    .me-purple {
        background-image: url("../../assets/MapMe/section2/purple.jpg");
        background-repeat: no-repeat;
        background-size: 101% 100%;
        height: auto;

        .me-tv1 {
            background-image: url("../../assets/MapMe/section2/delhi.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 350px;
            margin-left: 10%;
            padding: 17px 44px 77px 22px;

            iframe {
                width: 100%;
                height: 260px;
                border-radius: 20px;

            }
        }
    }

    .me-yellow {
        background-image: url("../../assets/MapMe/section2/yellow.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: auto;

        .me-tv1 {
            background-image: url("../../assets/MapMe/section2/kol.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 350px;
            margin-right: 10%;
            padding: 17px 58px 77px 22px;

            iframe {
                width: 100%;
                height: 260px;
                border-radius: 20px;

            }
        }
    }
}

.me-three {
    background-image: url("../../assets/MapMe/section3/city.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: auto;

    // p{
    //     font-size: 20px !important;
    //     font-weight: 600;
    // }
    .three-img {
        width: 80%;
        margin: auto;
    }

    .map-tab {
        width: 53%;
        margin: auto;
        padding-bottom: 40px;

        .tab-style {
            background-color: #f50632;
            padding: 20px 0;
            width: 200px;
            border-radius: 12px;

            p {
                color: #fff;
                font-size: 18px !important;
                font-weight: 500;
            }
        }
    }

    .text-2 {
        background-color: rgba(0, 0, 0, 0.555);
        width: 30%;
        margin: auto;
        padding: 15px;

        p {
            font-weight: 500;
        }
    }

    .three-logo {
        width: 30%;
        margin: auto;
    }

}

@media only screen and (max-width: 1024px) {
    .me-one {
        height: 640px;

        .map-train {
            padding-bottom: 0%;

            .train {
                position: absolute;
                top: 0%;
                left: 8%;
                z-index: 9;
                width: 84%;
            }

            .sli-img {
                .sli {
                    width: 96%;
                    margin: auto;
                    padding-top: 111px !important;
                }
            }
        }
    }

    .me-two {

        .me-red {
            .me-tv1 {

                padding: 17px 32px 77px 19px;
            }
        }

        .me-blue {
            .me-tv1 {
                padding: 17px 43px 94px 19px;
            }
        }

        .me-purple {
            .me-tv1 {
                padding: 17px 33px 77px 17px;
            }
        }

        .me-yellow {
            .me-tv1 {
                padding: 17px 43px 94px 19px;
            }
        }

    }

    .me-three {
        .map-tab {

            .tab-style {

                width: 157px;

            }
        }
    }
}

@media only screen and (max-width: 834px) {
    .me-one {
        height: 572px;

        .map-train {
            .train {
                left: 3%;
                width: 95%;
            }

            .sli-img .sli {
                width: 96%;

                padding-top: 101px !important;

                .slick-slider {
                    padding: 0 67px !important;
                }
            }

        }
    }

    .me-two {
        .me-red {
            .me-tv1 {
                padding: 17px 21px 77px 13px;
            }
        }

        .me-blue {
            .me-tv1 {
                padding: 17px 30px 94px 12px;
            }
        }

        .me-purple {
            .me-tv1 {
                padding: 17px 21px 77px 13px;
            }
        }

        .me-yellow {
            .me-tv1 {
                padding: 17px 30px 94px 12px;
            }
        }
    }

    .me-three {
        .map-tab {
            .tab-style {

                width: 118px;

                p {
                    font-size: 14px !important;
                }

            }
        }
    }
}

@media only screen and (max-width: 425px) {

    .me-one {
        background-image: url("../../assets/MapMe/section1/mobile-mapFirst-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        // height: auto;
        height: 405px;

        .map-train {
            .train {
                left: 0%;
                width: 100%;

            }

            .sli-img {
                .sli {
                    width: 96%;
                    padding-top: 62px !important;

                    .slick-prev,
                    .slick-next {
                        width: 9.75rem !important;
                        height: 4.75rem !important;
                    }

                    .prev-icon-container {
                        left: -5.5rem !important;
                    }

                    .slick-slider {
                        padding: 0 33px !important;
                    }
                }
            }

            .lr-arrow {
                width: 20px !important;
                height: 30px;
            }
        }
    }

    .me-two {
        .me-red {
            .me-tv1 {
                margin: 0% 10%;
                background-size: contain;
                height: 235px;
                padding: 12px 24px 0px 13px;

                iframe {
                    width: 100%;
                    height: 174px;
                    border-radius: 20px;
                }
            }
        }

        .me-yellow {
            .me-tv1 {
                margin: 0% 10%;
                background-size: contain;
                height: 235px;
                padding: 12px 34px 0px 13px;

                iframe {
                    width: 100%;
                    height: 169px;
                    border-radius: 20px;
                }
            }
        }

        .me-purple {
            .me-tv1 {
                margin: 0% 10%;
                background-size: contain;
                height: 235px;
                padding: 12px 24px 0px 13px;

                iframe {
                    width: 100%;
                    height: 174px;
                    border-radius: 20px;
                }
            }
        }

        .me-blue {
            .me-tv1 {
                margin: 0% 10%;
                background-size: contain;
                height: 235px;
                padding: 12px 34px 0px 13px;

                iframe {
                    width: 100%;
                    height: 169px;
                    border-radius: 20px;
                }
            }
        }
    }

    .me-three {
        .three-img {
            width: 98%;
        }

        .map-tab {

            padding-bottom: 22px;

            .tab-style {
                width: 91px;
                padding: 9px 0;

                p {
                    font-size: 11px !important;
                }
            }
        }

        .text-2 {

            width: 53%;

            p {
                font-size: 12px !important;
            }
        }

        .three-logo {
            width: 58%;
        }
    }
}

@media only screen and (max-width: 390px) {

    .me-three {
        .map-tab {
            width: 60%;
        }
    }

    .me-two {
        .me-red {
            .me-tv1 {
                iframe {
                    width: 100%;
                    height: 155px;
                    border-radius: 13px;
                }
            }
        }

        .me-purple {
            .me-tv1 {
                iframe {
                    width: 100%;
                    height: 155px;
                    border-radius: 12px;
                }
            }
        }

        .me-blue {
            .me-tv1 {

                height: 235px;
                padding: 10px 32px 0px 10px;

                iframe {
                    height: 153px;
                    border-radius: 17px;
                }
            }
        }

        .me-yellow {
            .me-tv1 {
                margin: 0% 10%;
                background-size: contain;
                height: 235px;
                padding: 12px 33px 0px 13px;

                iframe {
                    width: 100%;
                    height: 150px;
                    border-radius: 13px;
                }
            }
        }

    }


}

@media only screen and (max-width: 375px) {
    .me-one {

        height: 357px;

        .map-train {
            .sli-img {
                .sli {
                    width: 96%;
                    padding-top: 59px !important;
                }
            }
        }
    }

    .me-two {
        .me-red {
            .me-tv1 {

                padding: 10px 24px 0px 13px;

                iframe {
                    height: 151px;

                }
            }
        }

        .me-blue {
            .me-tv1 {
                height: 235px;
                padding: 10px 29px 0px 12px;

                iframe {
                    height: 147px;
                    border-radius: 17px;
                }
            }
        }

        .me-yellow {
            .me-tv1 {
                margin: 0% 10%;
                background-size: contain;
                height: 235px;
                padding: 10px 28px 0px 13px;

                iframe {
                    width: 100%;
                    height: 146px;
                    border-radius: 13px;
                }
            }
        }
    }

    .me-three {

        .map-tab {
            width: 66%;
            margin: auto;
            padding-bottom: 19px;

            .tab-style {
                width: 99px;
                padding: 9px 0;
            }
        }

        .text-2 {
            width: 58%;
        }
    }
}

@media only screen and (max-width: 320px) {
    .me-one {
        height: 304px;

        .map-train {
            .sli-img {
                .sli {
                    width: 99%;
                    padding-top: 51px !important;
                }
            }
        }
    }

    .me-two {
        .me-red {
            .me-tv1 {
                padding: 10px 20px 0px 11px;
                height: 178px;
                iframe {
                    height: 126px;
                }
            }
        }

        .me-blue {
            .me-tv1 {
                height: 178px;
                padding: 9px 26px 0px 10px;

                iframe {
                    height: 124px;
                    border-radius: 12px;
                }
            }
        }

        .me-purple {
            .me-tv1 {
                height: 178px;
                padding: 9px 21px 0px 10px;

                iframe {

                    height: 128px;
                    border-radius: 12px;
                }
            }
        }

        .me-yellow {
            .me-tv1 {
                height: 178px;
                padding: 9px 26px 0px 11px;

                iframe {
                    width: 100%;
                    height: 125px;
                    border-radius: 13px;
                }
            }
        }
    }
    .me-three {.map-tab {.tab-style {
        width: 72px;
        padding: 9px 0;
    }}}
    .me-three .text-2 p {
        font-size: 11px !important;
    }
}