.home-one {
  background-image: url("../../assets/Homepage/home1.jpg");

  background-size: cover;

  .home1 {
    position: relative;

    .sli-img {

      // position: relative;
      .sli {
        //     width: 92%;
        // margin: auto;
        // padding-top: 150px !important;
        // width: 94%;
        width: 83%;
        margin: auto;
        padding-top: 75px !important;

        // .prev-icon-container {
        //   left: -0.1rem !important;
        // }
        //////new
        .prev-icon-container {
          left: 1.4rem !important;
        }

        .next-icon-container {
          right: 0rem !important;
        }

        .slick-slider {
          padding: 0 90px !important;
        }

        //////new
        .slick-prev,
        .slick-next {
          // width: 9.75rem !important;
          width: 6.95rem !important;
          height: 6.75rem !important;
        }
      }
    }

    .bamboo {
      // position: absolute;
      // top: 0%;
      // left:0%;   
      // z-index: 9;     
      // position: absolute;
      // top: 0%;
      // left: 20%;
      // z-index: 9;
      // width: 58%;
      position: absolute;
      top: 0%;
      left: 12%;
      z-index: 9;
      width: 78%;

    }


  }

  .school-img {
    width: 40%;
    margin: auto;
  }

  .school-para {
    width: 728px;
    margin: auto;
    text-align: center;

    p {
      font-size: 17px !important;
      line-height: 1.75rem !important;
      font-weight: 700;
    }
  }

  .know-btn {
    width: 13%;
    margin: auto;
  }
}

.home2 {
  background: url('../../assets/Homepage/pinky.jpg') scroll 0 0;
  background-size: 100% 100%;
  position: relative;

  .gts-logo {
    position: absolute;
    top: -123px;
    width: 100% !important;
    left: 80%;

    .gts {
      width: 19%;
    }
  }

  .img1 {
    width: 40%;
    margin: auto;
  }

  .img2 {
    width: 51%;
    margin: auto;
  }

  .play-none {
    img {
      pointer-events: none;
    }
  }

  .play-lo {
    width: 68%;

    margin: auto;
    margin-bottom: -134px;
  }

  .play {
    background-image: url('../../assets/Homepage/play-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 666px;
    background-position: top;
    padding-top: 142px;


    .tv1 {
      background: url("../../assets/Homepage/playtv1.png") no-repeat;
      // min-height: 185px;
      // width: 270px;
      // margin: auto;
      // padding: 9px 11px 36px 10px;
      // // padding: 9px 156px 76px 10px;
      min-height: 185px;
      width: 69%;
      background-size: 100% 100%;
      margin: auto;
      padding: 10px 11px 19px 10px;

      img {
        width: 100%;
        height: 151px;
        border-radius: 10px;
      }

      iframe {
        width: 100%;
        height: 152px;
        border-radius: 10px;
      }
    }

    .play-text {
      // position: absolute;
      // bottom: 12%;
      // left: 22%;
      // width: 210px;
      // transform: translateX(-50%);
      width: 210px;
      margin: auto;

      p {
        span {
          color: #fedc00;
          font-size: 600;
        }

        font-weight: 500;
        font-size: 16px !important;
        line-height: 1.3rem !important;
        letter-spacing: 0.8px;

      }
    }

    .play-arrow {
      // bottom: 10%;
      // position: absolute;
      // left: 43%;
      // width: 8%;
      width: 9%;
      margin: auto;
    }
  }

  .out-lo {
    width: 68%;
    margin: auto;
    margin-bottom: -134px;
  }

  .outdoor {
    background: url('../../assets/Homepage/out-bg.png') scroll 0 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 666px;
    background-position: top;
    padding-top: 142px;

    .tv2 {
      background: url("../../assets/Homepage/outtv.png") no-repeat;
      min-height: 185px;
      width: 69%;
      background-size: 100% 100%;
      margin: auto;
      padding: 10px 11px 19px 10px;

      img {
        width: 100%;
        height: 151px;
        border-radius: 10px;
      }

      iframe {
        width: 100%;
        height: 152px;
        border-radius: 10px;
      }
    }

    .out-text {
      width: 235px;
      margin: auto;

      p {
        span {
          color: #17c7ba;
          font-size: 600;
        }

        font-weight: 500;
        font-size: 16px !important;
        line-height: 1.3rem !important;
        letter-spacing: 0.8px;

      }
    }

    .out-arrow {
      width: 9%;
      margin: auto;
    }

  }

  .plan-lo {
    width: 68%;

    margin: auto;
    margin-bottom: -134px;
  }

  .plan {
    background: url('../../assets/Homepage/get-bg.png') scroll 0 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 666px;
    background-position: top;
    padding-top: 142px;

    .tv3 {
      background: url("../../assets/Homepage/plantv.png") no-repeat;
      min-height: 185px;
      width: 69%;
      background-size: 100% 100%;
      margin: auto;
      padding: 10px 11px 19px 10px;

      img {
        width: 100%;
        height: 151px;
        border-radius: 10px;
      }

      iframe {
        width: 100%;
        height: 152px;
        border-radius: 10px;
      }
    }

    .plan-text {
      width: 236px;
      margin: auto;

      p {
        span {
          color: #60f338;
          font-size: 600;
        }

        font-weight: 500;
        font-size: 16px !important;
        line-height: 1.3rem !important;
        letter-spacing: 0.8px;

      }
    }

    .plan-arrow {
      width: 9%;
      margin: auto;
    }

    .player-wrapper-small {
      padding-top: 57.25%;
    }
  }

}

.goal-logo {
  width: 64%;
  margin: auto;
}

.tv-img {
  height: 121px !important;
  border-radius: 10px;
}

.goal-slider {
  background-image: url('../../assets/Homepage/project/goalbg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  height: 515px;

  background-position: top;
  padding-top: 95px;
  padding-bottom: 49px;
  margin-top: -76px;

  .goal-tv {
    background: url("../../assets/Homepage/project/goaltv.png") no-repeat;
    min-height: 130px;
    width: 69%;
    background-size: 100% 100%;
    margin: auto;
    // padding: 6px 6px 9px 5px;
    padding: 6px 6px 14px 5px;
    //   .player-wrapper-small {
    //     position: relative;
    //     padding-top: 65.25%;
    //   }
    //   iframe{ 
    //     width: 100%;
    //     height: 109px;
    //     border-radius: 10px;
    // }
  }

  .goal-text {
    width: 176px;
    margin: auto;

    p {
      span {
        color: #a976fe;
        font-size: 600;
      }

      font-weight: 500;
      font-size: 14px !important;
      line-height: 1.3rem !important;
      letter-spacing: 0.8px;

    }
  }


}

.goal-arrow {
  width: 9%;
  margin: auto;
}

.mom-slider {
  background-image: url('../../assets/Homepage/project/mombg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  height: 515px;

  background-position: top;
  padding-top: 95px;
  padding-bottom: 49px;
  margin-top: -76px;

  .mom-tv {
    background: url("../../assets/Homepage/project/momtv.png") no-repeat;
    min-height: 130px;
    width: 69%;
    background-size: 100% 100%;
    margin: auto;
    // padding: 6px 6px 9px 5px;
    padding: 6px 6px 14px 5px;

  }

  .mom-text {
    width: 176px;
    margin: auto;

    p {
      span {
        color: #f28092;
        font-size: 600;
      }

      font-weight: 500;
      font-size: 14px !important;
      line-height: 1.3rem !important;
      letter-spacing: 0.8px;

    }
  }

}

.yes-slider {
  background-image: url('../../assets/Homepage/project/yesbg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  height: 515px;

  background-position: top;
  padding-top: 95px;
  padding-bottom: 49px;
  margin-top: -76px;

  .yes-tv {
    background: url("../../assets/Homepage/project/yestv.png") no-repeat;
    min-height: 130px;
    width: 69%;
    background-size: 100% 100%;
    margin: auto;
    // padding: 6px 6px 9px 5px;
    padding: 6px 6px 14px 5px;

  }

  .yes-text {
    width: 176px;
    // width: 188px;
    margin: auto;

    p {
      span {
        color: #feb400;
        font-size: 600;
      }

      font-weight: 500;
      font-size: 14px !important;
      line-height: 1.3rem !important;
      letter-spacing: 0.8px;

    }
  }

}

.school-slider {
  background-image: url('../../assets/Homepage/project/schoolbg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  height: 515px;

  background-position: top;
  padding-top: 95px;
  padding-bottom: 49px;
  margin-top: -76px;

  .school-tv {
    background: url("../../assets/Homepage/project/schooltv.png") no-repeat;
    min-height: 130px;
    width: 69%;
    background-size: 100% 100%;
    margin: auto;
    // padding: 6px 6px 9px 5px;
    padding: 6px 6px 14px 5px;

  }

  .school-text {
    width: 176px;
    margin: auto;

    p {
      span {
        color: #aac63c;
        font-size: 600;
      }

      font-weight: 500;
      font-size: 14px !important;
      line-height: 1.3rem !important;
      letter-spacing: 0.8px;

    }
  }

}

.scrappy-slider {
  background-image: url('../../assets/Homepage/project/scrappybg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  height: 515px;

  background-position: top;
  padding-top: 95px;
  padding-bottom: 49px;
  margin-top: -76px;

  .scrappy-tv {
    background: url("../../assets/Homepage/project/scrappytv.png") no-repeat;
    min-height: 130px;
    width: 69%;
    background-size: 100% 100%;
    margin: auto;
    // padding: 6px 6px 9px 5px;
    padding: 6px 6px 14px 5px;

  }

  .scrappy-text {
    width: 176px;
    //width: 189px;
    margin: auto;

    p {
      span {
        color: #6edafe;
        font-size: 600;
      }

      font-weight: 500;
      font-size: 14px !important;
      line-height: 1.3rem !important;
      letter-spacing: 0.8px;

    }
  }

}

.girl-slider {
  background-image: url('../../assets/Homepage/project/girlbg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  height: 515px;

  background-position: top;
  padding-top: 95px;
  padding-bottom: 49px;
  margin-top: -76px;

  .girl-tv {
    background: url("../../assets/Homepage/project/girltv.png") no-repeat;
    min-height: 130px;
    width: 69%;
    background-size: 100% 100%;
    margin: auto;
    // padding: 6px 6px 9px 5px;
    padding: 6px 6px 14px 5px;

  }

  .girl-text {
    width: 176px;
    margin: auto;

    p {
      span {
        color: #feb15e;
        font-size: 600;
      }

      font-weight: 500;
      font-size: 14px !important;
      line-height: 1.3rem !important;
      letter-spacing: 0.8px;

    }
  }

}

.luna-slider {
  background-image: url('../../assets/Homepage/project/lunabg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  height: 515px;

  background-position: top;
  padding-top: 95px;
  padding-bottom: 49px;
  margin-top: -76px;

  .luna-tv {
    background: url("../../assets/Homepage/project/lunatv.png") no-repeat;
    min-height: 130px;
    width: 69%;
    background-size: 100% 100%;
    margin: auto;
    // padding: 6px 6px 9px 5px;
    padding: 6px 6px 14px 5px;

  }

  .luna-text {
    width: 176px;
    margin: auto;

    p {
      span {
        color: #af37ff;
        font-size: 600;
      }

      font-weight: 500;
      font-size: 14px !important;
      line-height: 1.3rem !important;
      letter-spacing: 0.8px;

    }
  }

}

.be-slider {
  background-image: url('../../assets/Homepage/project/bebg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  height: 515px;

  background-position: top;
  padding-top: 95px;
  padding-bottom: 49px;
  margin-top: -76px;

  .be-tv {
    background: url("../../assets/Homepage/project/betv.png") no-repeat;
    min-height: 130px;
    width: 69%;
    background-size: 100% 100%;
    margin: auto;
    // padding: 6px 6px 9px 5px;
    padding: 6px 6px 14px 5px;

  }

  .be-text {
    width: 176px;
    margin: auto;

    p {
      span {
        color: #379f0e;
        font-size: 600;
      }

      font-weight: 500;
      font-size: 14px !important;
      line-height: 1.3rem !important;
      letter-spacing: 0.8px;

    }
  }

}

.code-slider {
  background-image: url('../../assets/Homepage/project/codebg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  height: 515px;

  background-position: top;
  padding-top: 95px;
  padding-bottom: 49px;
  margin-top: -76px;

  .code-tv {
    background: url("../../assets/Homepage/project/codetv.png") no-repeat;
    min-height: 130px;
    width: 69%;
    background-size: 100% 100%;
    margin: auto;
    // padding: 6px 6px 9px 5px;
    padding: 6px 6px 14px 5px;

  }

  .code-text {
    width: 176px;
    margin: auto;

    p {
      span {
        color: #48b0ff;
        font-size: 600;
      }

      font-weight: 500;
      font-size: 14px !important;
      line-height: 1.3rem !important;
      letter-spacing: 0.8px;

    }
  }

}

.pitch-slider {
  background-image: url('../../assets/Homepage/project/pitchbg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  height: 515px;

  background-position: top;
  padding-top: 95px;
  padding-bottom: 49px;
  margin-top: -76px;

  .pitch-tv {
    background: url("../../assets/Homepage/project/pitchtv.png") no-repeat;
    min-height: 130px;
    width: 69%;
    background-size: 100% 100%;
    margin: auto;
    // padding: 6px 6px 9px 5px;
    padding: 6px 6px 14px 5px;

  }

  .pitch-text {
    width: 176px;
    margin: auto;

    p {
      span {
        color: #feb000;
        font-size: 600;
      }

      font-weight: 500;
      font-size: 14px !important;
      line-height: 1.3rem !important;
      letter-spacing: 0.8px;

    }
  }

}

.going-slider {
  background-image: url('../../assets/Homepage/project/goingbg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  height: 515px;

  background-position: top;
  padding-top: 95px;
  padding-bottom: 49px;
  margin-top: -76px;

  .going-tv {
    background: url("../../assets/Homepage/project/goingtv.png") no-repeat;
    min-height: 130px;
    width: 69%;
    background-size: 100% 100%;
    margin: auto;
    // padding: 6px 6px 9px 5px;
    padding: 6px 6px 14px 5px;

  }

  .going-text {
    width: 176px;
    margin: auto;

    p {
      span {
        color: #ff94a4;
        font-size: 600;
      }

      font-weight: 500;
      font-size: 14px !important;
      line-height: 1.3rem !important;
      letter-spacing: 0.8px;

    }
  }

}

.close-model {
  width: 6%;
  margin: 0 10px 0 0;
  // padding: 0;

}

.play-mini {
  background-image: url("../../assets/Homepage/Modal/play-mini.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 25px;

  .modelImg1 {
    width: 85%;
    margin: auto;
  }

  .modeldate {
    width: 40%;
    margin: auto;
  }

  .modellogo {
    width: 40%;
    margin: auto;
  }

  .modelbtn {
    width: 30%;
    margin: auto;
  }

  .model-text {
    background: #00000047;
    padding: 10px;
    border-radius: 5px;

    p {
      color: #fff;

      span {
        color: #e40086;
        font-size: 20px;
        font-weight: 500;
      }
    }


  }
}

.out-mini {
  background-image: url("../../assets/Homepage/Modal/out-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 25px;

  .modelImg1 {
    width: 56%;
    margin: auto;
  }

  .modeldate {
    width: 40%;
    margin: auto;
  }

  .modellogo {
    width: 99%;
    margin: auto;
  }

  .modelbtn {
    width: 30%;
    margin: auto;
  }

  .model-text {
    p {
      span {
        color: #00b3a7;
        font-size: 20px;
        font-weight: 600;
      }

      font-weight: 500;
    }
  }
}

.get-mini {
  background-image: url("../../assets/Homepage/Modal/get-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 25px;

  .modelImg1 {
    width: 85%;
    margin: auto;
  }

  .modeldate {
    width: 40%;
    margin: auto;
  }

  .modellogo {
    width: 40%;
    margin: auto;
  }

  .modelbtn {
    width: 30%;
    margin: auto;
  }

  .model-text {
    p {
      span {
        color: #ed1b74;
        font-size: 20px;
        font-weight: 600;
      }

      font-weight: 500;
    }

  }
}

.goal-mini {
  background-image: url("../../assets/Homepage/Modal/goal-bg.jpg");
  background-size: 100% 100%;
  height: auto;
  background-repeat: no-repeat;
  border-radius: 25px;

  .model-banner {
    width: 111%;
    margin-left: -30px;

  }

  .model-para3 {
    margin-top: 0px !important;
  }

  .modelImg1 {
    margin-top: 20px !important;
    width: 90%;
    margin: auto;
  }

  .modellogo {
    margin-top: 20px !important;
    width: 40%;
    margin: auto;
  }

  .modelbtn {
    margin-top: 10px !important;

    width: 30%;
    margin: auto;
  }

  .model-text {
    background-color: #3d1392a8;
    padding: 10px;
    border-radius: 15px;

    p {
      color: #fff;
      margin-bottom: 0px !important;
    }

    span {
      color: #03fce8;
      font-size: 20px;
      font-weight: 500;
    }

  }

  .bg-tabs {
    background-color: #e40086;
    color: #fff;

    font-weight: 400;
    border-radius: 5px;
    padding: 3px 5px;

    p {
      font-size: 14px !important;
    }
  }
}

.map-mini {
  background-image: url("../../assets/Homepage/Modal/map-bg.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 25px;

  .modelImg1 {
    margin-top: 20px !important;
    width: 100%;
    margin: auto;
  }

  .bg-tabs {
    background-color: red;


    font-weight: 400;
    border-radius: 5px;
    padding: 3px 5px;

    p {
      font-size: 14px !important;
    }
  }

  .tv-1 {
    position: relative;

    .mum {
      width: 89%;
      position: absolute;
      top: 9px;
      left: 10px;

      img {
        width: 100% !important;
        height: 132px;
        border-radius: 10px;
      }

      .player-wrapper-small {
        position: relative;
        padding-top: 60.25%;
      }

      iframe {
        width: 100% !important;
        height: 132px;
        border-radius: 10px;
      }
    }
  }

  .tv-2 {
    position: relative;

    .mum {
      width: 87%;
      position: absolute;
      top: 9px;
      left: 10px;

      img {
        width: 100% !important;
        height: 127px;
        border-radius: 10px;
      }

      .player-wrapper-small {
        position: relative;
        padding-top: 60.25%;
      }

      iframe {
        width: 100% !important;
        height: 127px;
        border-radius: 10px;
      }
    }
  }

  .tv-3 {
    position: relative;

    .mum {
      width: 89%;
      position: absolute;
      top: 9px;
      left: 10px;

      img {
        width: 100% !important;
        height: 132px;
        border-radius: 10px;
      }

      .player-wrapper-small {
        position: relative;
        padding-top: 60.25%;
      }

      iframe {
        width: 100% !important;
        height: 132px;
        border-radius: 10px;
      }
    }
  }

  .tv-4 {
    position: relative;

    .mum {
      width: 87%;
      position: absolute;
      top: 9px;
      left: 10px;

      img {
        width: 100% !important;
        height: 127px;
        border-radius: 10px;
      }

      .player-wrapper-small {
        position: relative;
        padding-top: 60.25%;
      }

      iframe {
        width: 100% !important;
        height: 127px;
        border-radius: 10px;
      }
    }
  }

  .modellogo {
    margin-top: 20px !important;
    width: 40%;
    margin: auto;
  }

  .modelbtn {
    margin-top: 10px !important;

    width: 30%;
    margin: auto;
  }

  .model-text {
    margin-top: 20px;

    span {
      color: red;
      font-size: 20px;
      font-weight: 500;
    }

  }
}

.yes-mini {
  background-image: url("../../assets/Homepage/Modal/yess-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 25px;

  .modelImg1 {
    margin-top: 20px !important;
    width: 100%;
    margin: auto;
  }

  .modellogo {
    margin-top: 20px !important;
    width: 22%;
    margin: auto;
  }

  .modelbtn {
    margin-top: 20px !important;
    width: 30%;
    margin: auto;
    margin-bottom: 120px;
  }

  .model-text {


    span {
      color: red;
      font-size: 20px;
      font-weight: 500;
    }

  }
}

.school-mini {
  background-image: url("../../assets/Homepage/Modal/school-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 25px;

  .model-banner {
    margin-top: 145px;
  }

  .model-upper {
    position: relative;
  }

  .model-tree {
    width: 26%;
    position: absolute;
    bottom: -31px;
    right: -26px;
    transform: rotate(359deg);
  }

  .modelImg1 {
    margin-top: 20px !important;
    width: 100%;
    margin: auto;
  }

  .modellogo {
    margin-top: 20px !important;
    width: 22%;
    margin: auto;
  }

  .modelbtn {
    margin-top: 20px !important;
    width: 30%;
    margin: auto;
    margin-bottom: 23px;
  }

  .model-text {
    margin-top: 20px;

    p {
      text-align: left !important;
      font-weight: 400;
    }

    span {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
    }

  }
}

.scrappy-mini {
  background-image: url("../../assets/Homepage/Modal/scrappy-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 25px;

  .modelImg1 {
    margin-top: 20px !important;
    width: 100%;
    margin: auto;
  }

  .modellogo {
    margin-top: 10px !important;
    width: 34%;
    margin: auto;
  }

  .modelbtn {
    margin-top: 110px !important;
    width: 28%;
    margin: auto;
    padding-bottom: 12px !important;
  }

  .model-text {

    p {
      span {
        color: rgb(255, 255, 255);
        font-size: 20px;
        font-weight: 600;
      }

      font-weight: 500;
    }
  }
}

.girl-mini {
  background-image: url("../../assets/Homepage/Modal/girl-bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 25px;

  .model-banner {
    margin-top: 20px;
  }

  .modelImg1 {
    width: 85%;
    margin: auto;
  }

  .modeldate {
    width: 40%;
    margin: auto;
  }

  .modellogo {
    width: 50%;
    margin: auto;
  }

  .modelbtn {
    width: 30%;
    margin: auto;
  }

  .model-text {
    margin-top: 20px;

    p {
      span {
        color: #fdfdfd;
        font-size: 20px;
        font-weight: 600;
      }

      font-weight: 500;
    }
  }
}

.luna-mini {
  background-image: url("../../assets/Homepage/Modal/luna-bg.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 25px;

  .model-banner {
    margin-top: 20px;
  }

  .modelImg1 {

    width: 85%;
    margin: auto;
  }

  .modellogo {

    margin-top: 20px;
  }

  .modelbtn {
    margin-top: -30px !important;
    width: 30%;
    margin: auto;
  }

  .model-text {
    margin: 20px 0;
    background-color: rgb(0 115 186 / 55%);
    ;
    padding: 10px;
    color: #fff;

    span {
      color: #ff008a;
      font-size: 20px;
      font-weight: 500;
    }

  }
}

.be-mini {
  background-image: url("../../assets/Homepage/Modal/be-bg.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 25px;

  .model-banner {
    margin-top: 10px;
  }

  .modelImg1 {

    width: 85%;
    margin: auto;
  }

  .modeldate {

    width: 40%;
    margin: auto;
  }

  .modellogo {
    width: 35%;
    margin-top: 20px !important;
    margin: auto;
  }

  .modelbtn {
    margin-top: 20px !important;
    width: 28%;
    margin: auto;
  }

  .model-text {

    span {
      color: red;
      background-color: rgba(0, 0, 0, 0.39);
      font-size: 20px;
      font-weight: 500;
    }

  }

  .model-para3 {
    color: #fff;
    margin-bottom: 10px !important;
  }

}

.code-mini {
  background-image: url("../../assets/Homepage/Modal/code-bg.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 25px;

  .model-banner {
    margin-top: 10px;
  }

  .modelImg1 {

    width: 85%;
    margin: auto;
  }

  .modeldate {
    width: 31%;
    margin: auto;
    margin-top: 22px !important;
  }

  .modellogo {
    width: 38%;
    margin-top: 1px !important;
    margin: auto;
  }

  .modelbtn {
    margin-top: 20px !important;
    margin-bottom: 223px !important;
    width: 28%;
    margin: auto;
  }

  .model-text {

    span {
      color: #02d3cf;

      font-size: 20px;
      font-weight: 500;
    }

  }

  .model-para3 {
    color: #fff;
    margin-bottom: 10px !important;
  }
}

.pitch-mini {
  background-image: url("../../assets/Homepage/Modal/pitch-bg.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 25px;

  .model-banner {
    margin-top: 10px;
  }

  .modelImg1 {

    width: 85%;
    margin: auto;
  }

  .modeldate {
    width: 62%;
    margin: auto;
    margin-top: 76px !important;
  }

  .modellogo {
    width: 38%;
    margin-top: 21px !important;
    margin: auto;
  }

  .modelbtn {
    margin-top: 24px !important;
    margin-bottom: 62px !important;
    width: 28%;
    margin: auto;
  }

  .model-text {

    span {
      color: #ff008a;

      font-size: 20px;
      font-weight: 500;
    }

  }

  .model-para3 {

    margin-bottom: 10px !important;
  }
}

.going-mini {
  background-image: url("../../assets/Homepage/Modal/going-bg.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 25px;

  .modelImg1 {

    width: 90%;
    margin: auto;
  }

  .modellogo {
    width: 54%;
    margin-top: 21px !important;
    margin: auto;
  }

  .modelbtn {
    margin-top: 16px !important;
    margin-bottom: 30px !important;
    width: 26%;
    margin: auto;
  }

  .model-text {
    p {

      span {
        color: #fedb01;

        font-size: 20px;
        font-weight: 600;
      }

      font-weight: 500;
    }
  }

  .model-para3 {
    color: #fff;

  }
}

.home-model:focus-visible {
  outline: 0px;
}

.home-model {
  max-width: 60% !important;
  width: 50% !important;
  margin: auto !important;

  .modelImg2 {
    width: 85%;
    margin: auto;
  }

}

// .add-any{
//   opacity: 1;
//   transition: opacity 0.1s ease;
//   transition-delay: 0.3s;
//   height: auto;
// }
.fade-in-image {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1024px) {
  p {
    font-weight: 500;
  }

  .home-model {
    max-width: 100% !important;
    width: 100% !important;
  }

  .home-one {
    .home1 {
      .bamboo {
        position: absolute;
        top: 7%;
        left: 8%;
        z-index: 9;
        width: 83%;
      }


      .sli-img {
        .sli {
          width: 92%;
          margin: auto;
          padding-top: 86px !important;

          .next-icon-container {
            right: 1.6rem !important;
          }

          .prev-icon-container {
            left: 0.9rem !important;
          }
        }
      }

    }
  }

  .home2 {
    .gts-logo {

      top: -93px;

    }

    .play {
      .tv-1 {
        padding: 10px 9px 12px 8px;
      }

      .play-text {
        width: 180px;
        margin: auto;

        p {
          font-size: 14px !important;
        }
      }
    }

    .outdoor {
      .tv-2 {
        padding: 10px 9px 12px 8px;
      }

      .out-text {
        width: 203px;
        margin: auto;

        p {
          font-size: 14px !important;
        }
      }
    }

    .plan {
      .tv-3 {
        padding: 10px 9px 12px 8px;
      }

      .plan-text {
        width: 204px;
        margin: auto;

        p {
          font-size: 14px !important;
        }
      }
    }

  }

  .out-mini {
    .model-text {
      p {
        font-size: 19px !important;
        line-height: 1.7rem !important;
      }
    }
  }

  .map-mini {
    background-size: 100% 100%;
    height: auto;

    .tv-1 .mum {
      top: 13px;
      left: 14px;

      img,
      iframe {
        width: 100% !important;
        height: 203px;
        border-radius: 10px;
      }

    }

    .tv-2 .mum {
      top: 13px;
      left: 14px;

      img,
      iframe {
        width: 100% !important;
        height: 195px;
        border-radius: 16px;
      }
    }

    .tv-3 .mum {
      top: 13px;
      left: 14px;

      img,
      iframe {
        width: 100% !important;
        height: 203px;
        border-radius: 10px;
      }
    }

    .tv-4 .mum {
      top: 13px;
      left: 14px;

      img,
      iframe {
        width: 100% !important;
        height: 196px;
        border-radius: 10px;
      }
    }


  }

  .get-mini {
    .model-text p {
      font-size: 15px !important;
      line-height: 1.7rem !important;
    }
  }

  .school-mini {
    .model-banner {
      margin-top: 180px;
    }

    .modelImg1 {
      margin-top: 0px !important;
      width: 97%;

    }

    .model-text {
      p {
        text-align: left !important;
        font-weight: 500;
        font-size: 15px !important;
        line-height: 1.4rem !important;
      }
    }

    .modelbtn {
      margin-top: 0px !important;
      width: 20%;
      margin-bottom: 8px;
    }
  }

  .girl-mini {

    .model-text {
      p {
        font-size: 16px !important;
        line-height: 1.4rem !important;
      }
    }
  }

  .scrappy-mini {

    .model-text {
      p {
        font-size: 16px !important;
        line-height: 1.4rem !important;
      }
    }

    .modellogo {
      margin-top: 0px !important;

    }

    .modelbtn {
      margin-top: 91px !important;
      width: 28%;
      margin: auto;
    }
  }

  .luna-mini {
    background-size: 100% 100%;
    height: auto;
  }

  .goal-mini {

    background-size: 100% 920px;
    height: 920px;
  }

  .code-mini {
    .modelbtn {

      margin-bottom: 188px !important;

    }
  }

  .pitch-mini {
    .modelbtn {

      margin-bottom: 29px !important;

    }
  }

  .goal-slider .goal-text,
  .mom-slider .mom-text,
  .yes-slider .yes-text,
  .school-slider .school-text,
  .scrappy-slider .scrappy-text,
  .girl-slider .girl-text,
  .luna-slider .luna-text,
  .be-slider .be-text,
  .code-slider .code-text,
  .going-slider .going-text,
  .pitch-slider .pitch-text {
    width: 170px;
    margin: auto;
  }
}

@media only screen and (max-width: 834px) {
  .home2 {
    .outdoor {
      min-height: 526px;
      padding-top: 104px;

      .tv2 {
        padding: 7px 7px 14px 6px;
        min-height: 146px;
        width: 73%;
        background-size: 100% 100%;

        img {

          height: 120px;
          border-radius: 10px;

        }

        iframe {
          height: 120px;
        }
      }

      .out-text {
        width: 154px;
        margin: auto;

        p {

          font-size: 12px !important;
          line-height: 1rem !important;
        }
      }
    }

    .gts-logo {
      position: absolute;
      top: -78px;
      width: 100% !important;
      left: 80%;
    }

    .play {
      min-height: 526px;
      padding-top: 104px;

      .tv1 {
        padding: 7px 7px 14px 6px;
        min-height: 146px;
        width: 73%;
        background-size: 100% 100%;

        img {

          height: 120px;
          border-radius: 10px;

        }

        iframe {
          height: 120px;
        }
      }

      .play-text {
        width: 146px;
        margin: auto;

        p {
          font-size: 12px !important;
          line-height: 1rem !important;
        }
      }

    }

    .out-lo {
      width: 68%;
      margin: auto;
      margin-bottom: -99px;
    }

    .plan {
      min-height: 526px;
      padding-top: 104px;

      .tv3 {
        padding: 7px 7px 14px 6px;
        min-height: 146px;
        width: 73%;
        background-size: 100% 100%;

        img {

          height: 120px;
          border-radius: 10px;

        }

        iframe {
          height: 120px;
        }
      }

      .plan-text {
        width: 155px;
        margin: auto;

        p {
          font-size: 12px !important;
          line-height: 1rem !important;
        }
      }
    }

    .plan-lo {

      width: 68%;
      margin: auto;
      margin-bottom: -99px;

    }
  }

  .home2 .play-lo {
    width: 68%;
    margin: auto;
    margin-bottom: -98px;
  }

  .map-mini {
    background-size: 100% 100%;
    height: auto;

    .bg-tabs {
      padding: 3px 3px;

      p {
        font-size: 11px !important;
      }
    }

    .tv-1 .mum {
      top: 11px;
      left: 12px;

      img,
      iframe {
        height: 186px;
        border-radius: 26px;
      }
    }

    .tv-2 .mum {
      top: 11px;
      left: 12px;

      img,
      iframe {
        height: 179px;
        border-radius: 22px;
      }
    }

    .tv-3 .mum {
      top: 11px;
      left: 12px;

      img,
      iframe {
        height: 183px;
        border-radius: 20px;
      }
    }

    .tv-4 .mum {
      top: 11px;
      left: 12px;

      img,
      iframe {
        height: 180px;
        border-radius: 26px;
      }
    }
  }

  .goal-mini {
    background-size: 100% 100%;
    height: 34%;

    .model-banner {
      width: 107%;
      margin-left: -30px;
    }
  }

  .home-one {
    .home1 {
      .sli-img {
        .sli {
          width: 94%;
          margin: auto;
          padding-top: 77px !important;

          .prev-icon-container {
            left: 0.9rem !important;
          }

          .next-icon-container {
            right: 1.5rem !important;
          }

          .slick-prev,
          .slick-next {
            width: 5.75rem !important;
            height: 4.75rem !important;
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 820px) {
  p {
    font-weight: 500;
  }

  .map-mini {
    background-size: 100% 100%;
    height: auto;

    .bg-tabs {
      padding: 3px 3px;

      p {
        font-size: 11px !important;
      }
    }

    .tv-1 .mum {
      top: 11px;
      left: 12px;

      img,
      iframe {
        height: 181px;
        border-radius: 26px;
      }
    }

    .tv-2 .mum {
      top: 11px;
      left: 12px;

      img,
      iframe {
        height: 177px;
        border-radius: 26px;
      }
    }

    .tv-3 .mum {
      top: 11px;
      left: 12px;

      img,
      iframe {
        height: 181px;
        border-radius: 26px;
      }
    }

    .tv-4 .mum {
      top: 11px;
      left: 12px;

      img,
      iframe {
        height: 176px;
        border-radius: 26px;
      }
    }
  }

  .yes-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {

      margin-bottom: 69px;
    }
  }

  .play-mini {
    background-size: 100% 740px;
    height: 740px;
  }

  .out-mini {
    background-size: 100% 100%;
    height: auto;

    .model-text {
      p {
        line-height: 1.4rem !important;

        span {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

  .get-mini {
    background-size: 100% 100%;
    height: auto;
  }

  .school-mini {
    background-size: 100% 100%;
    height: auto;

    .model-banner {
      margin-top: 167px;
    }

    .model-text {
      p {

        font-size: 15px !important;
        line-height: 1rem !important;
      }
    }

    .modellogo {
      margin-top: -2px !important;

    }
  }

  .scrappy-mini {
    // background-size: 100% 725px;
    // height: 725px;
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      margin-top: 82px !important;
      width: 28%;
      margin: auto;
    }
  }

  .girl-mini {
    background-size: 100% 100%;
    height: auto;
  }

  .luna-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {

      padding-bottom: 0px !important;
    }
  }

  .be-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      padding-bottom: 0px !important;
    }
  }

  .code-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      .modelbtn {
        margin-bottom: 251px !important;
      }
    }
  }

  .pitch-mini {
    background-size: 100% 100%;
    height: auto;

    .modeldate {
      width: 62%;
      margin: auto;
      margin-top: 17px !important;
    }

    .modelbtn {

      margin-bottom: 20px !important;
    }
  }

  .going-mini {
    background-size: 100% 100%;
    height: auto;
  }

  //   .home-one {.home1 {.sli-img .sli{

  //       width: 98%;
  //       margin: auto;
  //       padding-top: 91px !important;

  //     .slick-next {
  //     width: 6.75rem !important;
  //   }}}}
  //   .home-one .home1 .sli-img .sli .slick-prev, .home-one .home1 .sli-img .sli .slick-next {
  //     width: 6.75rem !important;
  //     height: 12.75rem !important;
  // }
  // .home-one .home1 .sli-img .sli .prev-icon-container {
  //   left: 0.9rem !important;
  // }
  .home-one .know-btn {
    width: 18%;
    margin: auto;
  }

  .home2 {
    .outdoor {
      min-height: 526px;
      padding-top: 104px;

      .tv2 {
        padding: 7px 7px 14px 6px;
        min-height: 146px;
        width: 73%;
        background-size: 100% 100%;

        img {

          height: 120px;
          border-radius: 10px;

        }

        iframe {
          height: 120px;
        }
      }

      .out-text {
        width: 154px;
        margin: auto;

        p {

          font-size: 12px !important;
          line-height: 1rem !important;
        }
      }
    }

    .gts-logo {
      position: absolute;
      top: -78px;
      width: 100% !important;
      left: 80%;
    }

    .play {
      min-height: 526px;
      padding-top: 104px;

      .tv1 {
        padding: 7px 7px 14px 6px;
        min-height: 146px;
        width: 73%;
        background-size: 100% 100%;

        img {

          height: 120px;
          border-radius: 10px;

        }

        iframe {
          height: 120px;
        }
      }

      .play-text {
        width: 146px;
        margin: auto;

        p {
          font-size: 12px !important;
          line-height: 1rem !important;
        }
      }

    }

    .out-lo {
      width: 68%;
      margin: auto;
      margin-bottom: -99px;
    }

    .plan {
      min-height: 526px;
      padding-top: 104px;

      .tv3 {
        padding: 7px 7px 14px 6px;
        min-height: 146px;
        width: 73%;
        background-size: 100% 100%;

        img {

          height: 120px;
          border-radius: 10px;

        }

        iframe {
          height: 120px;
        }
      }

      .plan-text {
        width: 155px;
        margin: auto;

        p {
          font-size: 12px !important;
          line-height: 1rem !important;
        }
      }
    }

    .plan-lo {

      width: 68%;
      margin: auto;
      margin-bottom: -99px;

    }
  }

  .home2 .play-lo {
    width: 68%;
    margin: auto;
    margin-bottom: -98px;
  }

  .goal-mini {
    background-size: 100% 100%;
    height: 34%;

    .model-banner {
      width: 106%;
      margin-left: -13px;
    }
  }

}

@media only screen and (max-width: 768px) {
  .home-one .home1 .sli-img .sli {
    width: 96%;
    margin: auto;
    padding-top: 70px !important;
  }

  .map-mini {
    .tv-1 {
      .mum {
        top: 10px;
        left: 12px;

        img,
        iframe {
          height: 149px;
          border-radius: 15px;
        }
      }
    }

    .tv-2 {
      .mum {
        top: 10px;
        left: 11px;

        img,
        iframe {
          height: 144px;
          border-radius: 15px;
        }
      }
    }

    .tv-3 {
      .mum {
        top: 10px;
        left: 11px;

        img,
        iframe {
          height: 148px;
          border-radius: 15px;
        }
      }
    }

    .tv-4 {
      .mum {
        top: 10px;
        left: 11px;

        img,
        iframe {
          height: 145px;
          border-radius: 12px;

        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {

  .play-mini {

    background-size: 100% 100%;
    height: 660px;
  }

  .out-mini {
    background-size: 100% 100%;
    height: 754px;

    .model-text {
      p {
        font-weight: 500;
        font-size: 13px !important;
        line-height: 1rem !important;
      }
    }

    .modelbtn {
      padding-bottom: 0px !important;
    }
  }

  .get-mini {
    // background-size: 100% 665px;
    // height: 628px;
    // background-size: 100% 588px;
    //   height: 588px;
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      padding-bottom: 19px !important;
    }

    .model-text {
      p {
        font-size: 12px !important;
        line-height: 1rem !important;

      }
    }
  }

  .goal-mini {
    background-size: 100% 100%;
    height: 746px;

    .bg-tabs p {
      font-size: 10px !important;
    }

    .model-banner {
      width: 111%;
      margin-left: -13px;
    }

    .modelImg1 {
      margin-top: 3px !important;

    }

  }

  .map-mini {
    background-size: 100% 100%;
    height: auto;

    .tabs-data {
      flex-wrap: wrap;

      .bg-tabs p {
        font-size: 9px !important;
      }
    }

    .tv-4 {
      .mum {
        top: 5px;
        left: 6px;

        img,
        iframe {

          height: 79px;

        }
      }
    }

    .tv-1 {
      .mum {
        top: 5px;
        left: 6px;

        img,
        iframe {
          width: 100% !important;
          height: 80px;
          border-radius: 10px;
        }
      }
    }

    .tv-2 {
      .mum {
        top: 5px;
        left: 6px;

        img,
        iframe {
          width: 100% !important;
          height: 79px;
          border-radius: 10px;
        }
      }
    }

    .tv-3 {
      .mum {
        top: 5px;
        left: 6px;

        img,
        iframe {
          width: 100% !important;
          height: 80px;
          border-radius: 10px;
        }
      }
    }

    .modellogo {
      margin-top: 5px !important;

    }

    .modelbtn {
      margin-top: -3px !important;

    }
  }

  .yes-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      margin-bottom: 33px;
    }
  }

  .school-mini {
    background-size: 100% 100%;
    height: 942px;

    .model-banner {
      margin-top: 103px;
    }

    .model-text p {
      font-size: 11px !important;
      line-height: 1rem !important;
    }

    .modelImg1 {
      margin-top: 5px !important;
      width: 100%;
    }

    .modellogo {
      margin-top: 28px !important;
      width: 29%;
    }

    .modelbtn {
      margin-top: 6px !important;
      width: 27%;
      margin-bottom: 8px;
    }
  }

  .scrappy-mini {
    background-size: 100% 100%;
    height: auto;

    .model-text {
      p {
        font-size: 12px !important;
        line-height: 1rem !important;
      }
    }

    .modelbtn {
      margin-top: 29px !important;


    }
  }

  .girl-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      padding-bottom: 25px !important;
    }

    .model-text p {
      font-size: 11px !important;
      line-height: 1rem !important;
    }
  }

  .luna-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      margin-top: -21px !important;
      padding-bottom: 16px !important;
    }
  }

  .be-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      padding-bottom: 13px !important;
    }
  }

  .code-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      margin-top: 0px !important;
      margin-bottom: 144px !important;
      width: 28%;
      margin: auto;
    }
  }

  .pitch-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      margin-bottom: 39px !important;
    }
  }

  .going-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {

      margin-bottom: 9px !important;
    }
  }

  .home-one {
    .home1 {

      .bamboo {
        position: absolute;
        top: 8%;
        left: 12%;
        z-index: 9;
        width: 78%;
      }


      .sli-img {
        .sli {
          width: 101%;
          margin: auto;
          padding-top: 35px !important;

          .slick-prev,
          .slick-next {
            width: 2.75rem !important;
            height: 2.95rem !important;
          }

          .slick-slider {
            padding: 0 72px !important;
          }

          .prev-icon-container {
            left: 2.9rem !important;
          }

          .next-icon-container {
            right: 3rem !important;
          }
        }
      }
    }
  }



  .home-one {
    .school-img {
      width: 75%;
      margin: auto;
    }

    .know-btn {
      width: 22%;
      margin: auto;
    }

    .school-para {
      width: 337px;

    }
  }

  .home2 {


    .gts-logo {
      position: absolute;
      top: -53px;
      width: 100% !important;
      left: 71%;

      .gts {
        width: 28%;
      }
    }

    .img1 {
      width: 63%;

    }

    .img2 {
      width: 81%;

    }

    .play {
      min-height: 590px;

      .tv1 {

        min-height: 185px;
        width: 69%;
        background-size: 100% 100%;
        margin: auto;
        padding: 10px 11px 19px 10px;

        img {
          width: 100%;
          height: 151px;
          border-radius: 10px;
        }

        iframe {
          width: 100%;
          height: 152px;
          border-radius: 10px;
        }
      }

      .play-text {
        width: 218px;

        p {
          font-size: 16px !important;
          line-height: 1.5rem !important;
          letter-spacing: 0px;
        }
      }
    }

    .outdoor {
      min-height: 573px;

      .tv2 {

        min-height: 185px;
        width: 69%;
        background-size: 100% 100%;
        margin: auto;
        padding: 10px 11px 19px 10px;

        img {
          width: 100%;
          height: 151px;
          border-radius: 10px;
        }

        iframe {
          width: 100%;
          height: 152px;
          border-radius: 10px;
        }
      }

      .out-text {
        width: 227px;

        p {
          font-size: 16px !important;
          line-height: 1.4rem !important;
          letter-spacing: 0px;
        }
      }
    }

    .plan {
      min-height: 575px;

      .player-wrapper-small {
        padding-top: 70.25%;
      }

      .tv3 {

        min-height: 185px;
        width: 69%;
        background-size: 100% 100%;
        margin: auto;
        padding: 10px 11px 19px 10px;

        img {
          width: 100%;
          height: 151px;
          border-radius: 10px;
        }

        iframe {
          width: 100%;
          height: 152px;
          border-radius: 10px;
        }
      }

      .plan-text {
        width: 211px;
        margin: auto;

        p {
          font-size: 16px !important;
          line-height: 1.4rem !important;
          letter-spacing: 0px;
        }
      }
    }
  }

  // .goal-slider .goal-text p , .mom-slider .mom-text p , .yes-slider .yes-text p , .school-slider .school-text p , .scrappy-slider .scrappy-text p , .girl-slider .girl-text p , .luna-slider .luna-text p, .be-slider .be-text p , .code-slider .code-text p , .pitch-slider .pitch-text p , .going-slider .going-text p   {
  //   letter-spacing: 0px;
  //   text-align: left !important;
  // }
  .model-text {
    p {
      font-size: 11px !important;
      line-height: 0.95rem !important;
    }

    span {
      font-size: 12px !important;
    }
  }

  .home-model {
    max-width: 100% !important;
    width: 100% !important;

    .cross-section {
      width: 20px !important
    }
  }
}

@media only screen and (max-width: 375px) {
  .home-one {
    .home1 {
      .bamboo {
        top: 7%;
      }

      .sli-img {
        .sli {

          padding-top: 34px !important;

          .slick-slider {
            padding: 0 68px !important;
          }
        }
      }
    }

    .school-para {
      width: 284px;

      p {
        font-size: 13px !important;

      }
    }
  }

  .home2 .play {
    min-height: 580px;
  }

  .home2 .outdoor {
    min-height: 553px;
  }

  .home2 .plan {
    min-height: 559px;
  }

  .out-mini {
    background-size: 100% 100%;
    height: 666px;

    .model-text p {
      font-size: 11px !important;
      line-height: 0.95rem !important;
    }
  }

  .get-mini {
    // background-size: 100% 550px;
    // height: 550px;
    background-size: 100% 100%;
    height: auto;
  }

  .play-mini {
    background-size: 100% 100%;
    height: 605px;
  }

  .goal-mini {
    // background-size: 100% 560px;
    // height: 560px;
    background-size: 100% 100%;
    height: 676px;

    .bg-tabs p {
      font-size: 8px !important;
    }

    .modelbtn {
      padding-bottom: 0px !important;
    }
  }

  .yes-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      margin-bottom: 21px;
    }
  }

  .map-mini {
    background-size: 100% 100%;
    height: auto;

    .tv-1 .mum iframe,
    .tv-1 .mum img {
      height: 69px;

    }

    .tv-2 .mum iframe,
    .tv-2 .mum img {

      height: 69px;

    }

    .tv-3 .mum iframe,
    .tv-3 .mum img {

      height: 69px;

    }

    .tv-4 .mum iframe,
    .tv-4 .mum img {

      height: 69px;

    }
  }

  .school-mini {
    background-size: 100% 100%;
    height: 885px;

  }

  .scrappy-mini {
    background-size: 100% 100%;
    height: auto;
  }

  .girl-mini {
    background-size: 100% 100%;
    height: auto;
  }

  .be-mini {
    background-size: 100% 100%;
    height: auto;
  }

  .code-mini {
    background-size: 100% 100%;
    height: auto;

    .modellogo {
      width: 56%;
      margin-top: 1px !important;
      margin: auto;
    }
  }

  .pitch-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      margin-bottom: 29px !important;
    }
  }

  .going-mini {
    background-size: 100% 100%;
    height: auto;
  }

  .luna-mini {
    background-size: 100% 100%;
    height: auto;
  }

}

@media only screen and (max-width: 320px) {
  .home2 .play {
    min-height: 549px;
  }

  .home2 .outdoor .out-text {
    width: 186px;
  }

  .home2 .plan .plan-text {
    width: 195px;
    margin: auto;
  }

  .home2 .plan .player-wrapper-small {
    padding-top: 83.25%;
  }

  .home2 .play .play-text {
    width: 189px;
  }

  .home2 .outdoor .out-text p {
    font-size: 16px !important;
    line-height: 1.4rem !important;
    letter-spacing: 0px;
  }

  .home2 .outdoor .out-text p {
    font-size: 16px !important;
    line-height: 1.4rem !important;
    letter-spacing: 0px;
  }

  .home2 .plan .plan-text p {
    font-size: 16px !important;
    line-height: 1.5rem !important;
    letter-spacing: 0px;
  }

  .goal-slider .goal-text,
  .mom-slider .mom-text,
  .yes-slider .yes-text,
  .school-slider .school-text,
  .scrappy-slider .scrappy-text,
  .girl-slider .girl-text,
  .luna-slider .luna-text,
  .be-slider .be-text,
  .code-slider .code-text,
  .going-slider .going-text,
  .pitch-slider .pitch-text {
    width: 137px;
    margin: auto;
  }

  .out-mini {
    background-size: 100% 100%;
    height: 574px;
  }

  .get-mini {
    // background-size: 100% 470px;
    // height: 470px;
    background-size: 100% 100%;
    height: auto;
  }

  .play-mini {
    background-size: 100% 100%;
    height: 527px;
  }

  .goal-mini {
    background-size: 100% 100%;
    height: 590px;

    .bg-tabs {

      padding: 3px 3px;
    }
  }

  .map-mini {
    background-size: 100% 100%;
    height: auto;

    .tv-1 {
      .mum {
        top: 5px;
        left: 4.5px;

        iframe,
        img {
          height: 58px;
          border-radius: 5px;
        }
      }
    }

    .tv-2 {
      .mum {
        top: 5px;
        left: 4.5px;

        iframe,
        img {
          height: 58px;
          border-radius: 5px;
        }
      }
    }

    .tv-3 {
      .mum {
        top: 5px;
        left: 4.5px;

        iframe,
        img {
          height: 58px;
          border-radius: 5px;
        }
      }
    }

    .tv-4 {
      .mum {
        top: 5px;
        left: 4.5px;

        iframe,
        img {
          height: 58px;
          border-radius: 5px;
        }
      }
    }
  }

  .yes-mini {
    background-size: 100% 100%;
    height: auto;
  }

  .school-mini {
    background-size: 100% 100%;
    height: 762px;

    .model-text p {
      font-size: 9px !important;
      line-height: 1rem !important;
    }

  }

  .scrappy-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      margin-top: 35px !important;

    }
  }

  .girl-mini {
    background-size: 100% 100%;
    height: auto;
  }

  .be-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      margin-top: 11px !important;

    }
  }

  .code-mini {
    background-size: 100% 100%;
    height: auto;
  }

  .pitch-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      margin-top: 7px !important;
      margin-bottom: 27px !important;

    }
  }

  .going-mini {
    background-size: 100% 100%;
    height: auto;
  }

  .luna-mini {
    background-size: 100% 100%;
    height: auto;

    .modelbtn {
      margin-top: -14px !important;
    }
  }

  .home-one {
    .home1 {
      .bamboo {
        top: 8%;
        left: 12%;
      }

      .sli-img {
        .sli {
          padding-top: 29px !important;

          .slick-slider {
            padding: 0 59px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1920px) {
  .map-mini {
    .tv-1 {
      .mum {
        img {
          width: 100% !important;
          height: 153px;
          border-radius: 10px;
        }

        iframe {
          width: 100% !important;
          height: 150px;
          border-radius: 10px;
        }
      }
    }
    .tv-2 {
      .mum {
        img {
          width: 100% !important;
          height: 145px;
          border-radius: 10px;
        }

        iframe {
          width: 100% !important;
          height: 145px;
          border-radius: 10px;
        }
      }
    }
    .tv-3 {
      .mum {
        img {
          width: 100% !important;
          height: 150px;
          border-radius: 10px;
        }

        iframe {
          width: 100% !important;
          height: 151px;
          border-radius: 10px;
        }
      }
    }
    .tv-4 {
      .mum {
        img {
          width: 100% !important;
          height: 146px;
          border-radius: 10px;
        }
        iframe {
          width: 100% !important;
          height: 146px;
          border-radius: 10px;
        }
      }
    }
  }}
 

.zoom-out {
  animation: fadeOut 2s;
  -webkit-animation: fadeOut 2s;
  -moz-animation: fadeOut 2s;
  -o-animation: fadeOut 2s;
  -ms-animation: fadeOut 2s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }


  50% {
    opacity: 0.5;
  }



  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }



  50% {
    opacity: 0.5;
  }



  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }


  50% {
    opacity: 0.5;
  }



  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }



  50% {
    opacity: 0.5;
  }



  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }



  50% {
    opacity: 0.5;
  }



  100% {
    opacity: 0;
  }
}