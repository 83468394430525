body{
    overflow-x: hidden !important;
}
.section2{
    background-image: url("../../assets/playtocity/tree-bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height:auto;
    padding-bottom: 150px !important;
    position: relative;
    .climate{
        width: 10%;
    position: absolute;
    top: 5%;
    right: 15%;
}
    
    .play-2{
     img{
            width:100%;
            
        }
    }
    .second-para{
        width: 680px;
        padding:10px  20px;
        margin: auto;
           background-color: rgba(0,0,0,48%);
        p{
            color: #fff;
            font-size: 21px !important;
            line-height: 1.75rem !important;
        span {
            font-weight: 900;
            color: #fedc00;
        }
        // color:#fff;

    }
    }
    .slick-slider {
        padding: 0px 150px !important;
    }
}
.section3{
    background-image: url("../../assets/playtocity/play-bg3.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height:auto;
    margin-top: -76px;
  .scraapy-img {  
    width: 85%;
    margin: auto;
    margin-top: -5%;
    position: relative;
    img{
        width:98% !important
    }
    .scrappy-video{
        position: absolute;
        top: 38%;
        left: 49.5%;
        transform: translate(-50%, -50%);
        iframe{
            width: 530px;
            height: 311px;
            border-radius: 10px;
        }
    }
}
}
.section4{
    background-image: url('../../assets/playtocity/skyy.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // min-height: 755px;
    min-height: 720px;
   // background-size: cover;
    // height: 100%;
    // width: 100%;
    // padding-bottom: 18px !important;
    .air-img{
        width: 43%;
        margin: auto;
        img{
            width: 100% !important;
            margin-top: -63px;
            margin-left: -12px;
        }
    }
    .air-tv{
        width: 55%;
        margin: auto;
        position: relative;
        img{
            width:100%;
        }
        .air-video{
            position: absolute;
            top: 58%;
            left: 49.9%;
            transform: translate(-50%, -50%);
            iframe{
                // width: 697px;
                // height: 383px;
                width: 671px;
                height: 379px;
                border-radius: 78px;
            }
        }
    }
    .air-para{
        p{
            font-size: 20px !important;
            letter-spacing: 0.35px;
        }
        
    }
}
.section5{
    margin-top: -7px;
}
.section6{
    background-image: url('../../assets/playtocity/pink1.png');
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding-bottom: 39px !important;
    .ground-img{
        width:45%;
        margin: auto;
        img{
         margin-top: -42px;
        }
    }
    .child-img{
        width: 70%;
        margin: auto;

    }
   
    .child-para{
        font-size: 18px !important;
    line-height: 1.75rem !important;
   width: 858px;
    margin: auto;

    }
    .panel-img{
        width: 68%;
    }
}
.section7   {

background-color: #d6f57c;
z-index: -1;
margin-top: -96px;
.img-foot{
    width: 70%;
    margin: auto;
    padding-top: 31px;
}
.foot-para{
    font-size: 16px !important;
    line-height: 1.5rem !important;
    width: 905px;
 margin: auto;
    font-weight: 500;

}
}
.section8{
    background-image: url("../../assets/playtocity/playbg8.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    .online-img{
        width: 46%;
        
        margin: auto;
        img{
            margin-top: -50px;
        }
    }
    .img-wood{
        width: 57%;
        margin: auto;
    }
    .small{
        width: 98%;
        position: relative;
        .video-1{
            position: absolute;
            top: 49%;
            width: 97%;
            left: 50%;
            transform: translate(-50% ,-50%); 

            .player-wrapper-small {
                position: relative;
                padding-top: 96.25%;
                border-radius: 0;
                overflow: hidden;
            }
            .react-player {
                position: absolute;
                top: -2px;
                left: 0;
            }
        }
        .scr-pic{
            position: absolute;
            top: 49%;
            width: 97%;
            left: 50%;
            transform: translate(-50% ,-50%);
        }
       
    }
    .big{
        position: relative;
        .video-2{
            position: absolute;
            top: 50%;
            width: 98.5%;
            left: 50%;
            transform: translate(-50% ,-50%);
            .player-wrapper-small {
                position: relative;
                padding-top: 57.25%;
                border-radius: 0;
                overflow: hidden;
            }
            .react-player {
                position: absolute;
                top: -9px;
                left: 0;
            }
        }
        .iso-pic{
            position: absolute;
            top: 50%;
            width: 98%;
            left: 50%;
            transform: translate(-50% ,-50%);
        }
      
    }
}
.section9{
    background-image: url("../../assets/playtocity/skydesk.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    min-height:1393px ;
    position: relative;
      .text-one{
        // position: absolute;
        // top: 8%;
        // right: 11%;
        // width: 30%;
        display: none;
    }
    .tv-one{
        background: url("../../assets/playtocity/tv.png") no-repeat scroll 0 0;
        min-height: 532px;
        width: 874px;
        position: absolute;
        // top: 9%;
        // left: 46%;
        top: 11%;
        left: 43%;
        padding: 120px 158px 120px 140px;
        transform: translateX(-50%);
        iframe{ 
            width: 100%;
            height: 314px;
            border-radius: 50px;
        }
    }
    .lines{
        position: absolute;
        top: 26%;
        right: -6%;
    }
   .text-two{
    // position: absolute;
    // bottom: 11%;
    // left: 14%;
    // width: 13%;
    display: none;

   }
   .tv-two{
    background: url("../../assets/playtocity/max1.png") no-repeat scroll 0 0;
    // min-height: 532px;
    // width: 743px;
    // min-height: 500px;
    // width: 650px;
    // position: absolute;
    // bottom: 2%;
    // left: 59%;
    // padding: 120px 50px 120px 98px;
    // transform: translateX(-50%);
    min-height: 500px;
    width: 700px;
    position: absolute;
    // bottom: -2.5%;
    // left: 56.5%;
    bottom: 0.5%;
    left: 57.5%;
    padding: 114px 75px 147px 100px;
    transform: translateX(-50%);
    iframe{ 
        width: 100%;
        // height: 314px;
        height: 285px;
        border-radius: 50px;
    }
   }
}
.blue-arrow{
    color: aqua;
    font-size: 4rem !important;
}


@media only screen and (max-width: 1024px){
    p{
        font-weight: 500;
    }
    body{
        overflow-x: hidden !important;
    }
.section3 {.scraapy-img {
    .scrappy-video {
   top: 38%;
    iframe {
        width: 430px;
        height: 261px;
    }
}
    }
}

.section4{
    min-height: 587px;
 .air-img{ img {
        width: 100% !important;
        margin-top: -49px;
    }
    
    
}
.air-tv {
    width: 67%;
    
    .air-video{
        top: 59%;
        left: 49.9%;
        iframe {
            width: 601px;
            height: 333px;
    // width: 521px;
    // height: 264px;
    border-radius: 54px;
}}}
    
}
.section6.child-para {
    font-size: 18px !important;
    line-height: 1.75rem !important;
    width: 625px;
    margin: auto;
}
.section7 {
   
     .img-foot {
        width: 70%;
        margin: auto;
        padding-top: 56px;
    }
    .foot-para {
    font-size: 14px !important;
    line-height: 1.5rem !important;
    width: 774px;
    margin: auto;
}}
.section8 .online-img img {
    margin-top: -37px;
}
.section8 .big .video-2 .react-player {
  
    top: -7px;
   
}
.section9 {
    // background-image: url("../../assets/playtocity/blue.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    min-height:1393px ;
    position: relative;
    .tv-one {
    background-position:  top center;
    width: 72%;
    position: absolute;
    padding: 120px 155px 151px 139px;;
    background-size: 90% 90%;
    margin: 0 auto;
}
.text-one {
    position: absolute;
    top: 8%;
    right: 9%;
    width: 33%;
    display: block;
}
.text-two {
    position: absolute;
    bottom: 13%;
    left: 4%;
    width: 18%;
    display: block;
}
.tv-two{
    bottom: -1.5%;
    left: 65.5%;
    background-size: 84% 84%;
    padding: 114px 175px 187px 81px;
}
}
}
@media only screen and (max-width: 834px){
    .section3 {.scraapy-img{ .scrappy-video {
        top:40%;
        iframe {
        width: 322px;
        height: 214px;
      }}}}
      .section4{ 
        min-height: 542px;
        .air-tv {
        width: 68%;
        margin: auto;
        position: relative;
        .air-video{
            top: 58%;
left: 49.9%;
            iframe {
            width: 500px;
            height: 264px;
            border-radius: 42px;
      }}}
       .air-para p {
        font-size: 15px !important;
        letter-spacing: 0.35px;
      }
}
.section6 {
    .child-img {
        width: 75%;
        margin: auto;
      }
    
    .child-para {
    font-size: 15px !important;
    line-height: 1.5rem !important;
    width: 557px;
    margin: auto;
  }}
  .section7 {
    background-color: #d6f57c;
    z-index: -1;
    margin-top: -63px;
  }
  .section9{ 
    
    .tv-one {
   
    padding: 120px 129px 151px 116px;
    iframe {
        
        border-radius: 40px;
      }
   
  }
  .tv-two {
    left: 71.5%;
  }
}
}
@media only screen and (max-width: 768px){
   
.section2 {
 
    background-size: 100% 700px;
    height: 700px;
    padding-bottom: 74px !important;
.second-para {
    width: 484px;
    padding: 10px 20px;
    margin: auto;
     p {
        color: #fff;
        font-size: 15px !important;
        line-height: 1.75rem !important;
    }
}
}
.section3 {
    .scraapy-img 
    {
    .scrappy-video{ 
        iframe {
    width: 317px;
    height: 184px;}}
}}
.section4{
    min-height: 427px;
    .air-img{
        img{
            margin-top: -30px;
        }
    }
    .air-para {p {
        font-size: 12px !important;
        letter-spacing: 0.35px;
    }}
    .air-tv 
    {.air-video 
    {
        iframe {
            width: 467px;
            height: 240px;
            border-radius: 27px;
}}}}
.section6 {
    background-size: 100% 571px;
 padding-bottom: 0 !important;
    .child-para {
    font-size: 11px !important;
    line-height: 1rem !important;
    width: 613px;

}}
.section7{
    margin-top: -66px;
.img-foot {
        width: 80%;
        margin: auto;
        padding-top: 31px;
    }
     .foot-para {
        width: 542px;
    font-size: 10px !important;
    line-height: 1rem !important;
   
}}
.section8 {.online-img img {
    margin-top: -28px;
}}
.section8 .small .video-1 .react-player {
   
    top: -3px;
   
}
.section9 {
    .tv-one {
         padding: 115px 153px 139px 143px;
        width: 84%;
        position: absolute;
        // padding: 120px 197px 151px 177px;
        background-size: 81% 91%;
        top: 12%;
        left: 41%;
    }
    
    .tv-two {
        background-position: top center;
        width: 80%;
        bottom: -2%;
        left: 61%;
        padding: 79px 124px 127px 146px;
        background-size: 74% 70%;
        iframe {
            width: 100%;
            height: 202px;
            border-radius: 38px;
        }
}
}}
@media only screen and (max-width: 425px){
.section2 {
    background-size: 100% 542px;
    height: auto;

    padding-bottom: 74px !important;
     .climate {
        top: 5%;
        right: 5%;
    }
.second-para {
    width: 321px;
    padding: 10px 9px;
    margin: auto;
     p {
        color: #fff;
        font-size: 11px !important;
        line-height: 1rem!important;
        margin-bottom: 0px;
    }
}

.slick-slider {
    padding: 0px 83px !important;
}
.next-icon-container {
    right: 1.5rem !important;
}
}
.section3 {.scraapy-img{ 
    width: 70%;
    margin: auto;
    margin-top: -5%;
    .scrappy-video {iframe {
    // width: 166px;
    // height: 104px;
    // border-radius: 5px;
    width: 100%;
    height: 92px;
    border-radius: 5px;
}}
}}
// .section6 .ground-img img {
//     margin-top: -20px;
// }
.section4{ 
    
        min-height: 271px;
  
    .air-para p {
        font-size: 10px !important;
        letter-spacing: 0.15px;
        margin-bottom: 0px !important;
    }
    .air-tv {
        width: 80%;
        .air-video {iframe {
        width: 291px;
        height: 155px;
        border-radius: 11%;
}}}
.air-img {
    width: 54%;
    img {
    margin-top: -12px;
}}
}

.section6{ 
    
    background-size: 100% 473px;
    height: 486px;

    .child-img {
        width: 100%;
        margin: auto;
    }
        .ground-img img {
            margin-top: -20px;
        }
    .panel-img {
    width: 100%;
    margin: auto;
}
.child-para {
    font-size: 10px !important;
    line-height: 1rem !important;
    width: 391px;
}
}
.section7 {
    .img-foot {
        width: 90%;
        margin: auto;
        padding-top: 31px;
    }
    .foot-para {
        font-size: 10px !important;
    line-height: 1rem !important;
    width: 379px;
}}
.section8 .online-img img {
    margin-top: -14px;
}
.section8 .img-wood {
    width: 74%;
    margin: auto;
}
.section8 .small {
    
        width: 91%;
   
    position: relative;
    margin: 0;
    .video-1{
         top: 49%;
    width: 97%;
    left: 50%;
    .player-wrapper-small {
        
        padding-top: 98.75%;
        border-radius: 0;
    }
    .react-player {
        position: absolute;
        top: -4px;
        left: 0;
    }
}
}
.section8 .big {
    position: relative;
    width: 102%;
    margin: 0;
    .video-2{
        top: 50.7%;
        width: 98%;
        left: 49.7%;
        .player-wrapper-small {
        position: relative;
        padding-top: 60.25%;
}
.react-player {
    position: absolute;
    top: -9px;
    left: 0;
}
}}
.section9 {
    background-image: url("../../assets/playtocity/skydesk.jpg");
    min-height: 600px;
    .tv-one {
        background-size: 90% 40%;
        // padding: 48px 82px 138px 73px;
        padding: 48px 78px 138px 68px;
    top: 13%;
    left: 38%;

iframe {
   
    border-radius: 19px;
    height: 126px;
}}
.text-one{
    display: none;
}
.tv-two {
    padding: 44px 44px 154px 52px;
    background-size: 90% 40%;
    bottom: -47%;
    width: 70%;
    left: 62%;
     iframe {
        height: 115px;
        border-radius: 24px;
    }
  
}
.text-two{
    display: none;
}
}
}
@media only screen and (max-width:414px){
    .section9 {.tv-one {
        background-size: 90% 40%;
        padding: 48px 69px 138px 63px;
        top: 13%;
        left: 38%;
    }
    .tv-two {
        padding: 45px 39px 154px 48px;
    }
}
}
@media only screen and (max-width:390){
    .section3 .scraapy-img .scrappy-video iframe {
    width: 100%;
    height: 80px;
    border-radius: 5px;
    .section4 .air-tv .air-video iframe {
    width: 273px;
    height: 148px;
    border-radius: 11%;
}
}
}
@media only screen and (max-width: 375px){
.section4 {.air-tv {.air-video 
     {
     width: 257px;
    height: 138px;
        iframe {
    width: 257px;
    height: 138px;
    border-radius: 10%;
}}}}
.section7 .foot-para {
    font-size: 9px !important;
    line-height: 1rem !important;
    width: 300px;
}
.section3 .scraapy-img .scrappy-video iframe {
    height: 78px;
    border-radius: 5px;
}
.section4 {
    min-height: 251px;
}
.section6 {
    background-size: 100% 442px;
    height: 456px;
    .child-para {
      
        width: 336px;
    }
    .panel-img {
        width: 100%;
        margin: auto;
    }}
.section9 .tv-one {
   
    padding: 48px 67px 138px 58px;
   
}
.section9 .tv-two {
    padding: 44px 38px 154px 44px;
}
.section8 .big .video-2 .react-player {
   
    top: -9px;

   
}
}
@media only screen and (max-width: 320px){
.section3 {.scraapy-img {.scrappy-video {iframe {
    width: 101%;
    height: 64px;
}}}}
.section8 .big {
    position: relative;
    width: 101%;
    margin: 0;
}
.section8 .big .video-2 .player-wrapper-small {
    position: relative;
    padding-top: 59.25%;
}
.section8 .big .video-2 .react-player {
    position: absolute;
    top: -6px;
    left: 0;
}
.section8 .small .video-1 .react-player {
    position: absolute;
    top: -3px;
    left: 0;
}
.section4 {
    min-height: 218px;
    .air-img {
        width: 62%;
        margin: auto;
        img {
            margin-top: -12px;
        }
    }
}
// .section6 {
//     background-size: 100% 669px;
//     height: 669px;
// }
.section6 {
    background-size: 100% 346px;
    height: 363px;
}
.section9 .tv-one {
    padding: 48px 58px 138px 50px;
}
.section9 .tv-two {
    padding: 44px 31px 127px 41px;
    iframe {
        height: 115px;
        border-radius: 19px;
    }
}
.section6 .child-para {
    font-size: 10px !important;
    line-height: 1rem !important;
    width: 291px;
}
.section4 .air-tv .air-video {
    
    top: 64%;
    left: 54.9%;
    
}
.section4 .air-tv .air-video iframe {
    width: 227px;
    height: 112px;
    border-radius: 10%;
}
.section2 .second-para {
    width: 292px;
    padding: 10px 9px;
    margin: auto;
}


.section2 .second-para p {
    color: #fff;
    font-size: 10px !important;
    line-height: 1rem !important;
    margin-bottom: 0px;
}
}