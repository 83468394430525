// .GAP-banner {
//   height: 60vh;
// }

.GAT_bg-img {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.GAT-map-container {
  position: absolute;
  top: -20rem;
  width: 80%;
  @include desktop-max{
    top: -12rem;
  }
  @include mobileScreen{
    top: -4rem;
    width: 100%;
    left: -10px;
    @include desktop{
      top: -10rem;
    }
    @include tablet{
      top: -10rem;
    }
    @include phablet{
      top: -7rem;
    }
    
  }
}

.GAT-logo-container {
  //   width: 18rem;
  //   height: 18rem;
  background: $white;
  border: 1px solid $white;
  border-radius: 50%;
  overflow: hidden;
  .GAT-logo {
    width: 100%;
    height: 100%;
    padding: $fs-16px;
    @include mobileScreen {
      padding: $fs-8px;
    }
  }
}
.GAT-img-container1 {
    width: 110%;
    height: 100%;
    @media (min-width: 320px) and (max-width: 1200px) {
      width: 80%;
      height: 50%;
      margin-top: -10%;
      margin-left: 10%;
     }
}
.GAT-img-container2 {
  width: 115%;
  height: 100%;
  margin-top: 15%;
  @media (min-width: 320px) and (max-width: 1200px) {
    width: 100%;
    height: 50%;
    margin-top: -6%;
   }
}
 
 
.title-margin-top {
  margin-top: 0% !important;
 
}
.title-margin{
  margin-left: 6% !important;
  @media (min-width: 320px) and (max-width: 1200px) {
    margin-left: 6% !important;
    margin-top: 10% !important;
   }
}
.ruff-cut{
  margin-left : 20%
}

.section1-img {
  width: 15%;
  margin-top: -7%;
  margin-left: 10%;
  @media (min-width: 320px) and (max-width: 1200px){
    width: 15% !important;
    margin-top: 5% !important;
    margin-left: 65%;
  }
  @include desktop-max{
  //  width: 15% !important;
    }
}

.section2-img {
  width: 15%;
  margin-top: -10%;
  margin-left: 10%;
  @media (min-width: 320px) and (max-width: 1200px){
    width: 25% !important;
    margin-top: 5% !important;
    display: block !important;
    margin: 0 auto !important;
   }
   @include desktop-max{
  //  width: 20%;
   }
}

.section3-img {
    width: 70%;
    margin-top: 15%;
    margin-left: 10%;
  @media (min-width: 320px) and (max-width: 1200px){
    width: 35% !important;
    margin-top: 45% !important;
    display: block !important;
    margin: 0 auto !important;
   }
   @include desktop-max{
   // width: 100% !important;
   // margin-top: 50% !important;
   }
}

.section4-img { 
    width:30%;
    margin-top: -17%;
    margin-left: 50%;
  @media (min-width: 320px) and (max-width: 1200px){
    width: 120% !important;
    margin-left: 5%;
    margin-top: 15%;
    display: block !important;
     
  }
  @include desktop-max{
 //   width: 20%;
  }
}
.img-margin-top{
  margin-top: 22% !important;
  @include mobileScreen{
    margin-top: 0% !important;
  }
}
.IKEA-img{
  width : 100%;
  @media (min-width: 320px) and (max-width: 1200px){
    margin-top: -3% !important;
     
  }
}
img-padding-left {
  padding-left: 20px;
}
.GAT-img-container {
  width: 100%;
  object-fit: cover;
  padding: 0.5rem;
  object-position: top;
  height: 50vh !important;
  @include mobileScreen{
    height: 100%;
  }
  @include desktop-max{
   }
}
.second-img-container{
  width: 100%;
  object-fit: cover;
  padding: 0.5rem;
  object-position: top;
  height: 30vh !important;
  @include mobileScreen{
    height: 100%;
  }
  @include desktop-max{
   }
}
.sec-img-container{
  width: 100%;
  object-fit: cover;
  padding: 0.5rem;
  object-position: top;
  height: 50vh !important;
  @include mobileScreen{
    height: 100%;
  }
  @include desktop-max{
   }
}
.GAT-youngh-container {
  width: 100%;
  object-fit: cover;
  object-position: top;
  height: 30vh !important;
  @include mobileScreen{
    height: 100%;
  }
  @include desktop-max{
   }
}
.video-margin{
  @media (min-width: 320px) and (max-width: 766px){
  margin-top: 57%;
  }
}

.first-img-container {
  width: 100%;
  object-fit: cover;
  height: 50vh ;
   padding: 0.5rem;
   @media (min-width: 320px) and (max-width: 1200px){
    height: 100% !important;
    display: block !important;
    margin: 0 auto !important;
    width: 50% !important;
  }
}

.Ent-img-container{
  width: 100%;
  object-fit: contain;
  padding: 0.5rem;
  @include mobileScreen{
    display: block !important;
    margin: 0 auto !important;
    width: 60% !important;
   }
}
 
.img-border {
   border : 2px solid #e75074;
}
.GAP-player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
  overflow: hidden;
  @media (min-width: 320px) and (max-width: 1200px){
    padding-top: 40.25% !important;
  }
}

.govel-img-container{
  display: flex;
  align-items: center;
  @media (min-width: 320px) and (max-width: 1200px){
    display: block;
    margin-bottom: $fs-16px;
     
  }
}