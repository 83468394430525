 .slider_bg {
       width: 100%;
       height: auto;
       filter: drop-shadow(0 0 15.5px rgba(0, 0, 0, 0.22000737488269806));
       background-color: #f5f0f0;
 }
 .slider_bg img {
          width: 100% !important;
        }
     
     .slick-slider{
       padding:0 100px !important;
    }
    @media screen and (max-width: 480px) {
        .slick-slider {
         padding: 0 20px !important;
        }
    }