.circle-loader {
  position: absolute;
  top: 0;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 99;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border: $fs-4px solid $sliver-grey;
  border-top: $fs-4px solid $crimson-red;
  border-radius: 50%;
  width: $fs-36px;
  height: $fs-36px;
  animation: spin 2s linear infinite;
}
.loader-small {
  border: $fs-4px solid $white;
  border-top: $fs-4px solid $crimson-red;
  border-radius: 50%;
  width: $fs-18px;
  height: $fs-18px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-position {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-position--bottom{
  bottom: 8%;
}