.external-title {
  font-family: $robotoSlab-Bold;
  color: $fuscous-grey;
  @include setFont(500, normal, normal, normal, left);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
}

.external-section--title {
  font-family: $robotoSlab-Regular;
  color: $white;
  @include setFont(500, normal, normal, normal, left);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
}
.external-main--heading {
  font-family: $robotoSlab-Regular;
  color: #000;
  @include setFont(500, normal, normal, normal, left);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
}

.title-bright-grey {
  color: $bright-grey;
}

.title-black-pearl {
  color: $black-pearl;
}

.title-denim-blue-light {
  color: $denim-blue-light !important;
}

.title-crimson-red {
  color: $crimson-red !important;
}

.title-kelly-green-light {
  color: $kelly-green-light !important;
}

.title-neon-carrot {
  color: $neon-carrot !important;
}

.title-black-rock {
  color: $black-rock;
}

.title-dune-grey {
  color: $dune-grey;
}

.title-danger {
  color: $danger;
}

.title-white {
  color: $white;
}

.title-cornflower-blue {
  color: $cornflower-blue;
}

.title-clinder-grey {
  color: $clinder-grey;
}

.title-tea-rose {
  color: $tea-rose;
}

.title-summer-sky {
  color: $summer-sky !important;
}

.title-black-rock-light {
  color: $black-rock-light;
}

.title-red {
  color: $DarkPink-red !important;
}

.title-black-rock-medium {
  color: $black-rock-medium;
}

.title-black {
  color: $black;
}

.title-blue-medium {
  color: $blue-medium !important;
}

.bg-mosque-green {
  background-color: $mosque-green;
}

.bg-crimson-red {
  background-color: $crimson-red;
}

.bg-cyprus-blue {
  background-color: $cyprus-blue;
}

.bg-zircon-grey {
  background-color: $zircon-grey;
}

.bg-mosque-green {
  background-color: $mosque-green;
}

.bg-danger {
  background-color: $danger;
}

.bg-oyster-grey {
  background-color: $oyster-grey;
}

.bg-white {
  background-color: $white;
}

.lato-Regular {
  font-family: $lato-Regular;
}

.robotoSlab-Bold {
  font-family: $robotoSlab-Bold;
}

.roboto-Bold {
  font-family: $roboto-Bold;
}

.robotoSlab-Medium {
  font-family: $robotoSlab-Medium;
}

.robotoSlab-Regular {
  font-family: $robotoSlab-Regular;
}

.card-container {
  border: none !important;
  box-shadow: 0px 3px 6px #72c3db29;
  @include mobileScreen {
    margin-bottom: $fs-32px;
  }
}

.card-body--container {
  @include setPadding(
    $fs-32px !important,
    $fs-32px !important,
    $fs-32px !important,
    $fs-32px !important
  );
  @include mobileScreen {
    @include setPadding(
      $fs-24px !important,
      $fs-24px !important,
      $fs-24px !important,
      $fs-24px !important
    );
  }
}

.card-body--small {
  @include setPadding(
    $fs-12px !important,
    $fs-12px !important,
    $fs-12px !important,
    $fs-12px !important
  );
}

.card-title--position {
  height: 3.45rem;
}

.edit-img-file {
  position: absolute;
  right: $fs-5px;
  border-radius: 50%;
  width: $fs-24px;
  height: $fs-24px;
  background: $danger;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.312rem;
  cursor: pointer;
}

.img-thumbnail-container {
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
}

.dropzone {
  &:focus {
    border: none !important;
    outline: 0 !important;
  }
}

.banner-container {
  height: 30vh;
  overflow: hidden;
}

.tab-mobile--position {
  padding-top: $fs-42px;
  padding-bottom: $fs-42px;
  @include mobileScreen {
    padding-top: $fs-24px;
  }
}

.tab-container {
  border: none !important;
  flex-wrap: nowrap !important;
  @include mobileScreen {
    justify-content: space-between;
    @include desktop {
      justify-content: flex-start;
    }
  }
  .tab-nav-item {
    .tab-nav-link {
      border: none !important;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      color: $sliver-grey !important;
      font-size: $fs-16px !important;
      font-family: $robotoSlab-Regular !important;
      padding-bottom: $fs-16px;
      border-bottom: $fs-4px solid $transparent !important;
      @include setFont(500, normal, normal, normal, left);
      cursor: pointer;
      background-color: $transparent;
      @include mobileScreen {
        padding-left: $fs-6px !important;
        padding-right: $fs-6px !important;
        font-size: $fs-14px !important;
        @include desktop {
          padding-left: $fs-14px !important;
          padding-right: $fs-14px !important;
        }
      }
      &.active {
        border: none !important;
        color: $crimson-red !important;
        font-family: $robotoSlab-Bold !important;
        border-bottom: $fs-4px solid $crimson-red !important;
        @include setFont(500, normal, normal, normal, left);
        background-color: $transparent;
        &:hover {
          border-bottom: $fs-4px solid $crimson-red !important;
        }
      }
    }
    &:hover {
      border: none !important;
    }
    &:focus {
      border: none !important;
    }
  }
}

.tab-container--position {
  justify-content: space-between;
}

.team-img-container {
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
  width: 8rem;
  height: 8rem;
  // border: 5px dotted #ff515c;
  display: flex;
  //align-items: center;
  justify-content: center;
  padding: $fs-2px;
  .team-img {
    border: 5px solid $gainsboro-white;
    border-radius: 50%;
    aspect-ratio: 16 / 16;
    object-fit: cover;
  }
  &:hover,
  &:focus {
    transition: transform 0.5s;
    transition: opacity 2s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 0px 20px 0 rgba($crimson-red, 0.15),
      0 0px 20px 0 rgba($crimson-red, 0.15);
  }
}
.gotham-bold {
  font-family: $gotham-bold;
}
.gotham-light {
  font-family: $gotham-light;
}
.team-title-container {
  margin: auto;
  overflow: hidden;
  width: 8rem;
  height: 8rem;
  //border: 5px dotted #ff515c;
  display: flex;
  //align-items: center;
  justify-content: center;
  padding: $fs-2px;
}

.supporter-img-container {
  display: flex;
  align-items: center;
  .common-img--position {
    width: calcu(100% / 5);
  }
  // width: 10%;
  // @include mobileScreen {
  //   width: 40%;
  // }
}

.logo-title--small {
  @include mobileScreen {
    font-size: 1rem !important;
  }
}

.custom-table-thead {
  tr {
    font-family: $robotoSlab-Bold;
    background-color: $mosque-green;
    color: $white;
    font-size: $fs-14px;
    text-align: center;
  }
}

.custom-table-tbody {
  tr {
    font-family: $robotoSlab-Regular;
    color: $black-pearl;
    font-size: $fs-13px;
    text-align: center;
    align-items: center;
    td {
      vertical-align: middle;
    }
  }
}

.project-container {
  background-color: $white !important;
  @include mobileScreen {
    margin-bottom: $fs-16px;
  }
}
.project-img-container {
  padding: 1rem 1rem;
  // width: 8rem;
  margin: auto;
  // height: 8rem;
  // overflow: hidden;
  display: flex;
  align-items: center;
  @include mobileScreen {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0.75rem 0.5rem;
  }
  .img-item {
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 16 / 9;
  }
}

.img-item--position {
  width: 100%;
  height: 300px;
  object-fit: contain;
  @include mobileScreen {
    height: 250px;
    @include desktop {
      height: 300px;
    }
  }
}

.project-img-container--small {
  width: 40% !important;
  margin: auto;
}

.project-cards-list {
  cursor: pointer;
}

.modal-container-position {
  max-width: 60% !important;
  width: 60%;
  @include mobileScreen {
    max-width: 90% !important;
    width: 90%;
  }
}

.project-modal-container {
  max-width: 40% !important;
  width: 40% !important;
  margin: auto !important;
  @include desktop-max {
    max-width: 60% !important;
    width: 60% !important;
  }
  @include mobileScreen {
    max-width: 90% !important;
    width: 90% !important;
  }
  .modal-content {
    border-radius: 18px !important;
  }
  .project-title--container {
    padding: $fs-16px $fs-8px !important;
    border-radius: 18px;
    border: 4px dotted $cornflower-blue;
    @include mobileScreen {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}

.project-modal-title--container {
  background-color: $white;
  border: none !important;
  &:focus {
    border: none !important;
  }
}

.project-details-container {
  height: 35vh;
  display: flex;
  align-items: center;
  // overflow: hidden;
  .details-img {
    height: 35vh;
    object-fit: cover;
    // height: 100%;
    width: 100%;
    overflow: hidden;
  }
}

.project-details-img-container {
  position: absolute;
  width: 8rem;
  height: 8rem;
  //top: -65%;
  background: $white;
  border-radius: 50%;
  border: 2px dotted $selective-yellow;
  padding: 0.75rem;
  bottom: -18%;
  left: 14%;
  @include mobileScreen {
    width: 6rem;
    height: 6rem;
    bottom: -18%;
    left: 7%;
  }
  @include desktop-max {
    left: 7%;
    bottom: -15%;
  }
}

// .get-a-plan-map-img {
//   position: absolute;
//   width: 50%;
//   height: 300%;
//   padding: 0.75rem;
//   bottom: -18%;
//    @include mobileScreen {
//     width: 6rem;
//     height: 6rem;
//     bottom: -18%;
//    }
//   @include desktop-max {
//      bottom: -15%;
//   }
// }

.pd-location-mobile--position {
  @include mobileScreen {
    display: none;
  }
}
// .get-a-plan-text{
//   position: absolute;
//   height: 15%;
//   //top: -65%;
//   padding: 0.75rem;
//   left: 55%;
//   @include mobileScreen {
//     width: 6rem;
//     height: 6rem;
//     bottom: -18%;
//     left: 7%;
//   }
//   @include desktop-max {
//     left: 7%;
//     bottom: -15%;
//   }
// }
.pd-location-mobile--position {
  @include mobileScreen {
    display: none;
  }
}

.bullet-points {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-left: $fs-28px;
  &:before {
    position: absolute;
    width: 0.85rem;
    height: 0.85rem;
    background: $cornflower-blue;
    content: "";
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin-top: $fs-6px;
    left: 0;
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
  border-radius: $fs-8px;
  overflow: hidden;
}
.player-wrapper-small {
  position: relative;
  padding-top: 50.25% /* Player ratio: 100 / (1280 / 720) */;
  border-radius: $fs-8px;
  overflow: hidden;
  @include mobileScreen {
    padding-top: 56.25%;
  }
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.platinum-logo {
  .link-platinum {
    position: absolute;
    //width: 35%;
    width: 30%;
    // right: 7%;
    right: 0px;
    text-align: right;
    // bottom: 5rem;
    bottom: 0px;
    // height: 25rem;
    height: 22rem;
    cursor: pointer;
    // z-index: 999;
  }
}

.social-icon-width {
  width: $fs-38px;
  margin-right: $fs-14px;
}

.contact-logo--contianer {
  position: absolute;
  top: 12%;
  right: 0;
  left: 0;
  width: 65%;
  margin: auto;
  text-align: right;
  .logo {
    width: 12%;
  }
}

.contact-info-address--contianer {
  position: absolute;
  top: 0;
  display: flex;
  width: 75%;
  top: 12%;
  left: 0;
  right: 0;
  margin: auto;
  //justify-content: space-evenly;
  .common-width {
    width: calc(100% / 6);
  }
}

.address-icon {
  width: $fs-42px;
}

.social-info--mobile-position {
  justify-content: center;
  @include mobileScreen {
    flex-direction: column-reverse;
    padding-bottom: $fs-32px;
  }
}

.social-icon--position {
  justify-content: center;
  @include mobileScreen {
    justify-content: flex-start;
  }
}

.policies-container {
  width: $fs-44px;
  height: $fs-44px;
  margin-bottom: $fs-16px;
}

.policies-icon {
  width: 70%;
}

.social-address--mobile-position {
  @include mobileScreen {
    margin: auto;
    justify-content: center;
  }
}

.card-reports {
  .report-card-container {
    cursor: pointer;
    position: relative;
    @include setPadding(
      $fs-20px !important,
      $fs-20px !important,
      $fs-16px !important,
      $fs-32px !important
    );
    margin-bottom: $fs-16px;
    height: 250px;
    @include xsmall {
      height: 180px;
    }
    @include phone {
      height: 200px;
    }
    @include iphone {
      height: 190px;
    }
    @include phablet {
      height: 200px;
    }
    @include tablet {
      height: 200px;
    }
  }
}

.view-container {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 20%;
  z-index: 99;
}

.pdf-thumbnail-container {
  position: absolute;
  top: 3px;
  right: 2px;
  background: $crimson-red;
}

.grid-img-container {
  border: 1px solid $bright-grey;
  border-radius: 4px;
}

.trash-icon-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: $crimson-red;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
}

.card-logo-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.count-bg-container {
  .count-bg {
    .count-bg-container {
      height: 17rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .count-desc {
        width: 70%;
        margin: auto;
        @include mobileScreen {
          width: 90%;
        }
      }
      @include mobileScreen {
        height: 14rem;
      }
    }
  }
}

.gallery-card-img-container {
  width: 100%;
  display: flex;
  align-items: center;
  .img-item {
    width: 100%;
    height: 300px;
    object-fit: cover;
    @include mobileScreen {
      height: 250px;
      @include desktop {
        height: 300px;
      }
    }
  }
}

.pdf-card-container {
  width: 100%;
  display: flex;
  align-items: center;
  .pdf-item {
    width: 100%;
    height: 300px;
    object-fit: cover;
    @include mobileScreen {
      height: 250px;
      @include desktop {
        height: 300px;
      }
    }
  }
}

.login-banner-img {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  object-fit: none;
}
.login-bg-color {
  background-color: #fdfdfd;
}
.login-mobile-banner--position {
  display: block;
  @include mobileScreen {
    display: none;
  }
}

.image-modal-container {
  @include setPadding($fs-16px, $fs-16px, $fs-16px, $fs-16px);
  width: 50% !important;
  @include desktop-max {
    width: 80% !important;
  }
  @include mobileScreen {
    width: 90% !important;
  }
}

.project-info__mobile-webiste-info {
  @include mobileScreen {
    padding-top: $fs-20px;
  }
}

.supporter__effect {
  transition: transform 0.5s;
  border-left: $fs-4px solid transparent !important;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 2s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.15);
    content: "";
    opacity: 0;
    z-index: -1;
  }
  &:hover,
  &:focus {
    transform: scale3d(1.006, 1.006, 1);
    border-left: $fs-4px solid $denim-blue-light !important;
    &::after {
      opacity: 1;
    }
  }
}

.link--underline-none {
  text-decoration: none !important;
}

.policies_effect {
  position: relative;
  border: 1px solid $transparent;
  overflow: hidden;
  .go-corner {
    position: absolute;
    right: 0;
    height: 100%;
    width: $fs-28px;
    padding-right: $fs-20px;
    border-radius: 0;
    transform: skew(7deg);
    margin-right: -36px;
    align-items: start;
    background-image: linear-gradient(
      -45deg,
      #8f479a 1%,
      $denim-blue-light 100%
    );
    opacity: 0;
    .go-arrow {
      transform: skew(-7deg);
      margin-left: 1px;
      margin-top: 3px;
      opacity: 0;
      color: $white;
    }
  }
  &:hover {
    border: 1px solid $denim-blue-light !important;
    .go-corner {
      transition: all 0.4s ease-out;
      margin-right: -14px;
      opacity: 1;
    }
    .go-arrow {
      opacity: 1;
    }
  }
}

.card__effect {
  transition: transform 0.5s;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.15);
    content: "";
    opacity: 0;
    z-index: -1;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
  }
  &:hover,
  &:focus {
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.15);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.15);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.15);
    transition: all 200ms ease-in;
    transform: scale(1.15);
    @include mobileScreen {
      -webkit-transition: all 200ms ease-in;
      -webkit-transform: scale(1);
      -ms-transition: all 200ms ease-in;
      -ms-transform: scale(1);
      -moz-transition: all 200ms ease-in;
      -moz-transform: scale(1);
      transition: all 200ms ease-in;
      transform: scale(1);
    }
  }
}

.project-map-setion {
  background: #f7f7f7 0% 0% no-repeat padding-box;
}

.card-description--postion {
  height: 7.75rem;
  overflow: hidden;
}

.card-description-small--postion {
  height: 5rem;
  overflow: hidden;
  @include mobileScreen {
    height: 6rem;
  }
}
.card-title-small--position {
  height: 1.15rem;
  overflow: hidden;
}

.video-single-info--position {
  @include mobileScreen {
    @include setPadding(
      $fs-16px !important,
      0 !important,
      0 !important,
      0 !important
    );
  }
}

.counter-tittle {
  @include mobileScreen {
    font-size: $fs-32px !important;
  }
}

.video-grid-mobile--position {
  @include mobileScreen {
    margin: auto !important;
  }
}

.count-description--position {
  height: 3.5rem;
  overflow: hidden;
}

.color-dots-container {
  width: 1.5rem;
  border: 1px solid $gainsboro-white;
  height: 1.5rem;
  margin: 0.25rem;
  border-radius: 50%;
  cursor: pointer;
}

.mgrpdf__wrapper {
  div {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gainsboro-white;
    border-radius: $fs-3px;
    div {
      opacity: 0;
    }
  }
  canvas {
    width: 100% !important;
    height: 300px !important;
    border: 1px solid $gainsboro-white;
    border-radius: $fs-3px;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.close-icon-container {
  width: 3rem;
  position: absolute;
  right: 4rem;
  z-index: 999;
  top: 0.5rem;
  cursor: pointer;
}

.team-bg-position2 {
  background-color: $hyper-link-blue;
}

.team-bg-position3 {
  background-color: $crimson-red;
}

.team-bg-position4 {
  background-color: $blue-medium;
}

.team-bg-position5 {
  background-color: $tea-rose;
}

.team-bg-position6 {
  background-color: $yellow-selective;
}

.team-bg-position7 {
  background-color: $blue;
}

.about-usa-container {
  background: $yellow-medium 0% 0% no-repeat padding-box;
}

.about-us-logo-container {
  width: 10rem;
}

.about-us-logo-medium-container {
  width: 12rem;
}

.flex-wrap {
  flex-wrap: wrap;
}

.img-viewer-container {
  height: 500px;
  width: 100%;
  object-fit: contain;
}

.player-border {
  border: $fs-1px $red;
}
/////////////////Home Css///////////
.home_banner_bg {
  background-image: url("../assets/home_banner.png");
  background-repeat: no-repeat;
  height: 600px;
  background-position: bottom;
  background-size: cover;
  width: 100% !important;

  h1 {
    font-weight: 900;
    color: #15375d;
  }
  h4 {
    font-weight: 600;
    margin-top: 20px;
    color: #00809a;
    font-size: 1.7rem !important;
  }
  h6 {
    color: #003f66;
    font-weight: 700;
    margin-top: 20px;
    font-size: 0.9rem !important;
  }
}

.slider_heading {
  color: #52f0e8;
}
.outdoor_img {
  img {
    width: 200px !important;
    // height:100px !important
  }
  .football_img {
    width: 80px !important;
  }
}
.home_plan_bg {
  background-color: #ffed78;

  .plan_img {
    img {
      width: 120px !important;
    }
  }
}
.home_popup {
  overflow-y: scroll !important;
  overflow-x: hidden !important;

  .home_modal {
    width: 100% !important;
    background-color: #fff;
  }
}

.home_video_bg {
  background-image: url("../assets/video-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  img {
    width: 70% !important;
    // height: 100% !important;
  }

  .download_file_btn {
    width: 260px;
    padding: 10px;
    border-radius: 19px;
    font-weight: 500;
    text-align: center;
    filter: drop-shadow(0 0 25.5px rgba(0, 0, 0, 0.2628084719181061));
    background-image: linear-gradient(0deg, #ff67b3 0%, #ffb0b2 100%);
    margin-top: 10px !important;
  }
  .home_video {
    width: 600px;
    height: auto;
    // max-width: 100%;
    border-radius: 36px;
    filter: drop-shadow(0 0 20.5px rgba(0, 0, 0, 0.2045164853334427));
    background-color: #ffdf5e;
    flex: auto;
    .youtube_video {
      border-radius: 20px;
      height: 280px;
    }
    img {
      width: 50px !important;
    }
  }
}
.sub_logo {
  img {
    width: 140px !important;
  }
}
.sub_logo1 img {
  width: 100px !important;
}
.home_close {
  width: 40px !important;
  margin-top: 20px !important;
  margin-right: 50px !important;
}
// .about_last_section {
//   // width: 60% !important;
// }
.download_report_btn {
  width: 307px;
  padding: 10px;
  border-radius: 19px;
  font-weight: 500;
  margin: auto;
  margin-top: 14px !important;
  text-align: center;
  filter: drop-shadow(0 0 25.5px rgba(0, 0, 0, 0.2628084719181061));
  background-image: linear-gradient(0deg, #ff67b3 0%, #ffb0b2 100%);
  margin-top: 10px !important;
}
.about-p{
  p{
    font-size: 17px !important;
    letter-spacing: 1px ;
    line-height: 1.75rem !important;
  }
}
.report-btn{
  width:53% !important
}
.about_magazine_img img {
  width: 50% !important;
}
.about-out-bg{
  background-color: #ec008b;
}
.bg-black{
  background-color: #000;
  .player-wrapper-small{
    border-radius: 0px !important;
  }
}
.bg-whitee{
  background-color: rgb(255, 255, 255);
  .player-wrapper-small{
    border-radius: 0px !important;
  }
}
.scrap_banner img {
  width: 95% !important;
}
.usa-main{
  background-image: url('../assets/bg1.png');
  background-repeat: no-repeat;
  background-size: 23% ;
}
.usa-play-pop{
  width:100%;
  height:300px;
}
.usa-section {

  p{
    letter-spacing: 1px;
    font-size: 17px !important;
  }
  h2 {
    color: #ec008c !important;
  }
  .globe_img img {
    width: 74% !important;
  }
  .play_img img {
    width: 50% !important;
  }
  .pink_bold1 {
    color: #ec008c !important;
    font-weight: 700;
    letter-spacing: 1px;
  }
  .pink_bold {
    color: #ec008c !important;
   
    letter-spacing: 1px;
  }
  .usa_climate_img img {
    width: 50% !important;
  }
}
.videocontainer {
  position: relative;
  width: 500px;
  margin: auto;
}
.videocontainer img {
  width: 100%;
}
.data-box{
  width:400px;
  p{
    letter-spacing: 0px !important;
  }
}
.videocontainer .tv_video {
  position: absolute;
  top: 49%;
  left: 40.7%;
  transform: translate(-50%, -50%);
  width: 318px;
  height: 243px;
  border-radius: 10px;
}

.videocontainer1 {
  position: relative;
  width: 400px;
  margin: auto;
}
.videocontainer2 {
  position: relative;
  width: 400px;
  // margin: auto;
}
.videocontainer2 img {
  width: 100%;
}
.videocontainer2 .tv_video {
  // position: absolute;
  // top: 47%;
  // left: 40.7%;
  // transform: translate(-50%, -50%);
  // width: 215px;
  // height: 161px;
  // border-radius: 10px;
  position: absolute;
  top: 47%;
  left: 40.8%;
  transform: translate(-50%, -50%);
  width: 272px;
  height: 203px;
  border-radius: 10px;
}
.videocontainer1 img {
  width: 100%;
}
.videocontainer1 .tv_video {
  // position: absolute;
  // top: 47%;
  // left: 40.7%;
  // transform: translate(-50%, -50%);
  // width: 215px;
  // height: 161px;
  // border-radius: 10px;
  position: absolute;
  top: 47%;
  left: 40.8%;
  transform: translate(-50%, -50%);
  width: 272px;
  height: 203px;
  border-radius: 10px;
}
.card-data {
  padding-left: 17px !important;
}
.side-image {
  position: absolute;
  right: 0;
  top: 60px;
  width: 80%;
}
.support_heading {
  padding-left: 32px !important;
}
.usa_video {
  width: auto !important;
  height: 250px !important;
}
.usa_logo_img img {
  width: 11% !important;
  border-radius: 50%;
}
.team_bg {
  background-image: url("../assets/sky_bg.jpg");
  background-size: cover;
}
.head-font{
  font-size: 30px !important;
}
@media screen and(max-width:768px) {
  .videocontainer1 {
    width: 300px !important;
  }
  .videocontainer1 .tv_video {
    top: 47% !important;
    left: 40.7% !important;

    width: 215px !important;
    height: 161px !important;
  }
  .videocontainer2 {
    width: 300px !important;
  }
  .videocontainer2 .tv_video {
    top: 47% !important;
    left: 40.7% !important;

    width: 215px !important;
    height: 161px !important;
  }
  
    .data-box {
      width: 250px;
  }
  
  .support_plan img {
    width: 143px !important;

    top: 30px !important;
    right: 18px !important;
  }
}
@media screen and (max-width: 480px) {
  .slick-slider {
    padding: 0 40px !important;
}
 .usa-main{ p {
    letter-spacing: 1px;
    font-size: 14px !important;
}}
  .football_pitch {
    height: 100px !important;
  }
  .home_banner_bg {
    background-position: right;
    background-size: auto;
    height: 400px !important;
    h1 {
      font-size: 20px !important;
    }
    h4 {
      font-size: 18px !important;
    }
    h6 {
      font-size: 14px !important;
    }
  }
  .home_close {
    width: 30px !important;
    margin-top: 10px !important;
    margin-right: 30px !important;
  }
  .slick-next {
    right: 0rem !important;
  }
  .videocontainer {
    width: 260px !important;
  }
  .videocontainer .tv_video {
    width: 173px;
    height: 127px;
  }
  .videocontainer1 {
    width: 260px !important;
  }
  .videocontainer1 .tv_video {
    width: 177px !important;
    height: 136px !important;
    top: 48% !important;
    left: 40.7% !important;
  }
  .videocontainer2 {
    width: 260px !important;
  }
  .videocontainer2 .tv_video {
    width: 177px !important;
    height: 136px !important;
    top: 48% !important;
    left: 40.7% !important;
  }
  .side-image {
    position: relative;
    right: 0;
    top: 0;
    width: 80%;
  }
  .sub_logo {
    img {
      width: 100px !important;
    }
  }
  .sub_logo1 {
    img {
      width: 80px !important;
    }
  }
  .support_heading {
    padding-left: 21px !important;
  }
  .usa_video {
    width: 250px !important;
  }
  .head-font{
    font-size: 20px !important;
  }
}

.home_video_bg {
  .home_video {
    margin: 0 !important;
  }
}
.external-section--title {
  text-align: justify;
}
.support_plan img {
  width: 107px !important;
  position: absolute;
  top: 84px;
  right: 56px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .support_plan img {
    width: 107px !important;
    top: 54px;
    right: 18px;
  }
    .usa-section p {
      letter-spacing: 1px;
      font-size: 14px !important;
  }
  .usa-section .usa_climate_img img {
    width: 89% !important;
}
  
}
@media only screen and (max-width: 425px) {
.usa_logo_img img {
  width: 23% !important;
  border-radius: 50%;
}
}