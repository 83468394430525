img {
  width: 100%;
}

.no-margin {
  @include setMargin(0 !important, 0 !important, 0 !important, 0 !important);
}

.col-no--padding {
  @include setPadding(0 !important, 0 !important, 0 !important, 0 !important);
}

.mobile-col-no--padding {
  @include mobileScreen{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

a {
  &:hover {
    text-decoration: none !important;
  }
  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}

.no-mobile--margin {
  @include mobileScreen {
    @include setMargin(0 !important, 0 !important, 0 !important, 0 !important);
  }
}

.no-mobile--padding {
  @include mobileScreen {
    @include setPadding(0 !important, 0 !important, 0 !important, 0 !important);
  }
}

.container {
  max-width: 90% !important;
  margin: auto;
  padding: 0 !important;
  @include mobileScreen {
    max-width: 90% !important;
    @include desktop {
      max-width: 95% !important;
    }
  }
}

.align-center {
  text-align: center !important;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left !important;
}

.fw-blod {
  font-weight: bold !important;
}

.lh-25px{
  line-height: $fs-25px !important;
}

.lh-28px{
  line-height: $fs-28px !important;
}

.lh-30px {
  line-height: $fs-30px !important;
}

.lh-40px {
  line-height: $fs-40px !important;
  @include mobileScreen{
    line-height: $fs-30px !important;
  }
}

.ls-8px {
  letter-spacing: $fs-8px !important;
}

.link--underline {
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: $denim-blue-light;
  }
}

.text-underline{
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

.link--underline--none {
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.full-height {
  height: 100vh;
}

.full-width {
  width: 100% !important;
}

.ht-80vh {
  height: 80vh;
}

.btn-block {
  width: 100%;
}

.error {
  font-family: $robotoSlab-Regular;
  color: $danger;
  @include setFont(500, normal, normal, normal, left);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
}

.search__form-container {
  border: none !important;
  border-bottom: $fs-3px solid $crimson-red !important;
  border-radius: 0 !important;
  font-family: $robotoSlab-Regular;
  padding-left: $fs-30px !important;
  padding-right: $fs-40px !important;
  color: $black-rock !important;
  @include setFont(800, normal, normal, normal, left);
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border-bottom-color: $crimson-red !important;
  }
  &::placeholder {
    opacity: 0.45 !important;
    font-size: $fs-12px !important;
  }
}

.search__icon-container {
  align-items: center !important;
  .search__icon_append {
    position: absolute;
    z-index: 99;
  }
  .search__icon-position {
    background-color: transparent !important;
    padding: 0.25rem !important;
    border: none !important;
  }
}

.reset_label {
  position: absolute;
  right: $fs-4px;
  z-index: 99;
  color: $crimson-red !important;
  cursor: pointer !important;
}

.width-32px {
  width: $fs-32px;
}

.width-64px {
  width: $fs-64px;
}

.ml-12px {
  margin-left: $fs-12px !important;
}

.sc-fnVZcZ.cDmETx.rdt_TableHeader {
  display: none;
}

.confirmation-content__container {
  padding-top: 1rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form-group{
  margin-bottom: $fs-12px !important;
}

.fs-10px{
  font-size: $fs-10px !important;
}

.fs-14px {
  font-size: $fs-14px !important;
}

.overflow-hidden{
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer !important;
}

.box-shadow{
  box-shadow: 0px $fs-8px $fs-6px #72C3DB29;
}

.ml-auto{
  margin-left: auto !important;
}

.full-height-perc{
  height: 100%;
  object-fit: cover;
}

.down-arrow{
  font-size: $fs-42px !important;
  vertical-align: unset !important;
}

.mr-12px{
  margin-right: $fs-12px;
}

.pt-18px{
 padding-top: $fs-18px;
}

.pl-4px{
  padding-left: $fs-4px;
}

.pr-4px{
  padding-right: $fs-4px;
}

.pl-3px{
  padding-left: $fs-3px;
}

.pr-3px{
  padding-right: $fs-3px;
}

.pl-8px{
  padding-left: $fs-8px;
}

.pr-8px{
  padding-right: $fs-8px;
}

.pl-0px{
  padding-left: 0 !important;
  @include mobileScreen {
    padding-left: 0!important;
    padding-right:0 !important
  };
}

.modal-open{
  position: fixed;
  inset: 0;
}

.br-16px{
  border-radius: $fs-16px;
}

.br-24px{
  border-radius: $fs-24px !important;
}

.position-relative{
  position: relative;
}

.img-padding {
  padding: $fs-4px !important;
}

.pl-16px{
  padding-left: $fs-16px;
}
.pb-12px{
  padding-bottom: $fs-12px;
}

.mpt-16px{
  @include mobileScreen{
    padding-top: $fs-16px;
  }
}

// .menu-isOpen {
//   overflow: hidden !important;
//   position: fixed;
//   inset: 0;
// }


.year-picker{
  font-family: $robotoSlab-Light !important;
  font-size: $fs-14px !important;
  color: $black !important;
  border-radius: $fs-2px !important;
  border-color: $gainsboro-white !important;
  height: $fs-38px !important;
  width: 100% !important;
  cursor: pointer;
  @include setFont(
    600 !important,
    normal !important,
    normal !important,
    normal !important,
    left !important
  );
  &:focus {
    color: $black !important;
    box-shadow: none !important;
    border-color: $gainsboro-white !important;
  }
  &::placeholder {
    opacity: 0.45 !important;
    font-size: $fs-12px !important;
  }
  @include mobileScreen {
    height: $fs-54px !important;
    font-size: $fs-24px;
  }
  @include mobileScreen {
    height: $fs-38px !important;
  }
  .year-input{
    font-family: $robotoSlab-Light !important;
    font-size: $fs-14px !important;
    color: $black !important;
    @include setFont(
      600 !important,
      normal !important,
      normal !important,
      normal !important,
      left !important
    );
  }
  .picker-panel{
    z-index: 99;
    width: 240px;
    height: 240px;
    .year-wrapper{
      .year{
        font-family: $robotoSlab-Light !important;
        font-size: $fs-14px !important;
        color: $black !important;
        text-decoration: none !important;
        &.selected-year{
          color: $white !important;
        }
      }
    }
    .footer{
      .footer-btn{
        .footer-today{
          font-family: $robotoSlab-Light !important;
          font-size: $fs-14px !important;
          color: $crimson-red !important;
        }
      }
    }
  }
}

.form-control[type=file] {
  overflow: hidden;
  opacity: 0;
}

.mobile-column-reverse{
  @include mobileScreen{
    flex-direction: column-reverse;
  }
}

.rdt_TableHeader {
  display: none !important;
}

.mobile-pt-12px{
  @include mobileScreen{
    padding-top: $fs-12px;
  }
}

.width-40{
  width: 40%;
  @include mobileScreen{
    width: 50%;
  };
}

.width-30{
  width: 30%;
  @include mobileScreen{
    width: 40%;
  };
}

.text-decoration--none{
  text-decoration: none;
}

.dp-none{
  display: none;
}

.br-4px{
  border-radius: $fs-4px;
}

.mb-auto{
  margin-top: auto !important;
  margin-bottom: auto !important;
  @include mobileScreen{
    margin: auto;
  }
}