.gallery{
  position: relative;
  height:100%;
  width: 90%;
  margin: auto;
  padding: 45px 0;
  display:grid;
  grid-template-columns:auto auto auto auto;
  grid-gap: 1vmin;
  grid-auto-flow:dense;
  @include mobileScreen {
      display: block;
      padding-top: 2px;
      padding: 0px 0;

  }
}
 .img {
  position: relative;
  height: 100%;
  overflow: hidden;
  @include mobileScreen {
    display: block;
    width: 100%;
    height: 100%;
    padding-bottom: 4px;
  }
}
.container {
  position: relative;
  text-align: center;
}
.text-container-left {
  position: absolute;
  padding : 10px
}
.text-conteainer{
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: center;
  font-size: $fs-1px;
  @include mobileScreen {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    align-items: center;
    text-align: left;
    justify-content: center;
    font-size: $fs-28px;
    margin-top: 10px;
  }
}
.text-conteainer p{
  margin-left: 30px;
    margin-right: 30px;
}

.banner-descriptionImgSectiom{
  position: relative;
  height:100%;
  width: 90%;
  margin: auto;
  padding-top: 30px;
  @include mobileScreen {
      display: block;
      padding-top: 4px;
      padding-left: 10px;
  }
}
.img img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.GG-banner-mobile {
  display: none;
  @include mobileScreen {
    display: block;
    height: 100%;
    width: 100%;
}
}
.GG-banner-desktop {
  display: block;
  @include mobileScreen {
    display: none;
    height: 100%;
    width: 100%;
}
}
.text-padding {
  @include mobileScreen {
  padding-left: 21px;
  padding-top: 7PX;
  }
}
.banner-description {
  font-size: 2.5rem !important;
  line-height: 2.6rem !important;
  @media only screen and (max-width: 600px) {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 6px;
    padding-right: 0px;
    line-height: 1rem;
    font-size: 0.75rem !important;
  }
  @media only screen and (width: 768px) {
    padding-bottom: 0px;
    padding-left: 1px;
    padding-right: 0px;
    line-height: 2rem;
    font-size: 1.50rem !important;
  }
}
.banner-description-img {
  height: 85%;;
  padding :0px;
  @include mobileScreen {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    height: 100%;
  }
}
.firstSection-img {
    &:first-child{
    grid-column-start: span 2;
    grid-row-start: span 2 ; 
    }
    &:nth-child(2){
      background-color: rgb(95,51,147);
      color: white;
    @include mobileScreen {
      display: block;
      width: 100%;
      height: 100%;
      margin-bottom: 4px;
    }
    }
    &:nth-child(5){
      background-color: white;
      border: 2px solid rgb(0,0,0);
      @include mobileScreen {
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid rgb(95,51,147);
          margin-bottom: 4px;
      }
    }
    &:nth-child(8){
    background-color: rgb(236,0,140);
    color: white;
    @include mobileScreen {
      display: block;
      width: 100%;
      height: 100%;
        margin-bottom: 4px;
    }
    }
}
.secondSection-img {
  &:first-child{
  grid-column-start: span 2;
  grid-row-start: span 2 ; 
  }
  &:nth-child(2){
    background-color: rgb(255,242,0);
    color : black;
  @include mobileScreen {
    display: block;
    width: 100%;
    height: 100%;
    margin-bottom: 4px;
  }
  }
  &:nth-child(5){
    background-color: rgb(0,207,110);
    color: white;
    @include mobileScreen {
      display: block;
      width: 100%;
      height: 100%;
      margin-bottom: 4px;
    }
  }
  &:nth-child(7){
    grid-column-start: span 2;
    background-color: rgb(95,51,147)
    ;
    color: white;
  @include mobileScreen {
    display: block;
    width: 100%;
    height: 100%;
      margin-bottom: 4px;
  }
  }
}
.thirdSection-img{
  &:first-child{
    grid-column-start: span 2;
    grid-row-start: span 2 ; 
    }
  
    &:nth-child(5){
      background-color: white;
      border: 2px solid rgb(0,0,0);
      @include mobileScreen {
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid rgb(95,51,147);
          margin-bottom: 4px;
      }
    }
    &:nth-child(8){
    background-color: rgb(236,0,140);
    color: white;
    @include mobileScreen {
      display: block;
      width: 100%;
      height: 100%;
        margin-bottom: 4px;
    }
    }
}
.fourthSection-img{
  border: 1px solid rgb(95,51,147);
  @include mobileScreen {
    border: none;
  }
    &:nth-child(3){
      background-color: rgb(255,242,0);
      border: none;
      @include mobileScreen {
        display: block;
        width: 100%;
        height: 100%;
          margin-bottom: 4px;
      }
    }
    &:nth-child(12){
    background-color: rgb(0,207,110);
    color: white;
    border: none;
    @include mobileScreen {
      display: block;
      width: 100%;
      height: 100%;
        margin-bottom: 4px;
    }
    }
}
.fifthSection-img {
  border: 1px solid rgb(95,51,147);
  @include mobileScreen {
    border: none;
  }
  &:first-child{
    grid-column-start: span 2;
    grid-row-start: span 3 ; 
    border: none;
    @include mobileScreen {
      display: block;
      width: 100%;
      height: 100%;
        margin-bottom: 4px;
    }
    }
}
.map-text{
  margin-left: 60%;
  position: absolute;
}
.img-height{
  height: 25vh;
  @media only screen and (max-width: 600px) {
    height: 23vh;
    width: 110%;
  }
  @media only screen and (width: 768px) {
    height: 20vh;
  }
}
.logoBT{
  width: 85%
};
.logoBT2{
  width: 200%
}