.common-select-drop-down__control {
    background-color: $white !important;
    border: solid $fs-1px $gainsboro-white !important;
    font-size: $fs-12px;
    cursor: pointer !important;
    @include setFont(800, normal, normal, normal, left);
    min-height: $fs-28px !important;
    height: auto;
    font-family: $robotoSlab-Light;
    color: $black !important;
    border-color: $gainsboro-white !important;
    border-radius: $fs-4px;
    @include mobileScreen {
      min-height: $fs-20px !important;
      font-size: $fs-12px;
    }
  }
  
  
  .common-select-drop-down__option {
    @include setFont(300, normal, normal, normal, left);
    color: $black !important;
    font-size: $fs-12px !important;
    font-family: $robotoSlab-Light;
    cursor: pointer !important;
    @include setPadding(
      $fs-4px !important,
      $fs-4px !important,
      $fs-4px !important,
      $fs-4px !important
    );
    &:hover {
      color: $white !important;
      background-color: $crimson-red !important;
    }
  }
  
  .common-select-drop-down-large__option--is-focused.css-1n7v3ny-option{
    background-color: $crimson-red !important;
    color: $white !important;
   }
  
  .common-select-drop-down__control__placeholder {
    font-family: $robotoSlab-Light;
    opacity: 0.45 !important;
    font-size: $fs-13px !important;
  }
  
  .common-select-drop-down__control__control {
    border-color: $gainsboro-white !important;
  }
  
  .common-select-drop-down__dropdown-indicator {
    padding: $fs-4px !important;
  }
  .common-select-drop-down__indicator {
    padding: $fs-4px !important;
  }
  
  .common-select-drop-down__option--is-selected {
    color: $white !important;
    background-color: $crimson-red !important;
  }
  
  .common-input-select-drop-down__control__control {
    min-height: $fs-38px !important;
  }
  
  .common-input-select-drop-down__control {
    background-color: $white !important;
    border: solid $fs-1px $gainsboro-white !important;
    font-size: $fs-12px;
    cursor: pointer !important;
    @include setFont(300, normal, normal, normal, left);
    min-height: $fs-42px !important;
    height: auto;
    font-family: $robotoSlab-Light;
    color: $black !important;
    border-radius: $fs-4px;
    border-color: $gainsboro-white !important;
    @include mobileScreen {
      min-height: $fs-38px !important;
      font-size: $fs-12px;
    }
  }
  
  .common-input-select-drop-down__control__placeholder {
    font-family: $robotoSlab-Light;
    opacity: 0.45 !important;
    font-size: $fs-13px !important;
  }
  
  .common-input-select-drop-down__control__single-value {
    font-family: $robotoSlab-Light;
    color: $black !important;
    font-weight: 800 !important;
  }
  
  .common-input-select-drop-down__control__option {
    @include setFont(300, normal, normal, normal, left);
    color: $black !important;
    font-size: $fs-12px !important;
    font-family: $robotoSlab-Light;
    cursor: pointer !important;
    @include setPadding(
      $fs-4px !important,
      $fs-4px !important,
      $fs-4px !important,
      $fs-4px !important
    );
    &:hover {
      color: $white !important;
      background-color: $crimson-red !important;
    }
  }
  
  .common-input-select-drop-down__control__menu {
    z-index: 9 !important;
  }
  
  .common-select-drop-down-large__control {
    background-color: $white !important;
    border: solid $fs-1px $gainsboro-white !important;
    font-size: $fs-15px;
    cursor: pointer !important;
    @include setFont(800, normal, normal, normal, left);
    min-height: $fs-36px !important;
    height: auto;
    font-family: $robotoSlab-Light;
    color: $black !important;
    border-color: $gainsboro-white !important;
    border-radius: $fs-4px;
    @include mobileScreen {
      min-height: $fs-36px !important;
      font-size: $fs-12px;
    }
  }
  
  .common-select-drop-down-large__option {
    @include setFont(300, normal, normal, normal, left);
    color: $black !important;
    font-size: $fs-14px !important;
    font-family: $robotoSlab-Light;
    cursor: pointer !important;
    @include setPadding(
      $fs-4px !important,
      $fs-4px !important,
      $fs-4px !important,
      $fs-4px !important
    );
    &:hover {
      color: $white !important;
      background-color: $crimson-red !important;
    }
  }
  
  .common-select-drop-down-large__control__placeholder {
    font-family: $robotoSlab-Light;
    opacity: 0.45 !important;
    font-size: $fs-13px !important;
  }
  
  .common-select-drop-down-large__control__control {
    border-color: $gainsboro-white !important;
  }
  
  .common-select-drop-down-large__dropdown-indicator {
    padding: $fs-4px !important;
  }
  .common-select-drop-down-large__indicator {
    padding: $fs-4px !important;
  }
  .common-select-drop-down-large__option--is-selected {
    color: $white !important;
    background-color: $crimson-red !important;
  }
  
  .common-select-drop-down-large__single-value--is-disabled {
    font-family: $robotoSlab-Light;
    color: $black !important;
  }
  .common-select-drop-down-large__placeholder {
    opacity: 0.45 !important;
    font-size: $fs-13px !important;
  }
  
