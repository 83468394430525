.play_banner1 {
  width: 100% !important;
  height: auto !important;
}

.play_banner2 {
  background-image: url("../../assets/PlayCity/bg1.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;

  p {
    font-family: RobotoSlab-Regular;
    color: #f1ebeb !important;
    font-weight: 200;
    font-size: 15px !important;
    letter-spacing: 0.037rem;
  }
  a {
    text-decoration: none;
    cursor: pointer;
  }
}
.play_logo {
  width: 100% !important;
  height: 40px !important;
}
.map_section {
  span {
    font-size: 30px;
    font-weight: 600;
    color: #d912d2;
    font-family: "Amatic SC";
    text-transform: uppercase;
  }
  .map_img {
    width: 85%;
  }
}
.gallery {
  position: relative;
  height: 100%;
  width: 90%;
  margin: auto;
  padding: 45px 0;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 1vmin;
  grid-auto-flow: dense;
  @include mobileScreen {
    display: block;
    padding-top: 2px;
    padding: 0px 0;
  }
}
.firstSection1-img {
  &:first-child {
    grid-column-start: span 1;
    grid-row-start: span 1;
  }
  &:nth-child(2) {
    color: white;
    @include mobileScreen {
      display: block;
      width: 100%;
      height: 100%;
      margin-bottom: 4px;
    }
  }

  &:nth-child(7) {
    grid-column-start: span 2;
    grid-row-start: span 1;
    @include mobileScreen {
      display: block;
      width: 100%;
      height: 100%;
      margin-bottom: 4px;
    }
  }
}
.red_section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .img_1 {
    img {
      width: 80px !important;
      position: absolute;
      top: 2px;
      left: 79px;
    }
    p {
      position: absolute;
      left: 96px;
      top: 34px;
      color: #fff !important;
      font-size: 14px !important;
    }
  }

  .img_2 {
    position: relative;
    img {
      width: 400px !important;
    }
    p {
      position: absolute;
      left: 52px;
      top: 25px;
      color: #fff !important;
      font-size: 13px !important;
      text-align: start;
    }
  }
}
.bg_green {
  background-image: url(../../assets/PlayCity/bluebg.jpg);
  background-size: 100% 229px;
  background-repeat: no-repeat;

  p {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 1.35rem !important;
    text-align: justify !important;
  }
}
.yellow_bg {
  background-color: yellow;
  width: 100%;
  height: 220px;
  padding: 50px;
  p {
    font-size: 20px !important;
    text-align: justify !important;
  }
}
.slider1_sec {
  h4 {
    // padding-left: 40px;
    font-size: 30px;
    font-weight: 600;
    color: #d912d2;
    text-align: start;
    font-family: "Amatic SC";
    text-transform: uppercase;
  }
}
.play_img {
  img {
    width: 100%;
  }
}
.robo_section {
  background-image: url("../../assets/PlayCity/greenbg.png");
  background-size: 100% 198px;
  background-repeat: no-repeat;

  p {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 1.35rem !important;
    text-align: justify !important;
  }
}
.bg_yellow {
  background-image: url("../../assets/PlayCity/playbg_2.png");
  background-size: 100% 240px;
  background-repeat: no-repeat;
  padding: 20px !important;

  p {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 1.35rem !important;
    text-align: justify !important;
  }
}
.bg_purple {
  background-image: url("../../assets/PlayCity/purplebg.png");
  background-size: 100% 230px;
  background-repeat: no-repeat;

  p {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 1.35rem !important;
    text-align: justify !important;
  }
}
.bg_lightGreen {
  background-image: url("../../assets/PlayCity/lightGreen.png");
  background-size: 100% 185px;
  background-repeat: no-repeat;

  p {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 1.35rem !important;
    text-align: justify !important;
  }
}
.before-section {
  p {
    font-size: 16px !important;
    span {
      font-weight: 700;
    }
  }
}
.play_tv {
  img {
    width: 90% !important;
  }
}
.scrap_img1 {
  img {
    width: 90% !important;
  }
}
.scrap_img2 {
  img {
    width: 90% !important;
  }
}
.robo_img {
  img {
    width: 70% !important;
  }
}
.tv_img {
  img {
    width: 100% !important;
  }
}
.slider-video {

  .player-wrapper-small {
    width: 99%;
    height: 140px;
    border-radius: 0px !important;
  }
}
.slider-videos{
  .thumb-main{
position: relative;
    .ylogo{
      position: absolute;
      top: 35% !important;
      left: 34% !important;
      width:33% !important
  }
  

  }
}

.arrow_img {
  img {
    width: 58% !important;
    transform: rotate(309deg);
  }
}
.slider_bg {
  background-color: none !important;
  img {
    width: 100% !important;
    margin: 5px;
  }
}
.slick-slider {
  padding: 0 20px !important;
}
.pinkHead {
  font-size: 30px;
  font-weight: 600;
  color: #d912d2;
  font-family: "Amatic SC";
  text-transform: uppercase;
}
.city1 {
  img {
    width: 100% !important;
  }
}
.city2 {
  img {
    width: 100% !important;
  }
}
.city3 {
  img {
    width: 100% !important;
  }
}
.company_logo{
  img{
    width:200px !important
  }
}
@media screen and (max-width: 480px) {
  .slider_comp{.slick-slider {
    padding: 0 53px !important;
  }}
  .robo_section {
   
    background-size: 100% 144px;
    
}
.scrap_img1 
{ text-align: start;
  img {
  width: 67% !important;
}}
.bg_green {
 
  background-size: 100% 249px;
}
  .play_logo {
    width: 80% !important;
    height: 30px !important;
    margin-top: 0px !important;
  }
  .bg_yellow { 
  background-size: 100% 172px;
  p {
   font-size: 16px !important;
   line-height: 1rem !important;
 }}
 .bg_lightGreen {
    background-size: 100% 320px;
 }
 .bg_purple {
  background-size: 100% 315px;
 }
 .red_section .img_1 img {
  width: 12% !important;
  left: 33px !important;
  top: 7px !important;
}
.red_section .img_1 p {
  left: 40px !important;
  top: 23px !important;
  color: #fff !important;
  font-size: 12px !important;
}
}

.city-ratio {
  position: relative;
  .trans {
    position: relative;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  .fixed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (min-device-width: 769.8px) and (max-device-width: 1024px) {
  .red_section {
    .img_1 {
      img {
        left: 4px !important;
      }
      p {
        left: 20px !important;
      }
    }
  }
  p {
    font-weight: 200;
    font-size: 13px !important;
    letter-spacing: 0.027rem;
  }
  .slider-video .player-wrapper-small {
    width: 90%;
    height: 120px;
    margin-right: 10px;
    border-radius: 0px !important;
  }
  .yellow_bg {
    background-color: yellow;
    width: 100%;
    height: 169px;
    padding: 40px;
    p {
      font-size: 15px !important;
      text-align: justify !important;
    }
  }
  .before-section {
    p {
      font-size: 13px !important;
      span {
        font-weight: 600;
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .slick-slider {
    padding: 0px 53px !important;
}
  .red_section {
    .img_2 {
      img {
        width: 300px !important;
      }
      p {
        position: absolute;
        left: 32px;
        top: 15px;
        color: #fff !important;
        font-size: 11px !important;
        text-align: start;
      }
    }
    .img_1 {
      img {
        width: 50px !important;
        left: 14px;
        top: 7px;
      }
      p {
        left: 21px;
        top: 23px;
        color: #fff !important;
        font-size: 12px !important;
      }
    }
  }
  .map_section .map_img {
    width: 100%;
  }
  .gallery_image {
    iframe {
      width: 100% !important;
    }
  }
  .slider-video .player-wrapper-small {
    width: 100%;
    height: 140px;
    border-radius: 0px !important;
  }
  .robo_section {
    p {
      font-size: 13px !important;

      line-height: 1rem !important;
    }
  }
  .robo_img img {
    width: 100% !important;
  }
  .bg_yellow{
    background-size: 100% 185px;
    p {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 0.78rem !important;
  }
}
  .bg_green {
    h4 {
      font-size: 21px !important;
      padding-bottom: 0px !important;
    }
    p {
      font-size: 13px !important;
      line-height: 1rem !important;
    }
  }
  .bg_purple p {
    font-size: 13px !important;
   
    line-height: 1rem !important;
    
}
  .yellow_bg {
    background-color: yellow;
    width: 100%;
    height: 127px;
    padding: 20px;
    p {
      font-size: 13px !important;
      text-align: justify !important;
    }
  }
  .before-section {
    p {
      font-size: 13px !important;
      span {
        font-weight: 700;
      }
    }
  }
  .arrow_img img {
    padding-top: 36px;
    width: 58% !important;
    transform: rotate(316deg);
}

}
