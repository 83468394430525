$white: #ffffff;
$black: #000000;
$crimson-red: #DC0D45;
$boulder-grey: #757675;
$bright-grey: #535455;
$kelly-green: #2FB40E;
$kelly-green-light: #1BD230;
$selective-yellow: #EFB503;
$cornflower-blue: #629CE6;
$neon-carrot: #FC9B2A;
$black-pearl: #050708;
$denim-blue: #1B88D2;
$black-rock: #2C2F3B;
$clinder-grey: #171A1B;
$oyster-grey: #EDF4F7;
$cyprus-blue: #0A474B;
$zircon-grey: #F6F7F7;
$dune-grey: #52514F;
$denim-blue-light: #1669D3;
$transparent: transparent;
$danger: #dc3545;
$warning: #e88a02;
$gainsboro-white: #D8D8D8;
$mosque-green : #05585E;
$fuscous-grey: #3F3E3F;
$sliver-grey: #BCBCBC;
$yellow-selective: #EFC450;
$red: #FA6565;
$yellow: #EC9207;
$blue: #0B96AD;
$electric-purple: #BC0DDC;
$blue-violet: #5C18DC;
$caribbean-green: #09C987;
$summer-sky: #18A0DC;
$denium-sky: #1854DC;
$tea-rose: #F77DD1;
$black-rock-light: #2D303C;
$black-rock-medium: #575859;
$blue-medium: #47BEB3;
$hyper-link-blue:#4391DF;
$yellow-medium: #FFED00;
$DarkPink-red : #E75074;
$Blue-blue : #0728FC
